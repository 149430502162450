import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DateSelect from '../components/DateSelect';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import ScrollSpy from 'react-scrollspy-navigation';
import TimeSelect from '../components/TimeSelect';
import CrossIcon from '../assets/img/plus.svg';
import DownloadIcon from '../assets/img/download.svg';
import PdfIcon from '../assets/img/pdf.png';
import moment from 'moment';
import LoadingContent from '../components/LoadingContent';
import { dhakaArea, ctgArea, savarArea } from '../helpers/Areas';
import { toast } from 'react-toastify';
import PatientDob from '../components/PatientDob';
import { parseInt } from 'lodash';
import BtnLoading from '../components/BtnLoading';
import OrderItemEditNew from '../components/OrderItemEditNew';
import SelectTests from '../components/TestCategory/SelectTests';
import DueDateSelect from '../components/DueDateSelect';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoicePdf from '../components/invoice/InvoicePdf';
import MtAssignHistoryModal from '../components/MtAssignHistoryModal';
import { getUserTokens } from '../firebase/Firebase';
import { sendMsgCustom } from '../firebase/SendMsgCustom';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';


class OrderEdit extends Component {
  state = {
    order: null,
    loading: false,
    reports: null,
    orderAllPatients: [],
    orderAllTests: [],
    fees: null,

    feesApi: { collection_fee: 200, meterial_fee: 50, delivery_fee: 100 },

    orderType: null,
    orderRef: null,
    headUpdateLoading: false,

    orderCro: null,
    orderMt: null,
    orderMtOld: null,

    sampleDate: null,
    sampleTime: null,
    sampleDateTimeEditEnable: false,

    userDetails: null,
    userPatients: null,
    userPatientsShown: null,
    userPatientName: '',

    locationLoopItem: dhakaArea,
    mobile: '',
    district: '',
    thana: '',
    address: '',
    addEmail: '',
    addId: null,
    timeSelectPlaceholder: 'HH:MM',
    addressLoading: false,
    scheduleUpdating: false,
    previousAddresses: [],
    selectedPreviousAddress: null,

    patientName: '',
    patientGender: '',
    patientDob: '',
    patientEmail: '',
    patientNumber: '',
    patientId: null,
    patientLoading: false,
    patientHeight: 0.0,
    patientWeight: 0.0,
    patientInfoId: null,

    orderManager: null,
    medicalTech: null,

    orderStatus: null,
    orderStatusPrev: null,
    serviceStatus: null,
    paymentStatus: null,
    reportStatus: null,

    paymentEdit: false,
    paymentNote: null,
    paymentSource: null,
    paymentLoading: false,

    orderReportFile: null,
    orderReportName: '',
    orderReportNote: '',
    orderReportTestName: [],
    orderReportPatientName: '',
    orderReportLoading: false,
    orderReportUploaded: false,

    orderDiscountSource: '',
    orderDiscountPrice: null,
    orderDiscountColl: null,
    orderDiscountMate: null,
    orderDiscountNote: '',
    orderDiscountEdit: false,
    orderDiscountLoading: false,

    reportOverviewEdit: false,
    reportStatusSoft: '',
    reportStatusHard: '',
    reportStatusHardDate: null,
    reportStatusSoftDate: null,
    reportSoftNote: '',
    reportHardNote: '',

    orderNoteEdit: false,
    orderNote: '',
    orderNoteMarked: false,
    orderNoteId: null,
    orderNoteLoading: null,

    from_sneho: false,
    sneho_prescription_id: 0,

    orderItemAddModal: false,

    reportSendEmails: '',
    reportSendEmailsLoading: false,

    orderDeliveryHead: '',
    orderDeliveryHeadNew: '',

    softCopyStatus: [],
    hardCopyStatus: [],

    discountPercentage: null,
    discountPrice: null,
    patientOrders: [],

    ref_cup_code: '',
    ref_channel: '',
    ref_reference: '',

    is_archived: false,

    mtAssignHistory: null,
    mt_assign_modal: false,
    mtStatusHistory: null,
    mt_status_modal: false,

    labDiscountArray: [],

    referenceGetedItem: [],
  };

  _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };


  setPreviousAddresses = (addresses = []) => {
    this.setState({ previousAddresses: addresses })
  }

  setSelectedPreviousAddress = (index = 0) => {
    this.setState({ selectedPreviousAddress: index });
  }


  // getFees = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
  //     .then((resp) => {
  //       this.setState({ feesApi: resp.data.results[0] });
  //     });
  // };



  // getFees = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
  //     .then((resp) => {

  //           if (this.state.order.orderitem && this.state.order.orderitem.length && this.state.order.orderitem[0].test_item && this.state.order.orderitem[0].test_item.branch && this.state.order.orderitem[0].test_item.branch.lab && !this.state.order.orderitem[0].test_item.branch.lab.is_active ){
  //               this.setState({
  //                 fees: {
  //                   collection_fee: 500,
  //                   meterial_fee: resp.data.results[0].meterial_fee,
  //                   delivery_fee: resp.data.results[0].delivery_fee,
  //                 },
  //               });
  //           } else {
  //             this.setState({ fees: resp.data.results[0] });
  //           }

  //     });


  // };






  getLabDiscount = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab-discount-get/`)
      .then((resp) => {
        // this.calculateLabDiscount(resp.data.results);
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length !== 0) {
            // console.log(data);
            this.setState({ labDiscountArray: data });
            // this.calculateLabDiscount([aaa]);
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  setMtAssignModal = (value) => this.setState({ mt_assign_modal: value });
  setMtStatusModal = (value) => this.setState({ mt_status_modal: value });

  handlePatientOrders = (order) => {
    const itemArray = order.orderitem;
    const patientDetails = itemArray.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );
    // console.log(itemArray);
    // console.log(filteredPatient);
    let patientOrders = [];

    filteredPatient.forEach((patient) => {
      const result = itemArray.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        testItem: result,
      };
      patientOrders.push(newArray);
      console.log("Arr is", newArray);
      //alert("hi")
    });
    patientOrders.sort((p) => (p.patient.is_account ? -1 : 1));
    this.setState({ patientOrders: patientOrders });
    console.log("Patient ORder is = ", patientOrders);
  };




  getFees = (order) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {

            //alert("hi bro")
            if (order && order.orderitem && order.orderitem.length && order.orderitem[0] && order.orderitem[0].purchasable_order_item && order.orderitem[0].purchasable_order_item.testitem_purchasable_oi && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch && order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab && !order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab.is_active ){

                // this.setState({
                //   order: {
                //     collection_fee: 500,
                //     meterial_fee: resp.data.results[0].meterial_fee,
                //     delivery_fee: resp.data.results[0].delivery_fee,
                //   },
                // });

                this.setState(prevState => {
                  let order = Object.assign({}, prevState.order);
                    order.collection_fee= 500
                    order.meterial_fee= resp.data.results[0].meterial_fee
                    order.delivery_fee= resp.data.results[0].delivery_fee
                  return { order };
                })


                this.setState({
                  feesApi: {
                    collection_fee: 500,
                    meterial_fee: resp.data.results[0].meterial_fee,
                    delivery_fee: resp.data.results[0].delivery_fee,
                  },
                });

                this.setState({
                  fees: {
                    collection_fee: 500,
                    meterial_fee: resp.data.results[0].meterial_fee,
                    delivery_fee: resp.data.results[0].delivery_fee,
                  },
                });


            } else {

              // this.setState({
              //   feesApi: {
              //     collection_fee: 500,
              //     meterial_fee: resp.data.results[0].meterial_fee,
              //     delivery_fee: resp.data.results[0].delivery_fee,
              //   },
              // });

              // this.setState({
              //   fees: {
              //     collection_fee: 500,
              //     meterial_fee: resp.data.results[0].meterial_fee,
              //     delivery_fee: resp.data.results[0].delivery_fee,
              //   },
              // });
              this.setState({ feesApi: resp.data.results[0] });
              this.setState({ fees: resp.data.results[0] });
            }

      });

  };



  calculateDiscountInvoice = (order) => {
    if (order.orderdiscount) {
      const discountPercentage =
        (parseInt(order.orderdiscount.discount_price) /
          parseInt(order.total_price)) *
        100;
      // console.log(discountPercentage);
      this.setState({ discountPercentage: discountPercentage });
    }
  };

  arrangeReportStatus = (array) => {
    const sortedArray = array.sort((a, b) => {
      return a.id - b.id;
    });

    return sortedArray;
  };

  _toSpace = (string) => {
    let newStr = string.replace(/_/g, ' ');
    return newStr;
  };

  setOrderReportTests = (value) => {
    this.setState({ orderReportTestName: value });
  };










  filterOrderTests = (item) => {
    const l = [];
    const items = item.filter((i) => {
      if (
        !l.includes(
          i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.id
        )
      ) {
        l.push(
          i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.id
        );
        return true;
      }
      return false;
    });

    const tests = items
      .map(
        (item) =>
          item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
      )
      .map((item) => {
        return {
          ...item,
          value: item.name,
          label: item.name,
        };
      });
    // console.log(tests);
    this.setState({ orderAllTests: tests });
  };

  fetchOrderPatients = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-patient/${id}`)
      .then((resp) => {
        const l = [];
        const ptns = resp.data.patient.filter((ptn) => {
          if (!l.includes(ptn.id)) {
            l.push(ptn.id);
            return true;
          }
          return false;
        });

        // setOrderAllPatients(ptns);
        this.setState({ orderAllPatients: ptns });
        // console.log(ptns);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  petientReports = (reports) => {
    const patientDetails = reports.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let patientOrders = [];
    filteredPatient.forEach((patient) => {
      const result = reports.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        reports: result,
      };
      patientOrders.push(newArray);
    });
    console.log("Hi Bhai",patientOrders);
    this.setState({ reports: patientOrders });
  };

  setPatientDob = (val) => {
    this.setState({ patientDob: val });
  };

  setSampleDate = (value) => {
    this.setState({ sampleDate: value });
  };

  setSampleTime = (value) => {
    this.setState({ sampleTime: value });
  };

  fetchOrderManager = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name__in=Order%20Manager,Supervisor&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log('oms: ', resp.data.results);
        this.setState({ orderManager: resp.data.results });
      });
  };

  fetchMedicalTechnologist = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name=Medical%20Technologist&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ medicalTech: resp.data.results });
      });
  };

  fetchSingleOrder = (id, loading, callback = null) => {
    if (loading) {
      this.setState({ loading: true });
    }

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}`)
      .then((resp) => {
        this.setState({
          softCopyStatus: this.arrangeReportStatus(
            resp.data.orderreportdelivery
          ),
          hardCopyStatus: this.arrangeReportStatus(
            resp.data.orderreporthardcopydelivery
          ),
        });

        this.getFees(resp.data);
        this.handlePatientOrders(resp.data);



        if (resp.data.orderreport.length !== 0) {
          //alert("hi")
          this.petientReports(resp.data.orderreport);
        }


        this.fetchUserPatients(resp.data.user.id, resp.data.user);

        this.setState({ userDetails: resp.data.user });

        if (loading) {
          this.setState({ loading: false });
        }
        this.setState({ order: resp.data });
        // console.log(resp.data);

        if (resp.data.orderdetail) {
          this.setState({
            orderCro: resp.data.orderdetail.cs_agent
              ? resp.data.orderdetail.cs_agent.id
              : '',
            orderMt: resp.data.orderdetail.mt
              ? resp.data.orderdetail.mt.id
              : '',
            orderMtOld: resp.data.orderdetail.mt
              ? resp.data.orderdetail.mt.id
              : '',
            orderType: resp.data.order_type,
            orderRef: resp.data.orderdetail.references,
          });
        }

        if (resp.data.orderdetail) {
          this.setState({
            orderStatus: resp.data.orderdetail.order_status,
            orderStatusPrev: resp.data.orderdetail.order_status,
            serviceStatus: resp.data.orderdetail.delivery_copy_status,
            paymentStatus: resp.data.orderdetail.payment_status,
            // reportStatus: null,
            paymentNote: resp.data.orderdetail.payment_note,
            paymentSource: resp.data.orderdetail.payment_source,
          });
        }

        this.filterOrderTests(resp.data.orderitem);

        if (resp.data.orderdelivery && resp.data.orderdelivery.length !== 0) {
          const lastDelivery = resp.data.orderdelivery.at(-1).name;
          this.setState({
            orderDeliveryHead: lastDelivery,
            orderDeliveryHeadNew: lastDelivery,
          });
          // console.log(lastDelivery);
        }

        if (resp.data.orderdetail) {
          if (resp.data.orderdetail.address) {
            this.setState({
              reportSendEmails: resp.data.orderdetail.address.email,
            });
          }
        }

        const getUrlId = window.location.hash;

        if (getUrlId) {
          if (getUrlId === '#order-note-nav') {
            // console.log(getUrlId);
            window.scrollTo(0, 360);
            this.props.history.push(`/order-edit/${resp.data.id}`);
          } else if (getUrlId === '#order-summery-nav') {
            window.scrollTo(0, 1600);
            this.props.history.push(`/order-edit/${resp.data.id}`);
          }
        }

        this.setState({
          orderReportUploaded: resp.data.orderdetail.report_uploaded,
        });

        this.setState({
          from_sneho: resp.data.from_sneho,
        });
        this.setState({
          sneho_prescription_id: resp.data.sneho_prescription_id,
        });


        if (resp.data.orderdiscount && resp.data.orderdiscount.discount_price) {
          this.setState({
            discountPrice: parseInt(resp.data.orderdiscount.discount_price),
          });
        }

        this.calculateDiscountInvoice(resp.data);

        this.setState({ is_archived: resp.data.is_archived });

        // const newFees = {
        //   collection_fee: resp.data.collection_fee,
        //   meterial_fee: resp.data.orderitem[0].meterial_fee,
        // };
        // this.setState({ fees: newFees });
        // this.setState({ editOrderItem: resp.data.orderitem });

        // this.setUserDetails(resp.data.user);
        // this.fetchusePat(resp.data.user.id);
        // this.setState({
        //   fullname: resp.data.user.first_name + resp.data.user.last_name,
        // });
        // // this.setState({ sex: resp.data.orderdetail.references });
        // // this.setState({ dob: resp.data.user.references });
        // this.setState({ email: resp.data.user.email });
        // this.setState({ username: resp.data.user.username });

        // this.setState({ references: resp.data.orderdetail.references });
        // this.setState({ orderType: resp.data.orderdetail.order_type });
        // this.setState({ paymentStatus: resp.data.orderdetail.payment_status });
        // this.setState({ paymentNote: resp.data.orderdetail.payment_note });
        // this.setState({ orderNote: resp.data.orderdetail.order_note });

        // this.setState({ mobile: resp.data.orderdetail.address.mobile });
        // this.setState({ district: resp.data.orderdetail.address.district });
        // this.setState({ thana: resp.data.orderdetail.address.thana });
        // this.setState({ address: resp.data.orderdetail.address.address });

        // if (resp.data.orderdetail.address.district === 'Chattogram') {
        //   this.setState({ locationLoopItem: ctgArea });
        // }

        // this.setState({ sampleDate: new Date(resp.data.date) });
        // this.setState({ sampleTime: new Date(resp.data.time) });

        if (callback) callback();
      })
      .catch((error) => {
        console.log(error.response);
        if (loading) {
          this.setState({ loading: false });
        }
        if (callback) callback();
      });
  };

  fetchUserPatients = (id, user) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${id}&limit=300`
      )
      .then((resp) => {
        const results = resp.data.results
          .filter((patient) => patient.is_active)
          .map((patient) => {
            return {
              ...patient,
            };
          });
        // console.log(results);
        this.setState({ userPatients: results, userPatientsShown: results });
        if (!!user) this.getUserPreviousAddress(this.state.selectedPreviousAddress, resp.data.results, user, null);

        // setUserPatients(results);

        // const userPatient = results.filter((patient) => patient.is_account);
        // console.log(userPatient);
        // if (userPatient.length !== 0) {
        //   axios
        //     .get(
        //       `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`
        //     )
        //     .then((resp) => {
        //       // console.log(resp.data.results);
        //       setUserOldAddress(resp.data.results);
        //     })
        //     .catch((error) => console.log(error.response));
        // }
      });
  };


  getUserPreviousAddress = (selectedAddressIdx = null, userPatients = [{ is_account: true }], userDetails = {}, callback = null) => {
    const userPatient = userPatients.filter((patient) => patient.is_account);

    if (userPatient.length !== 0) {

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`)
        .then((resp) => {
          if (resp.data.results.length !== 0) {
            const tempAddrs = resp.data.results.filter(adrs => adrs.status)
            this.setPreviousAddresses(tempAddrs);

            if ((typeof selectedAddressIdx === 'number') && selectedAddressIdx >= 0) this.setSelectedPreviousAddress(selectedAddressIdx, false);
            else this.setSelectedPreviousAddress(
              tempAddrs.findIndex(addrs => { return (this.state.order.orderdetail && addrs.id === this.state.order.orderdetail.address.id) })
            );

            if (callback) callback();

          } else {
            axios
              .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/?user=${userDetails.id}&status=true&ordering=-id`)
              .then((resp) => {
                if (resp.data.results.length !== 0) {
                  const tempAddrsByUser = resp.data.results.filter(adrs => adrs.status)
                  this.setPreviousAddresses(tempAddrsByUser);
                  if ((typeof selectedAddressIdx === 'number') && selectedAddressIdx >= 0) this.setSelectedPreviousAddress(selectedAddressIdx);
                  else this.setSelectedPreviousAddress(
                    tempAddrsByUser.findIndex(addrs => { return (this.state.order.orderdetail && addrs.id === this.state.order.orderdetail.address.id) })
                  );
                }
                if (callback) callback();
              })
              .catch((err) => { if (callback) callback(); console.log(err.response); });
          }
        })
        .catch((error) => { if (callback) callback(); console.log(error.response); });
    } else {

            axios
              .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/?user=${userDetails.id}&status=true&ordering=-id`)
              .then((resp) => {
                if (resp.data.results.length !== 0) {
                  const tempAddrsByUser = resp.data.results.filter(adrs => adrs.status)
                  this.setPreviousAddresses(tempAddrsByUser);
                  if ((typeof selectedAddressIdx === 'number') && selectedAddressIdx >= 0) this.setSelectedPreviousAddress(selectedAddressIdx);
                  else this.setSelectedPreviousAddress(
                    tempAddrsByUser.findIndex(addrs => { return (this.state.order.orderdetail && addrs.id === this.state.order.orderdetail.address.id) })
                  );
                }
                if (callback) callback();
              })
              .catch((err) => { if (callback) callback(); console.log(err.response); });

    }
  }



  fetchRefData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/reference-data-set/?limit=1000`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ referenceGetedItem: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  componentDidMount() {
    this.context.setPageTitle('Users');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Orders', link: '/orders' },
      { name: 'Order Edit', link: '/' },
    ]);

    this.fetchRefData();

    const token = localStorage.getItem('token');
    if (!token) {
      this.props.history.push('/login');
    } else {
      axios.interceptors.request.use((config) => {
        const tokehjjhhn = 'Token ' + JSON.parse(token);
        config.headers.Authorization = tokehjjhhn;
        return config;
      });

      // console.log(this.props.match.params.id);
      if (this.props.match.params.id) {
        this.fetchSingleOrder(parseInt(this.props.match.params.id), true);
        this.fetchOrderPatients(parseInt(this.props.match.params.id));
      }

      this.fetchOrderManager();
      this.fetchMedicalTechnologist();
      //this.getFees();
      this.getLabDiscount();
      //this.getFees();
    }
  }


  clearScheduleForm = () => {
    this.setState({
      sampleDate: null,
      timeSelectPlaceholder: 'HH:MM',
      sampleDateTimeEditEnable: false,
    })
  }


  handleScheduleEditClick = () => {
    this.setState({
      sampleDate: new Date(this.state.order.date),
      sampleDateTimeEditEnable: true,
    });
    if (this.state.order.time) {
      this.setState({
        timeSelectPlaceholder: `${moment(this.state.order.time, [
          'HH:mm:ss',
        ]).format('hh:mm A')} - ${moment(this.state.order.time, 'HH:mm:ss')
          .add(120, 'minutes')
          .format('hh:mm A')}`,

        // sampleTime: `${moment(this.state.order.time,['HH:mm:ss',]).format('hh:mm A')}`
      });
    } else {
      this.setState({ timeSelectPlaceholder: 'HH:MM' });
    }
  }


  handleScheduleUpdate = () => {
    if (this.state.sampleDate === '' || this.state.sampleDate === null) {
      toast.error(`Please select sample collection date.`);
    } else {

      const timeAndDate = {
        date: moment(this.state.sampleDate).format('YYYY-MM-DD'),
        time: this.state.sampleTime
          ? moment(this.state.sampleTime).format('HH:mm:ss')
          : this.state.order.time,
      };

      this.clearScheduleForm();
      this.putOrder(parseInt(this.props.match.params.id), timeAndDate, false, () => {
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
      });

    }
  };


  handleAddressEditClick = (index = 0) => {
    const { previousAddresses } = this.state;
    this.setState({
      addId: previousAddresses[index].id,
      mobile: previousAddresses[index].mobile,
      district: previousAddresses[index].district,
      thana: previousAddresses[index].thana,
      address: previousAddresses[index].address,
      addEmail: previousAddresses[index].email,
    });

    if (previousAddresses[index].district === 'Dhaka') {
      this.setState({ locationLoopItem: dhakaArea });
    } else if (previousAddresses[index].district === 'Savar') {
      this.setState({ locationLoopItem: savarArea });
    } else if (previousAddresses[index].district === 'Chattogram') {
      this.setState({ locationLoopItem: ctgArea });
    } else {
      this.setState({ locationLoopItem: dhakaArea });
    }
  }


  handleAddressI = () => {
    // // console.log(this.state.order.orderdetail.address);
    // this.setState({
    //   addId: this.state.order.orderdetail.address.id,
    //   mobile: this.state.order.orderdetail.address.mobile,
    //   district: this.state.order.orderdetail.address.district,
    //   thana: this.state.order.orderdetail.address.thana,
    //   address: this.state.order.orderdetail.address.address,
    //   addEmail: this.state.order.orderdetail.address.email,
    //   sampleDate: new Date(this.state.order.date),
    // });

    // if (this.state.order.orderdetail.address.district === 'Chattogram') {
    //   this.setState({ locationLoopItem: ctgArea });
    // } else {
    //   this.setState({ locationLoopItem: dhakaArea });
    // }

    // setTimeout(() => {
    //   if (this.state.order.time) {
    //     if (this.state.addId) {
    //       this.setState({
    //         timeSelectPlaceholder: `${moment(this.state.order.time, [
    //           'HH:mm:ss',
    //         ]).format('hh:mm A')} - ${moment(this.state.order.time, 'HH:mm:ss')
    //           .add(120, 'minutes')
    //           .format('hh:mm A')}`,
    //       });
    //     } else {
    //       this.setState({ timeSelectPlaceholder: 'HH:MM' });
    //     }
    //   } else {
    //     this.setState({ timeSelectPlaceholder: 'HH:MM' });
    //   }
    // }, 100);
  };

  clearAddressForm = () => {
    this.setState({
      locationLoopItem: dhakaArea,
      mobile: '',
      district: '',
      thana: '',
      address: '',
      addEmail: '',
      addId: null,
      sampleDate: null,
      sampleTime: null,
    });
  };

  smsApiCall = (orderId, status) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/sms-order-status/${orderId}/${status}`)
      .then((resp) => {
        // console.log('come success', resp.data);
      })
      .catch((error) => {
        // console.log('come err', error.response);
      })
  }

  putOrderDetails = (data, id, redirect = false, sentSms = false) => {
    this.setState({ headUpdateLoading: true, paymentLoading: true });

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        this.setState({ paymentEdit: false, paymentNote: null });
        toast.success(`Order updated successfully.`);

        this.setState({ reportOverviewEdit: false });
        this.setState({ headUpdateLoading: false, paymentLoading: false });

        if (sentSms) {
          // if (resp.data.order_status === 'confirmed') {
          //   this.smsApiCall(resp.data.order, 'order_confirmation');
          // } else if (resp.data.order_status === 'completed') {
          //   // smsApiCall(resp.data.order, 'order_confirmation');
          //   if (this.state.order && this.state.order.orderitem.length !== 0) {
          //     const sou = this.state.order.orderitem[0].order.url
          //     if (sou === 'http://localhost:3000' || sou === 'http://ops.amarlab.com:8080' || sou === 'http://18.188.76.126:8080' || sou === 'https://ops.amarlab.com') {
          //       // console.log('came from dashboard');
          //       this.smsApiCall(resp.data.order, 'order_completed_ops');
          //     } else if (sou === 'http://amarlab.com:8080' || sou === 'http://3.142.245.74:8080' || sou === 'https://amarlab.com') {
          //       // console.log('came from frontend');
          //       this.smsApiCall(resp.data.order, 'order_completed_web');
          //     } else {
          //       // console.log('came from app');
          //       this.smsApiCall(resp.data.order, 'order_completed_app');
          //     }
          //   }
          // }
        }

        if (redirect) {
          this.props.history.push('/orders');
        }

        // if (resp.data.payment_status === 'paid') {
        //   axios
        //     .put(
        //       `${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`,
        //       { order_status: 'completed' }
        //     )
        //     .then((resp) => {
        //       // console.log(resp.data);
        //       this.fetchSingleOrder(parseInt(this.props.match.params.id));
        //     })
        //     .catch((error) => {
        //       // console.log(error.response);
        //     });
        // }

        // for order status change notification

        try {
          // for order status change notification
          // console.log(this.state.order.orderdetail);
          if (
            data &&
            !!data.order_status &&
            (this.state.order.orderdetail
              ? data.order_status !== this.state.order.orderdetail.order_status
              : !!data.order_status)
          ) {
            const newOrder = this.state.order;
            const newUserId = newOrder.user.id;
            const newOrderStatus = resp.data.order_status || data.order_status;

            // console.log(newUserId);
            getUserTokens(newUserId, (tokens) => {
              const extradata = {
                orderId: `${newOrder.order_id}`,
                orderid: newOrder.id,
              };
              // console.log(newUserId);
              // console.log(tokens);
              // console.log(extradata);

              tokens.map((token) => {
                sendMsgCustom(
                  token,
                  `Order ${newOrderStatus}`,
                  `Order ${newOrder.order_id}`,
                  `Your order #${newOrder.order_id} has been ${newOrderStatus}.`,
                  extradata
                );
              });
            });
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ headUpdateLoading: false, paymentLoading: false });
      });
  };


  putOrder = (id, data, alert = false, callback = null) => {
    //alert("hi bro")
    // this.setState({ headUpdateLoading: true });
    console.log("final saved data putOrder = ", data);
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-only/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        //this.fetchSingleOrder(parseInt(this.props.match.params.id));
        if (alert) {
          toast.success(`Order updated successfully.`);
        }

        this.setState({
          orderDiscountSource: '',
          orderDiscountPrice: null,
          orderDiscountColl: null,
          orderDiscountMate: null,
          orderDiscountNote: '',
          orderDiscountEdit: false,
        });
        // this.setState({ headUpdateLoading: false });
        if (callback) callback();

      })
      .catch((error) => {
        console.log(error.response);
        // this.setState({ headUpdateLoading: false });
        if (callback) callback();
      });
  };


  putAddress = (data, id) => {
    this.setState({ addressLoading: true });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/address/${id}/`,
        data
      )
      .then((resp) => {
        // let time;
        // // this.state.sampleTime ? time =
        // const timeAndDate = {
        //   date: moment(this.state.sampleDate).format('YYYY-MM-DD'),
        //   time: this.state.sampleTime
        //     ? moment(this.state.sampleTime).format('HH:mm:ss')
        //     : this.state.order.time,
        // };
        // // console.log(resp.data);
        // this.putOrder(parseInt(this.props.match.params.id), timeAndDate);
        this.fetchSingleOrder(parseInt(this.props.match.params.id), false, () => {
          this.setState({ addressLoading: false });
          this.clearAddressForm();
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addressLoading: false });
      });
  };

  handleAddressUpdate = () => {

    if (this.state.district === '') {
      toast.error(`Please select area.`);
    } else if (this.state.thana === '') {
      toast.error(`Please select thana.`);
    } else if (this.state.address.trim() === '') {
      toast.error(`Please enter location details.`);
    } else if (this.state.mobile.trim() === '') {
      toast.error(`Please enter phone number.`);
    } else {
      const putData = {
        district: this.state.district,
        thana: this.state.thana,
        address: this.state.address,
        mobile: this.state.mobile,
        email: this.state.addEmail,
      };

      if (this.state.addId) {
        this.putAddress(putData, this.state.addId);
        // console.log(putData, this.state.addId);
        // this.putSampleCollection(data, id);
      }
    }
  };


  handleAddressChange = () => {
    const { order, selectedPreviousAddress, previousAddresses } = this.state;

    if (
      selectedPreviousAddress &&
      typeof selectedPreviousAddress === 'number' &&
      previousAddresses.length &&
      order && order.orderdetail
    ) {

      if (
        (!order.orderdetail.address) ||
        (!!order.orderdetail.address &&
          !order.orderdetail.address.id) ||
        (!!order.orderdetail.address &&
          order.orderdetail.address.id &&
          order.orderdetail.address.id !== previousAddresses[selectedPreviousAddress].id)
      ) {

        const putData = {
          address: previousAddresses[selectedPreviousAddress].id,
        }

        // currently
        if (previousAddresses[selectedPreviousAddress].district !== 'Dhaka') {
          toast.error(`Amarlab service other than Dhaka is not available now.`);
          return;
        }

        this.addressChangeInOrderDetails(order.orderdetail.id, putData);

        if (order && order.orderitem && order.orderitem.length > 0) {
          order.orderitem.map(item => this.addressChangeInOrderItem(item.id, putData));
        }
      }

    }
  }

  addressChangeInOrderItem = (orderItemId, putData, callback) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-item/${orderItemId}/`, putData)
      .then((resp) => {
        if (callback) callback();
      })
      .catch(err => {
        console.log(err);
      })
  }

  addressChangeInOrderDetails = (orderDetailId, putData, callback) => {
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-detail/${orderDetailId}/`, putData)
      .then((resp) => {
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        if (callback) callback();
      })
      .catch(err => {
        console.log(err);
      })
  }


  handleDistrict = (e) => {
    this.setState({ district: e.target.value });

    if (e.target.value === 'Dhaka') {
      this.setState({ locationLoopItem: dhakaArea });
    } else if (e.target.value === 'Chattogram') {
      this.setState({ locationLoopItem: ctgArea });
    } else {
      this.setState({ locationLoopItem: dhakaArea });
    }
  };

  putPatient = (id, data, data2) => {
    this.setState({ patientLoading: true });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        this.fetchUserPatients(this.state.order.user.id);
        if (resp.data.is_active) {
          toast.success(`Patient updated successfully.`, {
            autoClose: 3000,
          });
        } else {
          toast.success(`Patient deleted successfully.`, {
            autoClose: 3000,
          });
        }
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });
        // setPtnLoading(false);
        // handlePatientEditClear();



          //patient info
          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient-info/${this.state.patientInfoId}/`,
              data2
            )
            .then((resp) => {
              // console.log(resp.data);
              this.fetchUserPatients(this.state.userDetails.id);
              //if (resp.data.is_active) {
                toast.success(`Patient weight added successfully.`, {
                  autoClose: 3000,
                });
              //}
              this.handlePatientFormClr();
              this.setState({ patientLoading: false });
              // setPtnLoading(false);
              // handlePatientEditClear();
            })
            .catch((error) => {
              // setPtnLoading(false);
              this.setState({ patientLoading: false });
              console.log(error.response);
            });
        //finish patient info







      })
      .catch((error) => {
        // setPtnLoading(false);
        this.setState({ patientLoading: false });
        console.log(error.response);
        this.handlePatientFormClr();
        // if (update) {
        //   toast.error(`Patient update failed.`, {
        //     autoClose: 3000,
        //   });
        // } else {
        //   toast.error(`Patient delete failed.`, {
        //     autoClose: 3000,
        //   });
        // }
      });
  };

  postPatient = (data, data2) => {
    this.setState({ patientLoading: true });

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user_management/patient/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchUserPatients(this.state.order.user.id);
        toast.success(`Patient added successfully.`, {
          autoClose: 3000,
        });
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });

          //patient info
          data2.patient=resp.data.id;
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient-info/`,
              data2
            )
            .then((resp) => {
              // console.log(resp.data);
              //this.fetchUserPatients(this.state.userDetails.id);

                toast.success(`Patient weight added successfully.`, {
                  autoClose: 3000,
                });



              this.handlePatientFormClr();
              this.setState({ patientLoading: false });
              // setPtnLoading(false);
              // handlePatientEditClear();
            })
            .catch((error) => {
              // setPtnLoading(false);
              this.setState({ patientLoading: false });
              console.log(error.response);
            });
        //finish patient info

        // setPtnLoading(false);
        // handlePatientEditClear();
      })
      .catch((error) => {
        // setPtnLoading(false);
        console.log(error.response);
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });
        // if (update) {
        //   toast.error(`Patient update failed.`, {
        //     autoClose: 3000,
        //   });
        // } else {
        //   toast.error(`Patient delete failed.`, {
        //     autoClose: 3000,
        //   });
        // }
      });
  };

  handlePatientDelete = (patient) => {
    // console.log(patient);

    const putDate = {
      is_active: false,
    };

    this.putPatient(patient.id, putDate);
  };

  handlePatientEdit = (patient) => {
    // console.log(patient);
    this.setState({
      patientId: patient.id,
      patientName: patient.full_name,
      patientGender: patient.sex,
      patientDob: new Date(patient.dob),
      // patientEmail: patient.email,
      // patientNumber: patient.mobile,
      patientHeight: patient.patient_info.height,
      patientWeight: patient.patient_info.weight,
      patientInfoId: patient.patient_info.id,
    });
  };

  handlePatientUpdate = () => {
    if (this.state.patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientName.length < 4) {
      toast.error(`Patient name must be at least 4 character long.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientDob === '' || this.state.patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else {
      const patientData = {
        full_name: this.state.patientName,
        dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
        sex: this.state.patientGender,
        // mobile: this.state.patientNumber,
        // email: this.state.patientEmail,
        user: this.state.order.user.id,
      };


      const patientInfoData = {
        height: this.state.patientHeight,
        weight: this.state.patientWeight,
        // patient: this.state.patientGender,
        order: this.state.order.id,
      };




      if (this.state.patientId) {
        this.putPatient(this.state.patientId, patientData, patientInfoData);
        // console.log('put patient');
      } else {
        console.log("the patient info data is = ", patientInfoData)
        this.postPatient(patientData, patientInfoData);
        // console.log('post patient');
      }

      // console.log(patientData, this.state.patientId);
    }
  };

  handlePatientFormClr = () => {
    this.setState({
      patientName: '',
      patientGender: '',
      patientDob: '',
      patientEmail: '',
      patientNumber: '',
      patientId: null,
      patientHeight: 0.0,
      patientWeight: 0.0,
    });
  };

  handlePaymentEdit = () => {
    // console.log(this.state.order);
    this.setState({
      paymentEdit: true,
      paymentNote: this.state.order.orderdetail.payment_note,
    });
  };

  handlePaymentInfo = () => {
    if (this.state.paymentStatus === '' || this.state.paymentStatus === null) {
      toast.error(`Please select payment status.`);
    } else if (this.state.paymentStatus === 'paid' && (this.state.paymentNote === '' || this.state.paymentNote === null)) {
      toast.error(`Please write payment note.`);
    } else if (
      this.state.paymentSource === '' ||
      this.state.paymentSource === null
    ) {
      toast.error(`Please select payment mode.`);
    } else {
      const putData = {
        payment_status: this.state.paymentStatus,
        payment_source: this.state.paymentSource,
        payment_note: this.state.paymentNote,
      };

      this.putOrderDetails(putData, this.state.order.orderdetail.id);
    }
  };

  handleFileDelete = (report) => {
    // console.log(report);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/order/order-report/${report.id}/`
      )
      .then((resp) => {
        // console.log('deleted', resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        toast.success(`Order report deleted sucessfully.`);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleOrderReportPatient = (e) => {
    this.setState({
      orderReportPatientName: e.target.value,
    });

    if (e.target.value !== '') {
      const patientName = this.state.orderAllPatients.filter(
        (patient) => patient.id === parseInt(e.target.value)
      );
      const firstName = patientName[0].full_name.split(' ').shift();
      const generateReportName = (order, patient) => {
        const orderId = order.order_id;
        const serial = order.orderemail
          ? order.orderemail.length + 1
          : Date.now();
        return orderId + patient.toUpperCase() + serial;
      };
      this.setState({
        orderReportName: generateReportName(this.state.order, firstName),
      });
    } else {
      this.setState({ orderReportName: '' });
    }
  };

  handleFileUpload = () => {
    if (
      this.state.orderReportPatientName === null ||
      this.state.orderReportPatientName === ''
    ) {
      toast.error(`Please select a patient.`);
    } else if (this.state.orderReportTestName.length === 0) {
      toast.error(`Please select a test.`);
    } else if (!this.state.orderReportFile) {
      toast.error(`Please select a file.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ orderReportLoading: true });

      const tagTests = this.state.orderReportTestName.map((test) => test.name);

      const postOrderReport = {
        order: this.state.order.id,
        upload_by: this.context.user_details.id,
        name: this.state.orderReportName,
        patient: this.state.orderReportPatientName,
        tests: tagTests.toString(),
        note: this.state.orderReportNote,
      };

      for (let i = 0; i < this.state.orderReportFile.length; i++) {
        const data = new FormData();
        data.append('report', this.state.orderReportFile[i]);

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/order/order-report/`,
            postOrderReport
          )
          .then((resp) => {
            // console.log(resp.data);
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/order/order-report/${resp.data.id}/`,
                data
              )
              .then((resp) => {
                // console.log(resp.data);
                this.fetchSingleOrder(parseInt(this.props.match.params.id));
                this.setState({
                  orderReportFile: null,
                  orderReportName: '',
                  orderReportNote: '',
                  orderReportTestName: [],
                  orderReportPatientName: '',
                  orderReportLoading: false,
                });
                toast.success(`Report uploaded successfully.`);
              })
              .catch((error) => {
                console.log(error.response);
                this.setState({ orderReportLoading: false });
              });

            // console.log(data);
          })
          .catch((err) => {
            console.log(err.response);
            this.setState({ orderReportLoading: false });
          });
      }
    }
  };

  handleOrderDiscountSource = (e) => {
    this.setState({ orderDiscountSource: e.target.value });

    let meteFee = 0;
    let collFee =
      this.state.order && this.state.order.collection_fee
        ? this.state.order.collection_fee
        : '0';
    let total = 0;

    const patts = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    meteFee =
      this.state.order && this.state.order.orderitem.length !== 0
        ? this.state.order.orderitem[0].meterial_fee * patts.length
        : patts.length * 50;

    if (meteFee === '0' || collFee === '0') {
      total = '0';
    } else {
      total = parseInt(collFee) + parseInt(meteFee);
    }

    // console.log(meteFee, collFee);
    // console.log(total);

    if (e.target.value === 'admin') {
      this.setState({
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
        orderDiscountColl: 0,
        orderDiscountMate: 0,
      });
    } else if (e.target.value === 'collection_fee') {
      this.setState({
        orderDiscountColl: collFee,
        orderDiscountPrice: 0,
        orderDiscountMate: 0,
      });
    } else if (e.target.value === 'material_fee') {
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountPrice: 0,
        orderDiscountColl: 0,
      });
    } else if (e.target.value === 'fee') {
      // setOrderDiscountPrice(total);
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountColl: collFee,
        orderDiscountPrice: 0,
      });
    } else if (e.target.value === 'fee_discount') {
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountColl: collFee,
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
      });
    } else {
      this.setState({
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
      });
    }
  };

  handleOrderDiscountEdit = () => {
    this.setState({
      orderDiscountEdit: true,
      orderDiscountSource:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_source,
      orderDiscountPrice:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_price,
      orderDiscountColl:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.collection_discount_price,
      orderDiscountMate:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.material_discount_price,
      orderDiscountNote:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_note,
    });
  };

  orderItemMaterial = (source) => {
    const patts = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);

    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'material_fee'
    ) {
      const main =
        this.state.fees.meterial_fee * patts.length -
        this.state.orderDiscountMate;
      // return parseInt(this.state.order.material_fee) === 0 ? 0 : Math.round(main / patts.length);
      return this.state.order.order_type === "covid" ? 0 : Math.round(main / patts.length)
    } else {
      // return parseInt(this.state.order.material_fee) === 0 ? 0 : this.state.fees.meterial_fee;
      return this.state.order.order_type === "covid" ? 0 : this.state.fees.meterial_fee;
    }
  };

  updateOrderItem = (id) => {
    // console.log(id);
    const putData = {
      meterial_fee: this.orderItemMaterial(this.state.orderDiscountSource),
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/${id}/`,
        putData
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  orderCollection = (source, report_status = null) => {
    const { order, fees, orderDiscountColl } = this.state;
    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'collection_fee'
    ) {

      let mainServiceCharge = fees.collection_fee + fees.delivery_fee;
      if (!!report_status) {
        mainServiceCharge = report_status === 'soft' ? fees.collection_fee : (fees.collection_fee + fees.delivery_fee);
      } else {
        mainServiceCharge = (order.orderdetail && order.orderdetail.delivery_copy_status === 'soft') ? fees.collection_fee : (fees.collection_fee + fees.delivery_fee);
      }
      const mainColl = mainServiceCharge - parseInt(orderDiscountColl);

      // return parseInt(this.state.order.collection_fee) === 0 ? 0 : mainColl;
      return this.state.order.order_type === "covid" ? 0 : mainColl;
    }
    else {

      let mainServiceCharge = fees.collection_fee + fees.delivery_fee;
      if (report_status) {
        mainServiceCharge = report_status === 'soft' ? fees.collection_fee : (fees.collection_fee + fees.delivery_fee);
      } else {
        mainServiceCharge = (order.orderdetail && order.orderdetail.delivery_copy_status === 'soft') ? fees.collection_fee : (fees.collection_fee + fees.delivery_fee);
      }

      // return parseInt(this.state.order.collection_fee) === 0 ? 0 : mainServiceCharge;
      return this.state.order.order_type === "covid" ? 0 : mainServiceCharge;
    }
  };

  orderMaterial = (source) => {
    const patts = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);

    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'material_fee'
    ) {
      const pri = (
        this.state.fees.meterial_fee * patts.length -
        this.state.orderDiscountMate
      );
      // return parseInt(this.state.order.material_fee) === 0 ? 0 : pri;
      return this.state.order.order_type === "covid" ? 0 : pri;
    } else {
      const pri = this.state.fees.meterial_fee * patts.length;
      // return parseInt(this.state.order.material_fee) === 0 ? 0 : pri;
      return this.state.order.order_type === "covid" ? 0 : pri;
    }
  };

  ajaxOrderDiscountPost = (data) => {
    // console.log('-->', data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-discount/`, data)
      .then((resp) => {
        const putData = {
          collection_fee: this.orderCollection(this.state.orderDiscountSource),
          material_fee: this.orderMaterial(this.state.orderDiscountSource),
        };

        const order_id = resp.data.order;

        this.putOrder(order_id, putData);

        this.setState({ orderDiscountLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  ajaxOrderDiscountPut = (data, id) => {
    // console.log('-->', data);
    // console.log(id);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-discount/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        const putData = {
          collection_fee: this.orderCollection(this.state.orderDiscountSource),
          material_fee: this.orderMaterial(this.state.orderDiscountSource),
        };

        const order_id = resp.data.order;
        this.putOrder(order_id, putData);
        this.setState({ orderDiscountLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  handleOrderDiscount = () => {
    if (
      this.state.orderDiscountSource === '' ||
      this.state.orderDiscountSource === null
    ) {
      toast.error(`Please enter discount source.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.orderDiscountPrice === '' ||
      this.state.orderDiscountPrice === null
    ) {
      toast.error(`Please enter discount price.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDiscount = {
        order: this.state.order.id,
        discount_note: this.state.orderDiscountNote,
        discount_price: parseInt(this.state.orderDiscountPrice),
        discount_by: this.context.user_details.id,
        discount_source: this.state.orderDiscountSource,
        material_discount_price: this.state.orderDiscountMate,
        collection_discount_price: this.state.orderDiscountColl,
      };
      const putOrderDiscount = {
        discount_note: this.state.orderDiscountNote,
        discount_price: parseInt(this.state.orderDiscountPrice),
        discount_by: this.context.user_details.id,
        discount_source: this.state.orderDiscountSource,
        material_discount_price: this.state.orderDiscountMate,
        collection_discount_price: this.state.orderDiscountColl,
      };

      this.setState({ orderDiscountLoading: true });

      this.state.order.orderitem.forEach((item) => {
        this.updateOrderItem(item.id);
      });

      if (this.state.order.orderdiscount === null) {
        this.ajaxOrderDiscountPost(postOrderDiscount);
        // console.log(postOrderDiscount);
      } else {
        this.ajaxOrderDiscountPut(
          putOrderDiscount,
          this.state.order.orderdiscount.id
        );
        // console.log(putOrderDiscount);
      }
    }
  };

  updateDiscount = (amount) => {
    // console.log(amount);
    if (this.state.order.orderdiscount === null) {
      const postOrderDiscount = {
        order: this.state.order.id,
        discount_price: parseInt(amount),
        discount_by: this.context.user_details.id,
        discount_source: 'admin',
      };
      this.ajaxOrderDiscountPost(postOrderDiscount);
      // console.log(postOrderDiscount);
    } else {
      const prevPrice = this.state.order.orderdiscount.discount_price;
      const putOrderDiscount = {
        discount_price: parseInt(amount) + parseInt(prevPrice),
        discount_by: this.context.user_details.id,
      };
      this.ajaxOrderDiscountPut(
        putOrderDiscount,
        this.state.order.orderdiscount.id
      );
      // console.log(putOrderDiscount);
    }
  };

  postServiceStatus = (data) => {
    console.log("final save data is = ", data);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-delivery-status/`,
        data
      )
      .then((resp) => {
        console.log(resp.data);
        // mainOrder.orderdelivery.push(resp.data);
        // toast.success(`Service status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
        // setShowEditModal(false);
        // setReportDelivery('');
        // setShowPopover(false);
        // setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  postMtStatus = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/mt/mt-status/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  referenceCheck = (ref) => {
    if (ref && this.state.referenceGetedItem.length !== 0) {
      // console.log(ref);
      // console.log(this.state.referenceGetedItem);
      const check = this.state.referenceGetedItem.filter((item) => {
        return item.name.toLowerCase() === ref.toLowerCase();
      });
      if (check.length === 0) {
        // console.log('api request');
        const postData = {
          name: ref,
          created_by: this.context.user_details.id,
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/order/reference-data-set/`,
            postData
          )
          .then((resp) => {
            // console.log(resp.data.results);
            this.fetchRefData();
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
      // console.log(check);
    }
  };

  handleHeadUpdateBtn = () => {

    // console.log(this.state.order);

    const snehoPrescriptionId = this.state.sneho_prescription_id
      ? String(this.state.sneho_prescription_id)
      : ''; // Convert to string or default to an empty string

    if (this.state.from_sneho && !snehoPrescriptionId.trim()) {
      toast.error('Please enter Sneho Prescription ID, or uncheck "From Sneho".');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.orderStatus === '' || this.state.orderStatus === null) {
      toast.error(`Please select order status.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.paymentStatus === '' ||
      this.state.paymentStatus === null
    ) {
      toast.error(`Please select payment status.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.serviceStatus === '' ||
      this.state.serviceStatus === null
    ) {
      toast.error(`Please select service status.`, {
        autoClose: 3000,
      });
    } else if (this.state.paymentStatus === 'paid' && (this.state.paymentNote === '' || this.state.paymentNote === null)) {
      toast.error(`Please write payment note.`, {
        autoClose: 3000,
      });
    }
    else if (this.state.orderType === '' || this.state.orderType === null) {
      toast.error(`Please select order type.`, {
        autoClose: 3000,
      });
    } else if (this.state.orderCro === '' || this.state.orderCro === null) {
      toast.error(`Please select a CRO.`, {
        autoClose: 3000,
      });
    } else if (this.state.orderMt === '' || this.state.orderMt === null) {
      toast.error(`Please select a MT.`, {
        autoClose: 3000,
      });
    } else {
      this.handleAddressChange();


      const putData = {
        order_status: this.state.orderStatus,
        payment_status: this.state.paymentStatus,
        delivery_copy_status: this.state.serviceStatus,
        cs_agent: this.state.orderCro,
        mt: this.state.orderMt,
        references: this.state.orderRef,
      };
      // console.log(putData);

      let putOrder = {
        order_type: this.state.orderType,
      };

      if(this.state.from_sneho){
        putOrder.from_sneho = this.state.from_sneho;
        putOrder.sneho_prescription_id = this.state.sneho_prescription_id;
      }



      if (
        this.state.order && this.state.order.orderdetail &&
        this.state.order.orderdetail.delivery_copy_status === this.state.serviceStatus
      ) {


          if (this.state.order && this.state.order.orderitem && this.state.order.orderitem.length && this.state.order.orderitem[0] && this.state.order.orderitem[0].purchasable_order_item && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab && !this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab.is_active ){
              putOrder.collection_fee = 500;

          } else {
              putOrder.collection_fee = parseInt(this.state.order.collection_fee)
          }



      } else if (
        (this.state.order && this.state.order.orderdetail &&
          this.state.order.orderdetail.delivery_copy_status !== this.state.serviceStatus) &&
        (this.state.serviceStatus === 'hard' || this.state.serviceStatus === 'both')
      ) {

        // alert("bro")
        // console.log("the data is -= ", this.state.order.orderitem)
        //purchasable_order_item.testitem_purchasable_oi.diagnostic_test.name
        if (this.state.order && this.state.order.orderitem && this.state.order.orderitem.length && this.state.order.orderitem[0] && this.state.order.orderitem[0].purchasable_order_item && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab && !this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab.is_active ){
            putOrder.collection_fee = 500;
            //alert(putOrder.collection_fee)

        } else {
            putOrder.collection_fee =
              parseInt(this.state.feesApi.collection_fee) +
              this.state.feesApi.delivery_fee;
        }



      } else if (
        this.state.order && this.state.order.orderdetail &&
        this.state.order.orderdetail.delivery_copy_status !== this.state.serviceStatus &&
        this.state.serviceStatus === 'soft'
      ) {


          if (this.state.order && this.state.order.orderitem && this.state.order.orderitem.length && this.state.order.orderitem[0] && this.state.order.orderitem[0].purchasable_order_item && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab && !this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab.is_active ){
              putOrder.collection_fee = 500;

          } else {
              putOrder.collection_fee = this.state.feesApi.collection_fee;
          }



      } else {
        if (this.state.serviceStatus === 'soft') putOrder.collection_fee = this.state.feesApi.collection_fee;
        else {

            if (this.state.order && this.state.order.orderitem && this.state.order.orderitem.length && this.state.order.orderitem[0] && this.state.order.orderitem[0].purchasable_order_item && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch && this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab && !this.state.order.orderitem[0].purchasable_order_item.testitem_purchasable_oi.branch.lab.is_active ){
                putOrder.collection_fee = 500;
            } else {
                putOrder.collection_fee =
                  parseInt(this.state.feesApi.collection_fee) +
                  this.state.feesApi.delivery_fee;
            }


        }
      }

      //alert(putOrder.collection_fee)

      const postMtStatusData = {
        mt: this.state.orderMt,
        name: 'pending',
        order: this.props.match.params.id,
        created_by: this.context.user_details.id,
      };

      if (this.state.orderMt !== this.state.orderMtOld) {
        this.postMtStatus(postMtStatusData);
      }

      this.referenceCheck(this.state.orderRef);

      this.putOrder(parseInt(this.props.match.params.id), putOrder);

      if (this.state.orderStatusPrev !== this.state.orderStatus) {
        if (this.state.orderStatus === 'confirmed' || this.state.orderStatus === 'completed') {
          this.putOrderDetails(putData, this.state.order.orderdetail.id, false, true);
        } else {
          this.putOrderDetails(putData, this.state.order.orderdetail.id, false);
        }
      } else {
        this.putOrderDetails(putData, this.state.order.orderdetail.id, false);
      }

      if (this.state.orderDeliveryHead !== this.state.orderDeliveryHeadNew) {
        const serviceData = {
          order: this.state.order.id,
          name: this.state.orderDeliveryHeadNew,
          created_by: this.context.user_details.id,
        };

        this.postServiceStatus(serviceData);
      }
    }
  };

  handleReportOverviewI = () => {
    this.setState({
      reportOverviewEdit: true,
    });
  };

  postSoftCopy = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-delivery-status/`,
        data
      )
      .then((resp) => {
        alert(1)
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        // toast.success(`Report delivery status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
        this.clearReportStatusForm();
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  postHardCopy = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-hardcopy-delivery-status/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        // toast.success(`Report delivery status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        this.clearReportStatusForm();
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  updateReportOverview = () => {
    if (this.state.serviceStatus === 'hard') {
      if (this.state.reportStatusHard === '') {
        toast.error(`Please select report status.`);
      } else if (
        this.state.reportStatusHard === 'hard_copy_due' &&
        this.state.reportStatusHardDate === null
      ) {
        toast.error(`Please select due date.`);
      } else {
        const putData = {
          delivery_copy_status: this.state.serviceStatus,
        };

        this.putOrderDetails(putData, this.state.order.orderdetail.id);

        const date = this.state.reportStatusHardDate
          ? moment(this.state.reportStatusHardDate).format('YYYY-MM-DD')
          : null;

        const time = this.state.reportStatusHardDate
          ? moment(this.state.reportStatusHardDate).format('H:mm')
          : null;

        const postHardCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusHard,
          created_by: this.context.user_details.id,
          note: this.state.reportHardNote,
          due_date: this.state.reportStatusHardDate ? date + 'T' + time : null,
        };
        // console.log(postHardCopy);
        this.postHardCopy(postHardCopy);


        // handle collection and material fee
        const { order } = this.state;
        if (!order.orderdetail || (order.orderdetail && order.orderdetail.delivery_copy_status === 'soft')) {
          const putDataFee = {
            collection_fee: this.orderCollection('', 'hard'),
            material_fee: this.orderMaterial(''),
          };
          console.log(putDataFee);
          const order_id = this.state.order.id;
          this.putOrder(order_id, putDataFee);
        }

      }
    } else if (this.state.serviceStatus === 'soft') {
      if (this.state.reportStatusSoft === '') {
        toast.error(`Please select report status.`);
      } else if (
        this.state.reportStatusSoft === 'soft_copy_due' &&
        this.state.reportStatusSoftDate === null
      ) {
        toast.error(`Please select due date.`);
      } else {
        const putData = {
          delivery_copy_status: this.state.serviceStatus,
        };

        this.putOrderDetails(putData, this.state.order.orderdetail.id);

        const date = this.state.reportStatusSoftDate
          ? moment(this.state.reportStatusSoftDate).format('YYYY-MM-DD')
          : null;

        const time = this.state.reportStatusSoftDate
          ? moment(this.state.reportStatusSoftDate).format('H:mm')
          : null;

        const postSoftCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusSoft,
          created_by: this.context.user_details.id,
          note: this.state.reportSoftNote,
          due_date: this.state.reportStatusSoftDate ? date + 'T' + time : null,
        };

        // console.log(postSoftCopy);
        this.postSoftCopy(postSoftCopy);


        // handle collection and material fee
        const { order } = this.state;
        if (!order.orderdetail || (order.orderdetail && order.orderdetail.delivery_copy_status !== 'soft')) {
          const putDataFee = {
            collection_fee: this.orderCollection('', 'soft'),
            material_fee: this.orderMaterial(''),
          };
          const order_id = this.state.order.id;
          this.putOrder(order_id, putDataFee);
        }
      }
    } else if (this.state.serviceStatus === 'both') {
      if (this.state.reportStatusHard === '') {
        toast.error(`Please select report status.`);
      } else if (
        this.state.reportStatusHard === 'hard_copy_due' &&
        this.state.reportStatusHardDate === null
      ) {
        toast.error(`Please select due date.`);
      } else if (this.state.reportStatusSoft === '') {
        toast.error(`Please select report status.`);
      } else if (
        this.state.reportStatusSoft === 'soft_copy_due' &&
        this.state.reportStatusSoftDate === null
      ) {
        toast.error(`Please select due date.`);
      } else if (
        this.state.reportStatusHard !== '' &&
        this.state.reportStatusSoft !== ''
      ) {
        const putData = {
          delivery_copy_status: this.state.serviceStatus,
        };

        this.putOrderDetails(putData, this.state.order.orderdetail.id);

        const hardDate = this.state.reportStatusHardDate
          ? moment(this.state.reportStatusHardDate).format('YYYY-MM-DD')
          : null;

        const hardTime = this.state.reportStatusHardDate
          ? moment(this.state.reportStatusHardDate).format('H:mm')
          : null;

        const postHardCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusHard,
          created_by: this.context.user_details.id,
          note: this.state.reportHardNote,
          due_date: this.state.reportStatusHardDate
            ? hardDate + 'T' + hardTime
            : null,
        };

        const softDate = this.state.reportStatusSoftDate
          ? moment(this.state.reportStatusSoftDate).format('YYYY-MM-DD')
          : null;

        const softTime = this.state.reportStatusSoftDate
          ? moment(this.state.reportStatusSoftDate).format('H:mm')
          : null;

        const postSoftCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusSoft,
          created_by: this.context.user_details.id,
          note: this.state.reportSoftNote,
          due_date: this.state.reportStatusSoftDate
            ? softDate + 'T' + softTime
            : null,
        };

        // console.log(postSoftCopy);
        // console.log(postHardCopy);
        this.postHardCopy(postHardCopy);
        this.postSoftCopy(postSoftCopy);


        // handle collection and material fee
        const { order } = this.state;
        if (!order.orderdetail || (order.orderdetail && order.orderdetail.delivery_copy_status === 'soft')) {
          const putDataFee = {
            collection_fee: this.orderCollection('', 'both'),
            material_fee: this.orderMaterial(''),
          };
          const order_id = this.state.order.id;
          this.putOrder(order_id, putDataFee);
        }

      }
    }

    // this.setState({
    //   reportStatusSoft: '',
    //   reportSoftNote: '',
    //   reportStatusHard: '',
    //   reportHardNote: '',
    // });
  };

  clearReportStatusForm = () => {
    this.setState({
      reportStatusSoft: '',
      reportStatusHard: '',
      reportStatusHardDate: null,
      reportStatusSoftDate: null,
      reportSoftNote: '',
      reportHardNote: '',
    });
  };

  postOrderNote = (data) => {
    this.setState({ orderNoteLoading: true });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-note/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        toast.success(`Order note added successfully.`);

        this.setState({
          orderNoteEdit: false,
          orderNote: '',
          orderNoteId: null,
          orderNoteLoading: false,
          orderNoteMarked: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ orderNoteLoading: false });
      });
  };

  putOrderNote = (data, id) => {
    this.setState({ orderNoteLoading: true });
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-note/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        toast.success(`Order note updated successfully.`);
        this.setState({
          orderNoteEdit: false,
          orderNote: '',
          orderNoteId: null,
          orderNoteLoading: false,
          orderNoteMarked: false,
        });
      })
      .catch((error) => {
        this.setState({ orderNoteLoading: false });
        console.log(error.response);
      });
  };

  handleNoteSubmit = (e) => {
    e.preventDefault();

    if (this.state.orderNote.trim() === '') {
      toast.error(`Please enter order note.`);
    } else {
      const postOrderNote = {
        note: this.state.orderNote,
        order: this.state.order.id,
        created_by: this.context.user_details.id,
        marked: this.state.orderNoteMarked,
      };

      const putOrderNote = {
        // order: this.state.order.id,
        note: this.state.orderNote,
        created_by: this.context.user_details.id,
        marked: this.state.orderNoteMarked,
        // assign_by:'',
      };

      if (this.state.orderNoteId) {
        // console.log(putOrderNote);
        this.putOrderNote(putOrderNote, this.state.orderNoteId);
      } else {
        // console.log(postOrderNote);
        this.postOrderNote(postOrderNote);
      }
    }
  };

  handleOrderNoteEdit = (note) => {
    // console.log(note);

    this.setState({
      orderNoteEdit: true,
      orderNote: note.note,
      orderNoteMarked: note.marked,
      orderNoteId: note.id,
    });
  };

  resetAllBalance = () => {
    // console.log('all item deleted');
    this.putOrder(parseInt(this.props.match.params.id), {
      total_price: 0,
      material_fee: 0,
      collection_fee: 0,
    });

    if (this.state.order.orderdiscount) {
      axios
        .delete(
          `${process.env.REACT_APP_BASE_URL}/order/order-discount/${this.state.order.orderdiscount.id}/`
        )
        .then((resp) => {
          this.fetchSingleOrder(parseInt(this.props.match.params.id));
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  handleOrderNoteFormClr = () => {
    this.setState({
      orderNoteEdit: false,
      orderNote: '',
      orderNoteId: null,
      orderNoteLoading: null,
      orderNoteMarked: false,
    });
  };

  orderItemGet = (orderEditId) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/?order=${orderEditId}&limit=100`
      )
      .then((resp) => {
        const items = resp.data.results;
        if (items.length === 0) {
          this.resetAllBalance();
          return;
        }
        // console.log(items);
        const totalPrice = items.reduce((total, item) => {
          return (
            total + parseInt(item.purchasable_order_item.sell_price)
            // fees.meterial_fee
          );
        }, 0);
        // console.log(totalPrice);

        const patients = items
          .map((patient) => {
            return patient.patient.id;
          })
          .filter((x, i, a) => a.indexOf(x) === i);
        // console.log(patients.length);

        let subTotal = items[0].meterial_fee * patients.length;
        // subTotal = subTotal + fees.collection_fee;
        // console.log(subTotal);

        let orderPriceData;

        if (items.length === 1) {
          orderPriceData = {
            total_price: totalPrice,
            material_fee: subTotal,
            collection_fee:
              this.state.serviceStatus === 'soft'
                ? this.state.fees.collection_fee
                : this.state.fees.collection_fee + this.state.fees.delivery_fee,
          };
        } else {
          orderPriceData = {
            total_price: totalPrice,
            material_fee: subTotal,
          };
        }

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/order/order-only/${orderEditId}/`,
            orderPriceData
          )
          .then((resp) => {
            // console.log(resp.data);
            this.fetchSingleOrder(parseInt(this.props.match.params.id));
            // setItemEditModal(false);
            this.setOrderItemAddModal(false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      });
  };

  calculateLabDiscountDelete = (array, testDetails) => {
    // console.log(array);
    // console.log(testDetails);

    let labMatched = false;

    array.forEach((coupon) => {
      const matched =
        testDetails.purchasable_order_item.testitem_purchasable_oi.branch.lab.name.toLowerCase() ===
        coupon.lab.name.toLowerCase();
      if (matched) {
        labMatched = true;
      }
    });

    if (labMatched) {
      // console.log(array);
      // console.log(testDetails);

      let matchedTests = [];

      array.forEach((labTest) => {
        let single = [];
        labTest.cct_lab.forEach((test) => {
          let existing = [testDetails]
            .filter(
              (cart) =>
                cart.purchasable_order_item.testitem_purchasable_oi
                  .diagnostic_test.id === test.test.id &&
                cart.purchasable_order_item.testitem_purchasable_oi.branch.lab
                  .id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedTests.push(...single);
      });

      // console.log(matchedTests);

      const discountTestPrice = matchedTests.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
            100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPrice = discountTestPrice;

      if (discountPrice && 0 < discountPrice) {
        // this.setState({
        //   orderDiscountPrice: discountPrice,
        //   orderDiscountCustom: discountPrice,
        //   orderDiscountSource: 'cupon',
        //   orderDiscountNote: 'lab discount',
        // });
        return discountTestPrice;
        // console.log(discountTestPrice);
      }
    }
  };

  updateDiscountDelete = (amount) => {
    // console.log(amount);
    if (this.state.order.orderdiscount !== null) {
      const prevPrice = this.state.order.orderdiscount.discount_price;

      if (amount < prevPrice) {
        // console.log(amount);
        // console.log(parseInt(prevPrice));

        const putOrderDiscount = {
          discount_price: parseInt(prevPrice) - parseInt(amount),
          discount_by: this.context.user_details.id,
        };
        this.ajaxOrderDiscountPut(
          putOrderDiscount,
          this.state.order.orderdiscount.id
        );
        // console.log(putOrderDiscount);
      }
    }
  };

  handleTestItemDelete = (item) => {
    // console.log(item);

    let discountTaka;
    if (item.order_type === 'diagnostic') {
      const discount = this.calculateLabDiscountDelete(
        this.state.labDiscountArray,
        item
      );
      if (discount) {
        // console.log(discount);
        discountTaka = discount;
      }
    }

    const orderId = item.order.id;
    // console.log(orderId);
    // orderItemGet(orderId);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/order/order-item/${item.id}/`)
      .then((resp) => {
        // console.log(resp.data);
        // getSingleOrderTree(orderId);
        this.orderItemGet(orderId);
        toast.success(`Test item removed successfully.`, {
          autoClose: 3000,
        });
        if (discountTaka) {
          const discountTotal = discountTaka;
          this.updateDiscountDelete(discountTotal);
          // console.log(discountTotal);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleTestItemModal = (order) => {
    // setAddressId(order.orderdetail.address.id);
    // setOrderEditId(order.id);
    // setOrderUserId(order.user.id);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${order.user.id}&limit=300`
      )
      .then((resp) => {
        const results = resp.data.results.filter(
          (patient) => patient.is_active
        );
        // console.log(results);
        this.setState({ orderItemAddModal: true });
      });
  };

  testItemModalClose = () => {
    // getSingleOrderTree(orderEditId);
    // setItemEditModal(false);
    setTimeout(() => {
      this.orderItemGet(this.state.order.id);
      toast.success(`Test item added successfully.`, {
        autoClose: 3000,
      });
    }, 1000);
  };

  setOrderItemAddModal = (value) => {
    this.setState({ orderItemAddModal: value });
  };

  setUserPatients = (value) => {
    this.setState({ userPatients: value, userPatientsShown: value });
  };

  postOrderEmailStatus = (data) => {
    this.setState({ reportSendEmails: false });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-email/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ reportSendEmailsLoading: false });
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ reportSendEmailsLoading: false });
      });
  };

  lastReportStatus = (array) => {
    const sortedArray = array.sort((a, b) => {
      return a.id - b.id;
    });

    return sortedArray.at(-1).note;
  };

  handleSendEmail = () => {
    const regex = /^([a-z\d\.\_-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    let emails = this.state.reportSendEmails.split(',');
    emails = emails.filter((i) => i.trim());

    let validationMessage = [];
    let emailArray = [];

    emails.forEach((email) => {
      const test = regex.test(email.trim());
      validationMessage.push(test);
      emailArray.push(email.trim());
    });

    if (validationMessage.includes(false)) {
      toast.error('Please enter valid email address.');
    } else {
      // console.log(
      //   `${process.env.REACT_APP_BASE_URL}/order/report-mail/${
      //     this.state.order.id
      //   }/${emailArray.toString()}`
      // );
      // return;

      this.setState({ reportSendEmailsLoading: true });

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/order/report-mail/${this.state.order.id
          }/${emailArray.toString()}`
        )
        .then((resp) => {
          // console.log(resp.data);

          const postData = {
            order: this.state.order.id,
            mail_status: resp.data.status === 1 ? 'success' : 'failure',
            sender_by: this.context.user_details.id,
          };
          this.postOrderEmailStatus(postData);

          if (resp.data.status === 1) {
            toast.success(`Email send successfully.`);
          } else {
            toast.success(`Sorry, something went wrong. Try again later.`);
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({ reportSendEmailsLoading: false });
        });
    }
  };

  handleReportUploaded = () => {
    this.setState({
      orderReportUploaded: !this.state.orderReportUploaded,
    });

    setTimeout(() => {
      // console.log(this.state.orderReportUploaded);
      const data = {
        report_uploaded: this.state.orderReportUploaded,
      };
      this.putOrderDetails(data, this.state.order.orderdetail.id);

      const softData = {
        order: this.state.order.id,
        name: 'soft_copy_sent',
        created_by: this.context.user_details.id,
      };

      if (this.state.orderReportUploaded) {
        this.postSoftCopy(softData);
      }
    }, 200);
  };

  handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    return patients.length;
  };

  handlePatientSearch = (e) => {
    this.setState({ userPatientName: e.target.value });
    setTimeout(() => {
      const newPatient = this.state.userPatients.filter((p) =>
        p.full_name
          .toLowerCase()
          .includes(this.state.userPatientName.trim().toLocaleLowerCase())
      );
      this.setState({ userPatientsShown: newPatient });
    }, 200);
  };

  handleAchieve = () => {
    this.setState({ is_archived: !this.state.is_archived });

    setTimeout(() => {
      this.putOrder(
        parseInt(this.props.match.params.id),
        {
          is_archived: this.state.is_archived,
        },
        true
      );
    }, 200);
  };

  handleMtAssignHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/mt/mt-status-get/?order=${this.state.order.id}&limit=100`
      )
      .then((resp) => {
        this.setMtAssignModal(true);
        console.log('oms: ', resp.data.results);
        this.setState({ mtAssignHistory: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleMtFileDownload = (order) => {
    if (order.mt_deposit_order.length !== 0) {
      window.open(order.mt_deposit_order[0].lab_bill);
      // console.log(order.mt_deposit_order[0].lab_bill);
    } else {
      toast.error('Soorry, no file found.');
    }
  };

  render() {
    const { order, loading, previousAddresses, selectedPreviousAddress, orderManager, medicalTech, reports } = this.state;
    const { userPatients, orderDiscountSource, userPatientsShown } = this.state;

    return (
      <>
        <HeaderToolbar title='Edit Order' />
        {loading ? (
          <LoadingContent />
        ) : (
          <>
            {order && (
              <div className='content d-flex flex-column flex-column-fluid order-edit'>
                <div id='kt_content_container' className='container-xxl'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='card card-custom card-stretch gutter-b head-card shadow'>
                        <div className='card-header border-0 pt-5'>
                          <h3 className='card-title align-items-center flex-colum'>
                            <Link
                              to='/orders'
                              className='btn btn-light-primary py-5 px-8 me-7'
                            >
                              <i className='fas fa-chevron-left me-2'></i>
                              Back
                            </Link>
                            <div className='d-flex align-items-start'>
                              <div className='me-6'>
                                <div className='d-flex align-items-center'>
                                  <h2 className='fs-1 mb-2'>Order Details</h2>
                                  <span className='text-gray-500 fs-7 fw-normal ms-3'>
                                    {moment(order.created_at).format(
                                      'DD MMM YYYY; hh:mm A'
                                    )}
                                  </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                  <span className='text-gray-500 me-3 fw-normal'>
                                    #{order.order_id}
                                  </span>{' '}
                                  {order.orderdetail && (
                                    <span className='text-primary fs-7 text-uppercase'>
                                      {order.orderdetail.user_type} user
                                    </span>
                                  )}
                                  {order && order.external_source_user ? (
                                    <span className='text-info fs-7 mx-3'>
                                      {order.external_source_user.first_name
                                        ? order.external_source_user
                                          .first_name +
                                        ' ' +
                                        order.external_source_user.last_name
                                        : order.external_source_user.username}
                                    </span>
                                  ) : (
                                    <span className='text-info fs-7 mx-3 text-uppercase'>
                                      AmarLab
                                    </span>
                                  )}
                                  <div className='d-flex align-items-center'>
                                    <span className='text-warning fs-7 me-1 text-uppercase'>
                                      Achieved:
                                    </span>
                                    <div className='form-check form-switch form-check-custom form-check-solid'>
                                      <input
                                        type='checkbox'
                                        name='new_user_only'
                                        onChange={this.handleAchieve}
                                        checked={this.state.is_archived}
                                        className='form-check-input w-35px'
                                        style={{ height: 20 }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </h3>
                          <div className='card-toolbar'>
                            {this.state.headUpdateLoading ? (
                              <BtnLoading
                                name='updating...'
                                btnClass='primary submit-btn'
                              />
                            ) : (
                              <button
                                onClick={this.handleHeadUpdateBtn}
                                className='submit-btn btn btn-primary py-5 px-8'
                              >
                                UPDATE
                              </button>
                            )}
                          </div>
                        </div>
                        <div className='card-header border-0 pt-0'>
                          <h3 className='card-title align-items-center flex-colum'>
                            <p className='bg-light-primary px-16 me-7'></p>
                            <div className='d-flex'>
                              <div className='head-select'>
                                <span className='text-primary'>Order: </span>
                                <select
                                  className='form-select text-primary'
                                  value={this.state.orderStatus}
                                  onChange={(e) =>
                                    this.setState({
                                      orderStatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value='pending'>Pending</option>
                                  <option value='waiting_for_confirmation'>
                                    Waiting for Confirmation
                                  </option>
                                  <option value='service_on_hold'>
                                    Service on Hold
                                  </option>
                                  <option value='confirmed'>Confirmed</option>
                                  <option value='cancel'>Cancel</option>
                                  <option value='completed'>Completed</option>
                                </select>
                              </div>
                              <div className='head-select'>
                                <span className='text-primary'>Service: </span>

                                <select
                                  className='form-select text-primary'
                                  value={this.state.orderDeliveryHeadNew}
                                  onChange={(e) =>
                                    this.setState({
                                      orderDeliveryHeadNew: e.target.value,
                                    })
                                  }
                                >
                                  <option value>Select service status</option>
                                  <option value='pending'>Pending</option>
                                  <option value='order_received'>
                                    Order Received
                                  </option>
                                  <option value='sample_collected'>
                                    Sample Collected
                                  </option>
                                  <option value='sample_delivered_to_lab'>
                                    Sample Delivered to Lab
                                  </option>
                                </select>
                              </div>
                              <div className='head-select'>
                                <span className='text-danger'>Payment: </span>
                                <select
                                  className='form-select text-danger'
                                  value={this.state.paymentStatus}
                                  onChange={(e) =>
                                    this.setState({
                                      paymentStatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value=''>Set Payment Status</option>
                                  <option value='paid'>Paid</option>
                                  <option value='unpaid'>Unpaid</option>
                                </select>
                              </div>
                              <div className='head-select'>
                                <span className='text-danger'>Report: </span>
                                <select
                                  className='form-select text-danger'
                                  value={this.state.serviceStatus}
                                  onChange={(e) =>
                                    this.setState({
                                      serviceStatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value='both'>Both Copy</option>
                                  <option value='hard'>Hard Copy</option>
                                  <option value='soft'>Soft Copy</option>
                                </select>
                              </div>
                              {order.orderitem.length !== 0 && (
                                <div className='head-select'>
                                  <PDFDownloadLink
                                    document={
                                      <InvoicePdf
                                        order={order}
                                        materialPerson={this.handleMeterialPerson(
                                          order.orderitem
                                        )}
                                        discountPrice={this.state.discountPrice}
                                        patientOrders={this.state.patientOrders}
                                        discountPercentage={
                                          this.state.discountPercentage
                                        }
                                      />
                                    }
                                    fileName={`Order ${order.order_id} Invoice`}
                                    className='btn btn-success inv-button'
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading
                                        ? 'Loading...'
                                        : `Download Invoice`
                                    }
                                  </PDFDownloadLink>
                                </div>
                              )}
                            </div>
                          </h3>
                        </div>
                        <div className='card-header border-0 pt-0 align-items-end'>
                          <h3 className='card-title align-items-center flex-colum'>
                            <p className='bg-light-primary px-16 me-7'></p>
                            <div className='d-flex'>
                              <div className='cro-mt ms-0'>
                                <select
                                  className='form-select'
                                  value={this.state.orderType}
                                  onChange={(e) =>
                                    this.setState({
                                      orderType: e.target.value,
                                    })
                                  }
                                >
                                  <option value=''>Order Type</option>
                                  <option value='covid'>Covid</option>
                                  <option value='non_covid'>Non Covid</option>
                                </select>
                              </div>
                              <div className='d-flex align-items-center'>
                                <div className='ref-h'>
                                  <span className='fw-bold'>Ref: </span>
                                  <input
                                    list='refLists'
                                    value={this.state.orderRef}
                                    onChange={(e) =>
                                      this.setState({
                                        orderRef: e.target.value,
                                      })
                                    }
                                  />
                                  <datalist id='refLists'>
                                    {this.state.referenceGetedItem.map(
                                      (i, a) => (
                                        <option value={i.name} key={a}>
                                          {i.name}
                                        </option>
                                      )
                                    )}
                                  </datalist>
                                </div>
                              </div>
                              <div className='dashed'>
                                <p className='m-0'>
                                  {moment(order.date).format('DD MMM YYYY')}
                                </p>
                                <span className='text-gray-500'>Due Date</span>
                              </div>
                              {order.time && (
                                <div className='dashed'>
                                  <p className='m-0'>
                                    {moment(order.time, ['HH:mm:ss']).format(
                                      'hh:mm'
                                    )}{' '}
                                    -{' '}
                                    {moment(order.time, 'HH:mm:ss')
                                      .add(120, 'minutes')
                                      .format('hh:mm A')}
                                  </p>
                                  <span className='text-gray-500'>
                                    Collection Time
                                  </span>
                                </div>
                              )}
                              <div className='dashed'>
                                <p className='m-0'>
                                  ৳{' '}
                                  {order.orderdiscount
                                    ? `${parseInt(order.total_price) +
                                    parseInt(order.collection_fee) +
                                    parseInt(order.material_fee) -
                                    parseInt(
                                      order.orderdiscount.discount_price
                                    )
                                    }`
                                    : parseInt(order.total_price) +
                                    parseInt(order.collection_fee) +
                                    parseInt(order.material_fee)}
                                </p>
                                <span className='text-gray-500'>
                                  Order Amount
                                </span>
                              </div>
                            </div>
                          </h3>
                          <div className='card-toolbar'>
                            <div className='cro-mt cro'>
                              <span className='fw-bold'>CRO</span>
                              <select
                                className='form-select'
                                value={this.state.orderCro}
                                onChange={(e) =>
                                  this.setState({
                                    orderCro: e.target.value,
                                  })
                                }
                              >
                                <option value=''>CS Agent</option>
                                {orderManager &&
                                  orderManager.map((user, i) => (
                                    <option value={user.id} key={i}>
                                      {user.first_name
                                        ? `${user.first_name} ${user.last_name}`
                                        : user.username}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className='card-header border-0 pt-0 align-items-start justify-content-start'>
                          <p className='bg-light-primary px-16 me-7'></p>
                          <div className='card-toolbar mt-details'>
                            <div className='cro-mt'>
                              <span className='fw-bold'>MT</span>
                              <div className='d-flex align-items-center'>
                                <select
                                  className='form-select'
                                  value={this.state.orderMt}
                                  onChange={(e) =>
                                    this.setState({
                                      orderMt: e.target.value,
                                    })
                                  }
                                >
                                  <option value='' disabled>
                                    Select MT
                                  </option>
                                  {medicalTech &&
                                    medicalTech.map((user, i) => (
                                      <option value={user.id} key={i}>
                                        {user.first_name
                                          ? `${user.first_name} ${user.last_name}`
                                          : user.username}
                                      </option>
                                    ))}
                                </select>
                                {/* {order &&
                                  order.ordermtlogistic &&
                                  order.ordermtlogistic.length !== 0 && (
                                    <div className='mt-status'>
                                      <div className='icon'>
                                        {order.ordermtlogistic[0].name ===
                                          'pending' && (
                                          <i className='fas fa-clock text-warning'></i>
                                        )}
                                        {order.ordermtlogistic[0].name ===
                                          'cancel' && (
                                          <i className='fas fa-times-circle text-danger'></i>
                                        )}
                                        {(order.ordermtlogistic[0].name ===
                                          'ongoing' ||
                                          order.ordermtlogistic[0].name ===
                                            'go_to_lab' ||
                                          order.ordermtlogistic[0].name ===
                                            'completed') && (
                                          <i className='fas fa-check-circle text-success'></i>
                                        )}
                                      </div>
                                      <div className='name'>
                                        {order.ordermtlogistic[0].mt
                                          .first_name +
                                          ' ' +
                                          order.ordermtlogistic[0].mt.last_name}
                                      </div>
                                      <button
                                        type='button'
                                        className='btn btn-light-primary ms-3'
                                        onClick={this.handleMtAssignHistory}
                                      >
                                        Mt Assign History
                                        <i className='far fa-file-alt text-primary ms-2'></i>
                                      </button>
                                      <div className='mt-status2'>
                                        <div className='title'>
                                          Mt Status:{' '}
                                          <span
                                            className={
                                              order.ordermtlogistic[0].name
                                            }
                                          >
                                            {this._toSpace(
                                              order.ordermtlogistic[0].name
                                            )}
                                          </span>
                                        </div>
                                        <div className='down_his_ic'>
                                          {order.ordermtlogistic[0].name ===
                                            'completed' && (
                                            <button
                                              type='button'
                                              className='btn btn-light-success'
                                              onClick={() =>
                                                this.handleMtFileDownload(order)
                                              }
                                            >
                                              <i className='fas fa-download text-success'></i>
                                            </button>
                                          )}
                                          <button
                                            type='button'
                                            className='btn btn-light-info ms-3'
                                            onClick={() =>
                                              this.setMtStatusModal(true)
                                            }
                                          >
                                            Mt Status History
                                            <i className='far fa-file-alt text-info ms-2'></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )} */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='card-body pt-2 pb-0 mt-6'>
                          <nav className='menu-lists'>
                            <ScrollSpy duration={1500} offsetTop={200}>
                              {/* <a href='#order-refe-nav' ref={React.createRef()}>
                                Reference
                              </a> */}
                              <a href='#order-note-nav' ref={React.createRef()}>
                                Order Notes
                              </a>
                              <a
                                href='#patient-info-nav'
                                ref={React.createRef()}
                              >
                                Patient Info.
                              </a>
                              <a
                                href='#collection-info-nav'
                                ref={React.createRef()}
                              >
                                Collection Info.
                              </a>
                              <a
                                href='#order-summery-nav'
                                ref={React.createRef()}
                              >
                                Order Summary
                              </a>
                              <a
                                href='#payment-info-nav'
                                ref={React.createRef()}
                              >
                                Payment Info.
                              </a>
                              <a
                                href='#discount-info-nav'
                                ref={React.createRef()}
                              >
                                Discount
                              </a>
                              <a
                                href='#report-info-nav'
                                ref={React.createRef()}
                              >
                                Report
                              </a>
                            </ScrollSpy>
                          </nav>
                        </div>
                      </div>

                      <div className='all-sections'>
                        {/* ==================Reference Section================== */}
                        {/* <div
                          id='order-refe-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>Cupon Code *</p>
                                      <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='Enter coupon'
                                        value={this.state.ref_cup_code}
                                        onChange={(e) =>
                                          this.setState({
                                            ref_cup_code: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className=''>
                                      <p className='item-title'>
                                        Reference Channel *
                                      </p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.ref_channel}
                                        onChange={(e) =>
                                          this.setState({
                                            ref_channel: e.target.value,
                                          })
                                        }
                                      >
                                        <option value=''>Select Channel</option>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Select Reference *
                                      </p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.ref_reference}
                                        onChange={(e) =>
                                          this.setState({
                                            ref_reference: e.target.value,
                                          })
                                        }
                                      >
                                        <option value=''>
                                          Select Reference
                                        </option>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                      </select>
                                    </div>

                                    <div className='mb-7'>
                                      <p className='item-title text-white'>-</p>
                                      <p className='mb-0 text-end'>
                                        <div className='d-flex justify-content-between'>
                                          <button
                                            className='submit-btn btn btn-light'
                                            onClick={this.handleUserFormClr}
                                          >
                                            View Details
                                          </button>
                                          {this.state.userCreateLoading ? (
                                            <BtnLoading
                                              name='Adding'
                                              btnClass='primary submit-btn'
                                            />
                                          ) : (
                                            <button
                                              className='submit-btn btn btn-primary'
                                              onClick={this.handleUserCreate}
                                            >
                                              Save
                                            </button>
                                          )}
                                        </div>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>Reference: </p>
                                <p className='m-0 edit-option'>
                                  <p className='mb-2'>#778652</p>
                                  <p className='text-primary mb-2'>
                                    <span className='fw-bold'>Reference: </span>
                                    Dr. Ahmed
                                  </p>
                                  <p className='mb-2'>Channel: DMF Doctor</p>
                                  <p className='mb-2'>Category: Direct</p>
                                  <i
                                    className='far fa-edit'
                                    onClick={this.handleAddressI}
                                  ></i>
                                </p>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>ORDER reference</p>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* ==================End Reference Section================== */}
                        {/* ==================Notes Section================== */}
                        <div
                          id='order-note-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <p className='item-title'>Note *</p>
                                <form onSubmit={this.handleNoteSubmit}>
                                  <textarea
                                    className='form-control mb-7'
                                    rows='6'
                                    placeholder='Add Note'
                                    value={this.state.orderNote}
                                    onChange={(e) =>
                                      this.setState({
                                        orderNote: e.target.value,
                                      })
                                    }
                                    maxLength={990}
                                  ></textarea>

                                  <p className='item-title'>
                                    Marked as Colored
                                  </p>
                                  <div className='form-check form-switch form-check-custom form-check-solid'>
                                    <input
                                      type='checkbox'
                                      name='new_user_only'
                                      onChange={() =>
                                        this.setState({
                                          orderNoteMarked:
                                            !this.state.orderNoteMarked,
                                        })
                                      }
                                      checked={this.state.orderNoteMarked}
                                      className='form-check-input w-50px'
                                    />
                                  </div>

                                  <div className='d-flex justify-content-end mt-8'>
                                    {this.state.orderNote !== '' && (
                                      <button
                                        className='me-3 pe-5 btn btn-danger me-4'
                                        onClick={this.handleOrderNoteFormClr}
                                        type='button'
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                    )}
                                    {this.state.orderNoteLoading ? (
                                      <BtnLoading
                                        name='Loading...'
                                        btnClass='primary submit-btn'
                                      />
                                    ) : (
                                      <button className='submit-btn btn btn-primary'>
                                        {this.state.orderNoteId
                                          ? 'Update Note'
                                          : 'Add Note'}
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>Order Notes: </p>
                                <div className='notes-all'>
                                  {this.state.order && this.state.order.orderdetail && this.state.order.orderdetail.order_status === 'cancel' && (
                                    <p
                                      className='edit-option note-edit text-danger'
                                      style={{ backgroundColor: '#fff5f8' }}
                                    >
                                      <span>
                                        <span className='fw-bold'>
                                          Cancle Note:{' '}
                                        </span>
                                        <span>{this.state.order.orderdetail.status_note}</span>
                                      </span>
                                    </p>
                                  )}
                                  {this.state.order.ordernote.length !== 0 ? (
                                    this.state.order.ordernote
                                      .reverse()
                                      .map((item, i) => (
                                        <p
                                          className='edit-option note-edit'
                                          key={i}
                                        >
                                          <span>
                                            <span className='fw-bold'>
                                              Note:{' '}
                                            </span>
                                            <span>{item.note}</span> <br />
                                            <span className='fw-bold'>
                                              Colored:{' '}
                                            </span>
                                            <span>
                                              {item.marked ? 'true' : 'False'}
                                            </span>
                                          </span>
                                          <span className='date'>
                                            <span className='fw-bold'>
                                              Updated On:{' '}
                                            </span>
                                            {moment(item.created_at).format(
                                              'DD MMM YYYY'
                                            )}
                                            ;{' '}
                                            {moment(item.created_at).format(
                                              'hh:mm A'
                                            )}
                                          </span>
                                          <i
                                            className='far fa-edit'
                                            onClick={() =>
                                              this.handleOrderNoteEdit(item)
                                            }
                                          ></i>
                                        </p>
                                      ))
                                  ) : (
                                    <p className='m-0 fs-normal'>
                                      No order notes.
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>ORDER NoteS</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Notes Section================== */}

                        {/* ==================Patients Section================== */}
                        <div
                          id='patient-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Patient Name *
                                      </p>
                                      <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='Patient Name'
                                        value={this.state.patientName}
                                        onChange={(e) =>
                                          this.setState({
                                            patientName: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className=''>
                                      <p className='item-title'>Gender *</p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.patientGender}
                                        onChange={(e) =>
                                          this.setState({
                                            patientGender: e.target.value,
                                          })
                                        }
                                      >
                                        <option value=''>Select Gender</option>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                      </select>
                                    </div>
                                    {/* <div className='mb-7'>
                                      <p className='item-title'>
                                        Contact Number
                                      </p>
                                      <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='Patient phone'
                                        value={this.state.patientNumber}
                                        onChange={(e) =>
                                          this.setState({
                                            patientNumber: e.target.value,
                                          })
                                        }
                                      />
                                    </div> */}
                                    {/* <div className='mb-7'>
                                      <p className='item-title'>Email</p>
                                      <input
                                        type='email'
                                        className='form-control form-control-solid'
                                        placeholder='Patient email'
                                        value={this.state.patientEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            patientEmail: e.target.value,
                                          })
                                        }
                                      />
                                    </div> */}
                                  </div>
                                  <div className='col-md-6 col-6in7-r row'>
                                    <div className='mb-13'>
                                      <p className='item-title'>
                                        Date Of Birth *
                                      </p>
                                      <br />
                                      <PatientDob
                                        placeholder='DD/MM/YY'
                                        patientDob={this.state.patientDob}
                                        setPatientDob={this.setPatientDob}
                                      />
                                    </div>



                                  <div className='mb-3 col-md-6'>
                                    <p className='item-title'>Weight (KG.)</p>
                                    <input
                                      type='number'
                                      step='0.1'
                                      className='form-control form-control-solid'
                                      placeholder='Patient Height'
                                      value={this.state.patientWeight}
                                      onChange={(e) =>
                                        this.setState({
                                          patientWeight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>


                                  <div className='mb-3 col-md-6'>
                                    <p className='item-title'>Height (Ft.)</p>
                                    <input
                                      type='number'
                                      step='0.1'
                                      className='form-control form-control-solid'
                                      placeholder='Patient Height'
                                      value={this.state.patientHeight}
                                      onChange={(e) =>
                                        this.setState({
                                          patientHeight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>



                                    <p className='mb-0 text-end mt-9'>
                                      {this.state.patientId ? (
                                        <div className='d-flex justify-content-end'>
                                          <button
                                            className='me-3 pe-5 btn btn-danger'
                                            onClick={this.handlePatientFormClr}
                                          >
                                            <i className='fas fa-trash'></i>
                                          </button>
                                          {this.state.patientLoading ? (
                                            <BtnLoading
                                              name='Updating'
                                              btnClass='success submit-btn'
                                            />
                                          ) : (
                                            <button
                                              className='submit-btn btn btn-success'
                                              onClick={this.handlePatientUpdate}
                                            >
                                              Update
                                            </button>
                                          )}
                                        </div>
                                      ) : (
                                        <div className='d-flex justify-content-end'>
                                          <button
                                            className='me-3 pe-5 btn btn-danger'
                                            onClick={this.handlePatientFormClr}
                                          >
                                            <i className='fas fa-trash'></i>
                                          </button>
                                          {this.state.patientLoading ? (
                                            <BtnLoading
                                              name='Adding'
                                              btnClass='primary submit-btn'
                                            />
                                          ) : (
                                            <button
                                              className='submit-btn btn btn-primary'
                                              onClick={this.handlePatientUpdate}
                                            >
                                              Add Patient
                                            </button>
                                          )}
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>All Patients: </p>
                                <div className='items'>
                                  {userPatients &&
                                    userPatients.map((patient, i) => (
                                      <p
                                        key={i}
                                        className={`edit-option loop ${patient.is_account ? 'user' : ''
                                          }`}
                                      >
                                        <div>
                                          <p className='name mb-2 text-primary fw-bold'>
                                            {patient.full_name}
                                          </p>
                                          <p className='m-0'>
                                            Age:{' '}
                                            {moment().diff(
                                              `${patient.dob}`,
                                              'years'
                                            ) + ' Years'}
                                          </p>
                                          <p className='m-0 text-capitalize'>
                                            Sex: {patient.sex}
                                          </p>


                                          {patient.patient_info && patient.patient_info.weight ? (
                                            <p className='m-0 text-capitalize'>Weight: {patient.patient_info.weight} KG.</p>
                                          ) : (
                                            <>&nbsp;</>
                                          )}

                                          {patient.patient_info && patient.patient_info.height ? (
                                            <p className='m-0 text-capitalize'>Height: {patient.patient_info.height} Ft.</p>
                                          ) : (
                                            <>&nbsp;</>
                                          )}


                                        </div>
                                        <i
                                          className='far fa-edit edit'
                                          onClick={() =>
                                            this.handlePatientEdit(patient)
                                          }
                                        ></i>
                                        {!patient.is_account && (
                                          <i
                                            className='far fa-trash-alt'
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  'Are you sure to delete this patient?'
                                                )
                                              )
                                                this.handlePatientDelete(
                                                  patient
                                                );
                                            }}
                                          ></i>
                                        )}
                                      </p>
                                    ))}
                                </div>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>patient info</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Patients Section================== */}


                        {/* ==================Schedule Section================== */}
                        <div
                          id='collection-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-0'>
                                      <p className='item-title'>
                                        Sample Collection Date *
                                      </p>
                                      <DateSelect
                                        disabled={!this.state.sampleDateTimeEditEnable}
                                        placeholderText='DD/MM/YY'
                                        sampleDate={this.state.sampleDate}
                                        setSampleDate={this.setSampleDate}
                                      />
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    <div className=''>
                                      <p className='item-title'>
                                        Sample Collection Slot *
                                      </p>
                                      <TimeSelect
                                        disabled={!this.state.sampleDateTimeEditEnable}
                                        placeholderText={
                                          this.state.timeSelectPlaceholder
                                        }
                                        sampleTime={this.state.sampleTime}
                                        setSampleTime={this.setSampleTime}
                                      />
                                    </div>
                                    <p className='mb-0 text-end mt-5 d-flex justify-content-end'>

                                      <button
                                        className='me-3 pe-5 btn btn-danger'
                                        onClick={this.clearScheduleForm}
                                        disabled={!this.state.sampleDateTimeEditEnable}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>

                                      {this.state.scheduleUpdating ? (
                                        <BtnLoading
                                          name='updating'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <button
                                          disabled={
                                            // (this.state.sampleDate || this.state.sampleTime) ? false : true
                                            !this.state.sampleDateTimeEditEnable
                                          }
                                          onClick={this.handleScheduleUpdate}
                                          className='submit-btn btn btn-primary'
                                        >
                                          Update
                                        </button>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>Overview: </p>
                                <p className='m-0 edit-option'>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>
                                      Date:{' '}
                                    </span>
                                    {moment(order.date).format(
                                      'DD MMM YYYY'
                                    )}
                                    {order.time && (
                                      <>
                                        <br />
                                        <span className='fw-bold'>
                                          Time:{' '}
                                        </span>
                                        {moment(order.time, [
                                          'HH:mm:ss',
                                        ]).format('hh:mm A')}{' '}
                                        -{' '}
                                        {moment(order.time, 'HH:mm:ss')
                                          .add(120, 'minutes')
                                          .format('hh:mm A')}
                                      </>
                                    )}
                                  </p>
                                  <i
                                    className='far fa-edit'
                                    onClick={this.handleScheduleEditClick}
                                  ></i>
                                </p>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>Schedule</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Schedule Section================== */}


                        {/* ==================Collection Section================== */}
                        <div
                          id='collection-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>Area *</p>
                                      <select
                                        className='form-select form-select-sloid'
                                        onChange={this.handleDistrict}
                                        value={this.state.district}
                                        disabled={
                                          this.state.addId ? '' : 'disabled'
                                        }
                                      >
                                        <option value=''>Select Area</option>
                                        <option value='Dhaka'>Dhaka</option>
                                        <option value='Chattogram'>
                                          Chattogram
                                        </option>
                                      </select>
                                    </div>
                                    {/* <div className='mb-7'>
                                      <p className='item-title'>Thana</p>
                                      <select
                                        onChange={(e) =>
                                          this.setState({
                                            thana: e.target.value,
                                          })
                                        }
                                        disabled={
                                          this.state.addId ? '' : 'disabled'
                                        }
                                        value={this.state.thana}
                                        className='form-select form-select-sloid'
                                      >
                                        <option value=''>Select Thana *</option>
                                        {this.state.locationLoopItem.map(
                                          (area, i) => (
                                            <option value={area} key={i}>
                                              {area}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div> */}
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Location details *
                                      </p>
                                      <textarea
                                        disabled={
                                          this.state.addId ? '' : 'disabled'
                                        }
                                        className='form-control'
                                        rows='6'
                                        placeholder='Enter details'
                                        value={this.state.address}
                                        onChange={(e) =>
                                          this.setState({
                                            address: e.target.value,
                                          })
                                        }
                                        maxLength={990}
                                      ></textarea>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    <div className='mb-7'>
                                      <p className='item-title'>Thana</p>
                                      <select
                                        onChange={(e) =>
                                          this.setState({
                                            thana: e.target.value,
                                          })
                                        }
                                        disabled={
                                          this.state.addId ? '' : 'disabled'
                                        }
                                        value={this.state.thana}
                                        className='form-select form-select-sloid'
                                      >
                                        <option value=''>Select Thana *</option>
                                        {this.state.locationLoopItem.map(
                                          (area, i) => (
                                            <option value={area} key={i}>
                                              {area}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {/* <div className='mb-2'>
                                      <p className='item-title'>
                                        Sample Collection Date *
                                      </p>
                                      <DateSelect
                                        placeholderText='DD/MM/YY'
                                        sampleDate={this.state.sampleDate}
                                        setSampleDate={this.setSampleDate}
                                      />
                                    </div>
                                    <div className=''>
                                      <p className='item-title'>
                                        Sample Collection Slot *
                                      </p>
                                      <TimeSelect
                                        placeholderText={
                                          this.state.timeSelectPlaceholder
                                        }
                                        sampleTime={this.state.sampleTime}
                                        setSampleTime={this.setSampleTime}
                                      />
                                    </div> */}
                                    <div className='mb-7 mt-2'>
                                      <p className='item-title'>
                                        Collection Phone *
                                      </p>
                                      <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        placeholder='sample collection number'
                                        value={this.state.mobile}
                                        onChange={(e) =>
                                          this.setState({
                                            mobile: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Collection Email
                                      </p>
                                      <input
                                        type='email'
                                        className='form-control form-control-solid'
                                        placeholder='sample collection email'
                                        value={this.state.addEmail}
                                        onChange={(e) =>
                                          this.setState({
                                            addEmail: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                    <p className='mb-0 text-end mt-9 d-flex justify-content-end'>
                                      {this.state.addId ? (
                                        <button
                                          className='me-3 pe-5 btn btn-danger'
                                          onClick={this.clearAddressForm}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </button>
                                      ) : (
                                        <span></span>
                                      )}
                                      {this.state.addressLoading ? (
                                        <BtnLoading
                                          name='updating'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <button
                                          disabled={
                                            this.state.addId ? '' : 'disabled'
                                          }
                                          onClick={this.handleAddressUpdate}
                                          className='submit-btn btn btn-primary'
                                        >
                                          Update
                                        </button>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* {order.orderdetail && (
                                <>
                                  {order.orderdetail.address && (
                                    <div className='col-md-4 padd-last-item'>
                                      <p className='item-title'>Overview: </p>
                                      <p className='m-0 edit-option'>
                                        <p className='fw-bold text-primary mb-2'>
                                          {order.orderdetail.address.district},{' '}
                                          {order.orderdetail.address.thana}
                                        </p>
                                        <p className='mb-2'>
                                          <span className='fw-bold'>
                                            Details:{' '}
                                          </span>
                                          {order.orderdetail.address.address}
                                        </p>
                                        <p className='mb-2'>
                                          <span className='fw-bold'>
                                            Date:{' '}
                                          </span>
                                          {moment(order.date).format(
                                            'DD MMM YYYY'
                                          )}
                                          {order.time && (
                                            <>
                                              <br />
                                              <span className='fw-bold'>
                                                Time:{' '}
                                              </span>
                                              {moment(order.time, [
                                                'HH:mm:ss',
                                              ]).format('hh:mm A')}{' '}
                                              -{' '}
                                              {moment(order.time, 'HH:mm:ss')
                                                .add(120, 'minutes')
                                                .format('hh:mm A')}
                                            </>
                                          )}
                                        </p>
                                        <p className='m-0'>
                                          <span className='fw-bold'>
                                            Phone:{' '}
                                          </span>
                                          {order.orderdetail.address.mobile}
                                          {order.orderdetail.address.email && (
                                            <>
                                              <br />
                                              <span className='fw-bold'>
                                                Email:{' '}
                                              </span>
                                              {order.orderdetail.address.email}
                                            </>
                                          )}
                                        </p>
                                        <i
                                          className='far fa-edit'
                                          onClick={this.handleAddressI}
                                        ></i>
                                      </p>
                                    </div>
                                  )}
                                </>
                              )} */}
                              {previousAddresses &&
                                <div className='col-md-4 padd-last-item' >
                                  <p className='item-title'>Overview: </p>
                                  {previousAddresses.slice(0, 2).map((prevAddrs, idx) => (
                                    <p
                                      className={`m-0 mb-4 edit-option${selectedPreviousAddress === idx ? ` selectedAddrs` : ''}`}
                                      onClick={() => {
                                        this.setSelectedPreviousAddress(idx);
                                      }}
                                    >
                                      <p className='fw-bold text-primary mb-2'>
                                        {prevAddrs.district},{' '}
                                        {prevAddrs.thana}
                                      </p>
                                      <p className='mb-2'>
                                        <span className='fw-bold'>
                                          Details:{' '}
                                        </span>
                                        {prevAddrs.address}
                                      </p>
                                      <p className='m-0'>
                                        <span className='fw-bold'>
                                          Phone:{' '}
                                        </span>
                                        {prevAddrs.mobile}
                                        {prevAddrs.email && (
                                          <>
                                            <br />
                                            <span className='fw-bold'>
                                              Email:{' '}
                                            </span>
                                            {prevAddrs.email}
                                          </>
                                        )}
                                      </p>
                                      {(selectedPreviousAddress === idx) && <i className="far fa-check-square selected edit-selected"></i>}
                                      <i
                                        className='far fa-edit'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          this.handleAddressEditClick(idx);
                                        }}
                                      ></i>
                                    </p>
                                  ))}
                                </div>

                              }
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>collection info</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Collection Section================== */}

                        {/* ==================Order Summery Section================== */}
                        <div
                          id='order-summery-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-11'>
                                <table className='mb-0 table table-row-dashed table-row-gray-300 gy-7'>
                                  <thead>
                                    <tr className='fw-bolder fs-6 text-gray-600'>
                                      <th className='min-w-200px'>
                                        PATIENT INFO.
                                      </th>
                                      <th className='min-w-200px'>
                                        DIAGNOSTIC INFO.
                                      </th>

                                      <th className='min-w-20px'>
                                        &nbsp;
                                      </th>
                                      <th className='min-w-150px'>
                                        &nbsp;
                                      </th>

                                      <th className='min-w-75px'>PRICE</th>
                                      <th className='min-w-150px'>
                                        ORDERED FROM
                                      </th>
                                      <th className='min-w-50px'></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {order.orderitem.map((item, i) => (
                                      <SingleOrderItem
                                        key={i}
                                        item={item}
                                        handleTestItemDelete={
                                          this.handleTestItemDelete
                                        }
                                      />
                                    ))}
                                  </tbody>
                                </table>
                                <div
                                  className='row border-bottom-dashed border-top-dashed border-gray-300 gy-1 py-4'
                                  style={{ borderWidth: '1px' }}
                                >
                                  <div className='col-md-8'>
                                    <p className='mb-2 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Total Tests:{' '}
                                      </span>
                                      {order.orderitem.length || '0'}
                                    </p>
                                    <p className='mb-2 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Diagnostic Price:{' '}
                                      </span>
                                      ৳{parseInt(order.total_price) || '00'}
                                    </p>
                                    <p className='mb-2 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Collection Fee:{' '}
                                      </span>
                                      ৳{parseInt(order.collection_fee) || '00'}
                                    </p>
                                    <p className='m-0 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Material Fee:{' '}
                                      </span>
                                      ৳{parseInt(order.material_fee) || '00'}
                                    </p>
                                  </div>
                                  <div className='col-md-5'></div>
                                </div>
                                <div className='py-4 row'>
                                  <div className='col-md-8'>
                                    <p className='mb-2 text-end fs-6'>
                                      <span className='fw-bold'>Total: </span>৳
                                      {parseInt(order.total_price) +
                                        parseInt(order.collection_fee) +
                                        parseInt(order.material_fee)}
                                    </p>
                                    <p className='mb-2 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Discount:{' '}
                                      </span>
                                      -৳
                                      {order.orderdiscount
                                        ? `${parseInt(
                                          order.orderdiscount.discount_price
                                        )}`
                                        : '00'}
                                    </p>
                                    <p className='m-0 text-end fs-6'>
                                      <span className='fw-bold'>
                                        Total Payable:{' '}
                                      </span>
                                      ৳
                                      {order.orderdiscount
                                        ? `${parseInt(order.total_price) +
                                        parseInt(order.collection_fee) +
                                        parseInt(order.material_fee) -
                                        parseInt(
                                          order.orderdiscount.discount_price
                                        )
                                        }`
                                        : parseInt(order.total_price) +
                                        parseInt(order.collection_fee) +
                                        parseInt(order.material_fee)}
                                    </p>
                                  </div>
                                  <div className='col-md-4 align-self-end'>
                                    <p className='mb-2 text-end'>
                                      <button
                                        className='submit-btn btn btn-primary'
                                        onClick={() =>
                                          this.handleTestItemModal(
                                            this.state.order
                                          )
                                        }
                                      >
                                        Add New Test
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>order summery</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Order Summery Section================== */}

                        {/* ==================Payment Section================== */}
                        <div
                          id='payment-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Payment Status *
                                      </p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.paymentStatus}
                                        onChange={(e) =>
                                          this.setState({
                                            paymentStatus: e.target.value,
                                          })
                                        }
                                        disabled={
                                          this.state.paymentEdit
                                            ? ''
                                            : 'disabled'
                                        }
                                      >
                                        <option value=''>Payment Status</option>
                                        <option value='paid'>Paid</option>
                                        <option value='unpaid'>Unpaid</option>
                                      </select>
                                    </div>
                                    <div className='mb-7'>
                                      <p className='item-title'>Payment Mode</p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.paymentSource}
                                        onChange={(e) =>
                                          this.setState({
                                            paymentSource: e.target.value,
                                          })
                                        }
                                        disabled={
                                          this.state.paymentEdit
                                            ? ''
                                            : 'disabled'
                                        }
                                      >
                                        <option value='cod'>
                                          Cash On Delivery
                                        </option>
                                        <option value='sslc'>
                                          SSL Commerze
                                        </option>
                                        <option value='bkash'>Bkash</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    <div className=''>
                                      <p className='item-title'>Payment Note</p>
                                      <textarea
                                        className='form-control'
                                        rows='6'
                                        placeholder='Payment Note'
                                        value={this.state.paymentNote}
                                        onChange={(e) =>
                                          this.setState({
                                            paymentNote: e.target.value,
                                          })
                                        }
                                        maxLength={990}
                                        disabled={
                                          this.state.paymentEdit
                                            ? ''
                                            : 'disabled'
                                        }
                                      ></textarea>
                                    </div>
                                    <p className='mb-0 text-end mt-9'>
                                      {this.state.paymentLoading ? (
                                        <BtnLoading
                                          name='updating'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <p className='mb-0 text-end mt-9 d-flex justify-content-end'>
                                          <button
                                            className='me-3 pe-5 btn btn-danger'
                                            onClick={() =>
                                              this.setState({
                                                paymentStatus: null,
                                                paymentSource: null,
                                                paymentNote: null,
                                              })
                                            }
                                            disabled={
                                              this.state.paymentEdit
                                                ? ''
                                                : 'disabled'
                                            }
                                          >
                                            <i className='fas fa-trash'></i>
                                          </button>
                                          <button
                                            className='submit-btn btn btn-primary'
                                            disabled={
                                              this.state.paymentEdit
                                                ? ''
                                                : 'disabled'
                                            }
                                            onClick={this.handlePaymentInfo}
                                          >
                                            Update
                                          </button>
                                        </p>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>Payment Overview: </p>
                                <p className='m-0 edit-option'>
                                  <p className='fw-bold text-danger mb-2 text-uppercase'>
                                    {order.orderdetail
                                      ? order.orderdetail.payment_status
                                      : ''}
                                  </p>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>Amount: </span>৳
                                    {order.orderdiscount
                                      ? `${parseInt(order.total_price) +
                                      parseInt(order.collection_fee) +
                                      parseInt(order.material_fee) -
                                      parseInt(
                                        order.orderdiscount.discount_price
                                      )
                                      }`
                                      : parseInt(order.total_price) +
                                      parseInt(order.collection_fee) +
                                      parseInt(order.material_fee)}
                                  </p>
                                  <p className='m-0'>
                                    <span className='fw-bold'>Note: </span>
                                    {order.orderdetail
                                      ? order.orderdetail.payment_note
                                      : ''}
                                  </p>
                                  <i
                                    className='far fa-edit'
                                    onClick={this.handlePaymentEdit}
                                  ></i>
                                </p>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>payment info</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Payment Section================== */}

                        {/* ==================Discount Section================== */}
                        <div
                          id='discount-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Discount Type
                                      </p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.orderDiscountSource}
                                        onChange={
                                          this.handleOrderDiscountSource
                                        }
                                      // disabled={
                                      //   this.state.orderDiscountEdit
                                      //     ? ''
                                      //     : 'disabled'
                                      // }
                                      >
                                        <option value=''>Select Type</option>
                                        <option value='admin'>Custom</option>
                                        <option value='collection_fee'>
                                          Collection Fee
                                        </option>
                                        <option value='material_fee'>
                                          Material Fee
                                        </option>
                                        <option value='fee'>
                                          Fee (Collection + Material)
                                        </option>
                                        <option value='fee_discount'>
                                          Fee (Collection + Material + Custom)
                                        </option>
                                      </select>
                                    </div>
                                    {orderDiscountSource === '' ||
                                      orderDiscountSource === null ||
                                      orderDiscountSource === 'admin' ||
                                      orderDiscountSource === 'fee_discount' ? (
                                      <div className='mb-7'>
                                        <p className='item-title'>Rate</p>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          placeholder='discount rate'
                                          value={this.state.orderDiscountPrice}
                                          onChange={(e) =>
                                            this.setState({
                                              orderDiscountPrice:
                                                e.target.value,
                                            })
                                          }
                                        // disabled={
                                        //   this.state.orderDiscountEdit
                                        //     ? ''
                                        //     : 'disabled'
                                        // }
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    {orderDiscountSource === 'collection_fee' ||
                                      orderDiscountSource === 'fee' ||
                                      orderDiscountSource === 'fee_discount' ? (
                                      <div className='mb-7'>
                                        <p className='item-title'>
                                          Discount Collection
                                        </p>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          placeholder='discount collection'
                                          value={this.state.orderDiscountColl}
                                          onChange={(e) =>
                                            this.setState({
                                              orderDiscountColl: e.target.value,
                                            })
                                          }
                                        // disabled={
                                        //   this.state.orderDiscountEdit
                                        //     ? ''
                                        //     : 'disabled'
                                        // }
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    {orderDiscountSource === 'material_fee' ||
                                      orderDiscountSource === 'fee' ? (
                                      <div className=''>
                                        <p className='item-title'>
                                          Discount Material
                                        </p>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          placeholder='discount material'
                                          value={this.state.orderDiscountMate}
                                          onChange={(e) =>
                                            this.setState({
                                              orderDiscountMate: e.target.value,
                                            })
                                          }
                                        // disabled={
                                        //   this.state.orderDiscountEdit
                                        //     ? ''
                                        //     : 'disabled'
                                        // }
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    {orderDiscountSource === 'fee_discount' ? (
                                      <div className='mb-7'>
                                        <p className='item-title'>
                                          Discount Material
                                        </p>
                                        <input
                                          type='text'
                                          className='form-control form-control-solid'
                                          placeholder='discount material'
                                          value={this.state.orderDiscountMate}
                                          onChange={(e) =>
                                            this.setState({
                                              orderDiscountMate: e.target.value,
                                            })
                                          }
                                        // disabled={
                                        //   this.state.orderDiscountEdit
                                        //     ? ''
                                        //     : 'disabled'
                                        // }
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <div className=''>
                                      <p className='item-title'> Note</p>
                                      <textarea
                                        className='form-control'
                                        rows='6'
                                        placeholder='discount note'
                                        value={this.state.orderDiscountNote}
                                        onChange={(e) =>
                                          this.setState({
                                            orderDiscountNote: e.target.value,
                                          })
                                        }
                                        maxLength={990}
                                      // disabled={
                                      //   this.state.orderDiscountEdit
                                      //     ? ''
                                      //     : 'disabled'
                                      // }
                                      ></textarea>
                                    </div>
                                    <p className='mb-0 text-end mt-9'>
                                      {this.state.orderDiscountLoading ? (
                                        <BtnLoading
                                          name='updating'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <p className='mb-0 text-end mt-9 d-flex justify-content-end'>
                                          <button
                                            className='me-3 pe-5 btn btn-danger'
                                            onClick={() =>
                                              this.setState({
                                                orderDiscountSource: '',
                                                orderDiscountPrice: null,
                                                orderDiscountColl: null,
                                                orderDiscountMate: null,
                                                orderDiscountNote: '',
                                              })
                                            }
                                          >
                                            <i className='fas fa-trash'></i>
                                          </button>
                                          <button
                                            // disabled={
                                            //   this.state.orderDiscountEdit
                                            //     ? ''
                                            //     : 'disabled'
                                            // }
                                            onClick={this.handleOrderDiscount}
                                            className='submit-btn btn btn-primary'
                                          >
                                            Update
                                          </button>
                                        </p>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>
                                  Discount Overview:{' '}
                                </p>
                                <p className='m-0 edit-option'>
                                  <p className='fw-bold text-danger mb-2 text-uppercase'>
                                    {order.orderdiscount &&
                                      order.orderdiscount.discount_source.replace(
                                        /_/g,
                                        ' '
                                      )}
                                  </p>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>Amount: </span>৳
                                    {parseInt(order.total_price) +
                                      parseInt(order.material_fee) +
                                      parseInt(order.collection_fee)}
                                  </p>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>Discount: </span>৳
                                    -
                                    {order.orderdiscount
                                      ? parseInt(
                                        order.orderdiscount.discount_price
                                      )
                                      : '00'}
                                  </p>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>
                                      Collection:{' '}
                                    </span>
                                    ৳ -
                                    {order.orderdiscount
                                      ? parseInt(
                                        order.orderdiscount
                                          .collection_discount_price
                                      )
                                      : '00'}
                                  </p>
                                  <p className='mb-2'>
                                    <span className='fw-bold'>Material: </span>৳
                                    -
                                    {order.orderdiscount
                                      ? parseInt(
                                        order.orderdiscount
                                          .material_discount_price
                                      )
                                      : '00'}
                                  </p>
                                  <p
                                    className='m-0 pt-2 border-top-dashed border-gray-300'
                                    style={{ borderWidth: '1px' }}
                                  >
                                    <span className='fw-bold'>
                                      Total Payable:{' '}
                                    </span>
                                    ৳
                                    {order.orderdiscount
                                      ? `${parseInt(order.total_price) +
                                      parseInt(order.collection_fee) +
                                      parseInt(order.material_fee) -
                                      parseInt(
                                        order.orderdiscount.discount_price
                                      )
                                      }`
                                      : parseInt(order.total_price) +
                                      parseInt(order.collection_fee) +
                                      parseInt(order.material_fee)}
                                  </p>
                                  <i
                                    className='far fa-edit'
                                    onClick={this.handleOrderDiscountEdit}
                                  ></i>
                                </p>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>discount info</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Discount Section================== */}
                        {/* ==================Sneho Section================== */}

                        <div
                          id='report-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                            <div className='col-md-7 padd-fir-item'>
                              <div className="row">
                                <div className="col-md-6 col-6in7-l">
                                  <div className='cro-mt'>
                                     <p className='fw-bold'>From Sneho</p>
                                     <div style={{height: '52px'}} className='form-check form-switch form-check-custom form-check-solid'>
                                       <input
                                         type='checkbox'
                                         name='from_sneho'
                                         onChange={() =>
                                           this.setState((prevState) => ({
                                             from_sneho: !prevState.from_sneho,
                                           }))
                                         }
                                         checked={this.state.from_sneho}
                                         className='form-check-input w-50px'
                                       />
                                     </div>
                                 </div>
                                 {this.state.from_sneho && (
                                 <div className='cro-mt'>
                                     <p className='fw-bold'>Sneho Prescription ID *</p>
                                     <input
                                     style={{
                                       height: '52px',
                                       top: '-2px',
                                       position: 'relative',
                                     }}
                                       type='number'
                                       className='form-control form-control-solid'
                                       placeholder='Sneho Prescription ID *'
                                       value={this.state.sneho_prescription_id}
                                       onChange={(e) =>
                                         this.setState({
                                           sneho_prescription_id: e.target.value,
                                         })
                                       }
                                     />
                                 </div>
                                 )}
                               </div>
                              </div>
                             </div>
                             <div className="col-md-4 padd-last-item">
                              &nbsp;
                             </div>
                             <div class="col-md-1 section-name-right"><p class="name-vertical">Sneho</p></div>
                           </div>
                          </div>
                        </div>
                        {/* ==================End Sneho Section================== */}
                        {/* ==================Report Overview Section================== */}
                        <div
                          id='report-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-7 padd-fir-item'>
                                <div className='row'>
                                  <div className='col-md-6 col-6in7-l'>
                                    <div className='mb-7'>
                                      <p className='item-title'>
                                        Report Type *
                                      </p>
                                      <select
                                        className='form-select form-select-sloid'
                                        value={this.state.serviceStatus}
                                        onChange={(e) => {
                                          this.setState({
                                            serviceStatus: e.target.value,
                                          });

                                          if (e.target.value === 'hard') {
                                            this.setState({
                                              reportStatusSoft: '',
                                            });
                                          } else if (
                                            e.target.value === 'soft'
                                          ) {
                                            this.setState({
                                              reportStatusHard: '',
                                            });
                                          }
                                        }}
                                      // disabled={
                                      //   this.state.reportOverviewEdit
                                      //     ? ''
                                      //     : 'disabled'
                                      // }
                                      >
                                        <option value='both'>Both Copy</option>
                                        <option value='hard'>Hard Copy</option>
                                        <option value='soft'>Soft Copy</option>
                                      </select>
                                    </div>
                                    {order.orderdetail && (
                                      <>
                                        {this.state.serviceStatus === 'soft' ||
                                          this.state.serviceStatus === 'both' ? (
                                          <div className='mb-7'>
                                            <p className='item-title'>
                                              Report Status (Soft)
                                            </p>
                                            <select
                                              className='form-select form-select-sloid'
                                              value={
                                                this.state.reportStatusSoft
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  reportStatusSoft:
                                                    e.target.value,
                                                })
                                              }
                                            // disabled={
                                            //   this.state.reportOverviewEdit
                                            //     ? ''
                                            //     : 'disabled'
                                            // }
                                            >
                                              <option value=''>
                                                Select Copy
                                              </option>
                                              <option value='no_soft_copy'>
                                                No Soft Copy
                                              </option>
                                              <option value='soft_copy_sent'>
                                                Soft Copy Sent
                                              </option>
                                              <option value='soft_copy_due'>
                                                Soft Copy Due
                                              </option>
                                            </select>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                    {this.state.reportStatusSoft ===
                                      'soft_copy_due' && (
                                        <DueDateSelect
                                          placeholderText='Soft Due Date and Time'
                                          sampleDate={
                                            this.state.reportStatusSoftDate
                                          }
                                          setSampleDate={(v) =>
                                            this.setState({
                                              reportStatusSoftDate: v,
                                            })
                                          }
                                        />
                                      )}
                                    {order.orderdetail && (
                                      <>
                                        {this.state.serviceStatus === 'hard' ||
                                          this.state.serviceStatus === 'both' ? (
                                          <div className='mb-7'>
                                            <p className='item-title'>
                                              Report Status (Hard)
                                            </p>
                                            <select
                                              className='form-select form-select-sloid'
                                              value={
                                                this.state.reportStatusHard
                                              }
                                              onChange={(e) =>
                                                this.setState({
                                                  reportStatusHard:
                                                    e.target.value,
                                                })
                                              }
                                            // disabled={
                                            //   this.state.reportOverviewEdit
                                            //     ? ''
                                            //     : 'disabled'
                                            // }
                                            >
                                              <option value=''>
                                                Select Copy
                                              </option>
                                              <option value='no_hard_copy'>
                                                No Hard Copy
                                              </option>
                                              <option value='hard_copy_sent'>
                                                Hard Copy Sent
                                              </option>
                                              <option value='hard_copy_due'>
                                                Hard Copy Due
                                              </option>
                                            </select>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                    {this.state.reportStatusHard ===
                                      'hard_copy_due' && (
                                        <DueDateSelect
                                          placeholderText='Hard Due Date and Time'
                                          sampleDate={
                                            this.state.reportStatusHardDate
                                          }
                                          setSampleDate={(v) =>
                                            this.setState({
                                              reportStatusHardDate: v,
                                            })
                                          }
                                        />
                                      )}
                                    {/* <div className='mb-7'>
                                      <p className='item-title'>
                                        Set Notification Schedule
                                      </p>
                                      <select
                                        disabled={
                                          this.state.reportOverviewEdit
                                            ? ''
                                            : 'disabled'
                                        }
                                        className='form-select form-select-sloid'
                                      >
                                        <option value=''>
                                          Select Date & time
                                        </option>
                                      </select>
                                    </div> */}
                                  </div>
                                  <div className='col-md-6 col-6in7-r'>
                                    {order.orderdetail && (
                                      <>
                                        {this.state.serviceStatus === 'soft' ||
                                          this.state.serviceStatus === 'both' ? (
                                          <div className='mb-7'>
                                            <p className='item-title'>
                                              Note (Soft)
                                            </p>
                                            <textarea
                                              className='form-control'
                                              rows='3'
                                              placeholder=''
                                              // disabled={
                                              //   this.state.reportOverviewEdit
                                              //     ? ''
                                              //     : 'disabled'
                                              // }
                                              value={this.state.reportSoftNote}
                                              onChange={(e) =>
                                                this.setState({
                                                  reportSoftNote:
                                                    e.target.value,
                                                })
                                              }
                                              maxLength={990}
                                            ></textarea>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                    {order.orderdetail && (
                                      <>
                                        {this.state.serviceStatus === 'both' ||
                                          this.state.serviceStatus === 'hard' ? (
                                          <div className=''>
                                            <p className='item-title'>
                                              Note (Hard)
                                            </p>
                                            <textarea
                                              className='form-control'
                                              rows='3'
                                              placeholder=''
                                              // disabled={
                                              //   this.state.reportOverviewEdit
                                              //     ? ''
                                              //     : 'disabled'
                                              // }
                                              maxLength={990}
                                              value={this.state.reportHardNote}
                                              onChange={(e) =>
                                                this.setState({
                                                  reportHardNote:
                                                    e.target.value,
                                                })
                                              }
                                            ></textarea>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </>
                                    )}
                                    <p className='mb-0 text-end mt-9 d-flex justify-content-end'>
                                      <button
                                        className='me-3 pe-5 btn btn-danger'
                                        onClick={this.clearReportStatusForm}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                      <button
                                        // disabled={
                                        //   this.state.reportOverviewEdit
                                        //     ? ''
                                        //     : 'disabled'
                                        // }
                                        onClick={this.updateReportOverview}
                                        className='submit-btn btn btn-primary'
                                      >
                                        Add
                                      </button>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-4 padd-last-item'>
                                <p className='item-title'>Report Overview: </p>
                                <p className='mb-5 edit-option'>
                                  <p className='fw-bold text-danger mb-0 text-uppercase'>
                                    {order.orderdetail
                                      ? order.orderdetail.delivery_copy_status
                                      : ''}{' '}
                                    Copy
                                  </p>
                                </p>
                                <div className='row'>
                                  {order.orderdetail &&
                                    (order.orderdetail.delivery_copy_status ===
                                      'soft' ||
                                      order.orderdetail.delivery_copy_status ===
                                      'both') && (
                                      <div
                                        className={`${order.orderdetail
                                          .delivery_copy_status === 'soft'
                                          ? 'col-md-12'
                                          : 'col-md-6'
                                          } mb-5`}
                                      >
                                        <p className='item-title'>Soft:</p>
                                        <div
                                          style={{
                                            maxHeight: 230,
                                            overflow: 'scroll',
                                          }}
                                        >
                                          {order.orderreportdelivery.length !==
                                            0 &&
                                            this.state.softCopyStatus
                                              .reverse()
                                              .map((copy, i) => (
                                                <div
                                                  className='edit-option'
                                                  key={i}
                                                >
                                                  <p className='text-capitalize mb-0'>
                                                    <span className='fw-bold'>
                                                      Name:{' '}
                                                    </span>
                                                    {this._toSpace(copy.name)}
                                                  </p>
                                                  <p className='m-0'>
                                                    <span className='fw-bold'>
                                                      Note:{' '}
                                                    </span>
                                                    {copy.note}
                                                  </p>
                                                  {copy.due_date && (
                                                    <p className='m-0'>
                                                      <span className='fw-bold'>
                                                        Due Date:{' '}
                                                      </span>
                                                      {moment(
                                                        copy.due_date
                                                      ).format(
                                                        'DD MMM YYYY; hh:mm A'
                                                      )}
                                                    </p>
                                                  )}
                                                </div>
                                              ))}
                                        </div>
                                      </div>
                                    )}
                                  {order.orderdetail &&
                                    (order.orderdetail.delivery_copy_status ===
                                      'hard' ||
                                      order.orderdetail.delivery_copy_status ===
                                      'both') && (
                                      <div
                                        className={`${order.orderdetail
                                          .delivery_copy_status === 'hard'
                                          ? 'col-md-12'
                                          : 'col-md-6'
                                          } `}
                                      >
                                        <p className='item-title'>Hard:</p>
                                        <div
                                          style={{
                                            maxHeight: 230,
                                            overflow: 'scroll',
                                          }}
                                        >
                                          {order.orderreporthardcopydelivery
                                            .length !== 0 &&
                                            this.state.hardCopyStatus
                                              .reverse()
                                              .map((copy, i) => (
                                                <div
                                                  className='edit-option'
                                                  key={i}
                                                >
                                                  <p className='text-capitalize mb-0'>
                                                    <span className='fw-bold'>
                                                      Name:{' '}
                                                    </span>
                                                    {this._toSpace(copy.name)}
                                                  </p>
                                                  <p className='m-0'>
                                                    <span className='fw-bold'>
                                                      Note:{' '}
                                                    </span>
                                                    {copy.note}
                                                  </p>
                                                  {copy.due_date && (
                                                    <p className='m-0'>
                                                      <span className='fw-bold'>
                                                        Due Date:{' '}
                                                      </span>
                                                      {moment(
                                                        copy.due_date
                                                      ).format(
                                                        'DD MMM YYYY; hh:mm A'
                                                      )}
                                                    </p>
                                                  )}
                                                </div>
                                              ))}
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {/* <p className='m-0 edit-option'>
                                  {order.orderdetail && (
                                    <>
                                      {order.orderdetail
                                        .delivery_copy_status === 'soft' ||
                                      order.orderdetail.delivery_copy_status ===
                                        'both' ? (
                                        <>
                                          {order.orderreportdelivery.length !==
                                            0 && (
                                            <p className='mb-2'>
                                              <span className='fw-bold'>
                                                Note (Soft):{' '}
                                              </span>
                                              {this.lastReportStatus(
                                                order.orderreportdelivery
                                              )}
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}
                                  {order.orderdetail && (
                                    <>
                                      {order.orderdetail
                                        .delivery_copy_status === 'hard' ||
                                      order.orderdetail.delivery_copy_status ===
                                        'both' ? (
                                        <>
                                          {order.orderreporthardcopydelivery
                                            .length !== 0 && (
                                            <p className='mb-2'>
                                              <span className='fw-bold'>
                                                Note (Hard):{' '}
                                              </span>
                                              {this.lastReportStatus(
                                                order.orderreporthardcopydelivery
                                              )}
                                            </p>
                                          )}
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}
                                  <i
                                    className='far fa-edit'
                                    onClick={this.handleReportOverviewI}
                                  ></i>
                                </p> */}
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>report overview</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Report Overview Section================== */}

                        {/* ==================Report Management Section================== */}
                        <div
                          id='report-info-nav'
                          className='card card-custom card-stretch gutter-b mt-7'
                        >
                          <div className='card-body py-2 my-6'>
                            <div className='row'>
                              <div className='col-md-11'>
                                <div className='row'>
                                  <div className='col-md-8 padd-fir-item report-left'>
                                    <div className='row'>
                                      <div className='col-md-6 col-6in7-l'>
                                        <div className='mb-7'>
                                          <p className='item-title'>
                                            Select Patient *
                                          </p>
                                          <select
                                            className='form-select form-select-sloid'
                                            value={
                                              this.state.orderReportPatientName
                                            }
                                            onChange={
                                              this.handleOrderReportPatient
                                            }
                                            disabled={
                                              this.state.order.orderdetail
                                                .report_uploaded
                                                ? 'disabled'
                                                : ''
                                            }
                                          >
                                            <option value=''>
                                              Select Patient
                                            </option>
                                            {this.state.orderAllPatients.map(
                                              (patient, i) => (
                                                <option
                                                  value={patient.id}
                                                  key={patient.id + i}
                                                >
                                                  {patient.full_name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                        <div className='mb-7  file-patient'>
                                          <p className='item-title'>
                                            Select Diagnostic *
                                          </p>
                                          <SelectTests
                                            allTests={this.state.orderAllTests}
                                            setOrderReportTests={
                                              this.setOrderReportTests
                                            }
                                            orderTests={
                                              this.state.orderReportTestName
                                            }
                                          />
                                        </div>
                                        <div>
                                          <p className='item-title'>
                                            Report Note
                                          </p>
                                          <textarea
                                            maxLength={990}
                                            className='form-control'
                                            rows='3'
                                            placeholder='Enter report note'
                                            value={this.state.orderReportNote}
                                            onChange={(e) =>
                                              this.setState({
                                                orderReportNote: e.target.value,
                                              })
                                            }
                                          ></textarea>
                                        </div>
                                      </div>
                                      <div className='col-md-6 col-6in7-r'>
                                        <div className='mb-7'>
                                          <p className='item-title'>
                                            Report Name
                                          </p>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid mb-5'
                                            placeholder='Report name'
                                            value={this.state.orderReportName}
                                            onChange={(e) =>
                                              this.setState({
                                                orderReportName: e.target.value,
                                              })
                                            }
                                            disabled
                                          />
                                        </div>
                                        <div className='mb-9'>
                                          <p className='item-title'>
                                            Upload File *
                                          </p>
                                          <input
                                            type='file'
                                            className='form-control form-control-solid mb-5'
                                            placeholder='Upload File'
                                            multiple
                                            onChange={(e) => {
                                              this.setState({
                                                orderReportFile: e.target.files,
                                              });
                                            }}
                                            accept='application/pdf'
                                            disabled={
                                              this.state.order.orderdetail
                                                .report_uploaded
                                                ? 'disabled'
                                                : ''
                                            }
                                          />
                                        </div>
                                        <div className=''>
                                          <p className='item-title'>
                                            All Report Uploaded *
                                          </p>
                                          <div className='form-check form-switch form-check-custom form-check-solid'>
                                            <input
                                              type='checkbox'
                                              name='report_uploaded'
                                              onChange={
                                                this.handleReportUploaded
                                              }
                                              checked={
                                                this.state.orderReportUploaded
                                              }
                                              className='form-check-input w-50px'
                                            />
                                          </div>
                                        </div>
                                        <p className='mb-0 text-end mt-9'>
                                          {!this.state.orderReportLoading ? (
                                            <button
                                              className='submit-btn btn btn-primary'
                                              onClick={this.handleFileUpload}
                                              disabled={
                                                this.state.order.orderdetail
                                                  .report_uploaded
                                                  ? 'disabled'
                                                  : ''
                                              }
                                            >
                                              Upload
                                            </button>
                                          ) : (
                                            <BtnLoading
                                              name='Uploading'
                                              btnClass='primary submit-btn'
                                            />
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-4 padd-last-item report-right'>
                                    <p className='item-title'>
                                      Report Overview:{' '}
                                    </p>
                                    <div className='reports'>
                                      {reports && reports.length !== 0 ? (
                                        reports.map((report, i) => (
                                          <div className='single' key={i}>
                                            <div className='head'>
                                              <h5 className='fs-5 m-0'>
                                                {report.patient.full_name}
                                              </h5>
                                              <button className='btn' disabled>
                                                Download All
                                              </button>
                                            </div>
                                            <div className='body'>
                                              {report.reports.map((file, i) => (
                                                <div className='pdf' key={i}>
                                                  <div className='left'>
                                                    <img src={PdfIcon} alt='' />
                                                    <div className='name-date'>
                                                      <div>
                                                        {file.name
                                                          ? file.name
                                                          : 'Report ' + (i + 1)}
                                                      </div>
                                                      <div className='date'>
                                                        Uploaded on:{' '}
                                                        {moment(
                                                          file.created_at
                                                        ).format(
                                                          'DD MMM YYYY; hh:mm A'
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className='right'>
                                                    <a
                                                      href={file.report}
                                                      target='_blank'
                                                      rel='noopener noreferrer'
                                                    >
                                                      <img
                                                        src={DownloadIcon}
                                                        alt=''
                                                        className='download'
                                                      />
                                                    </a>
                                                    <img
                                                      src={CrossIcon}
                                                      alt=''
                                                      onClick={() => {
                                                        if (
                                                          window.confirm(
                                                            'Are you sure to delete this file?'
                                                          )
                                                        )
                                                          this.handleFileDelete(
                                                            file
                                                          );
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        ))
                                      ) : (
                                        <p className='fs-6'>
                                          Sorry, No Report Uploaded Yet.
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-12 report-email-head'>
                                    <div>
                                      <h2>SEND REPORTS VIA EMAIL</h2>
                                    </div>
                                  </div>
                                  <div className='col-md-8 padd-fir-item report-left'>
                                    <div className='row'>
                                      <div className='col-md-12 col-6in7-l'>
                                        <div className='mb-7'>
                                          <p className='item-title'>Email *</p>
                                          <input
                                            type='text'
                                            className='form-control form-control-solid'
                                            placeholder='comma seprated mulitple email address'
                                            value={this.state.reportSendEmails}
                                            onChange={(e) =>
                                              this.setState({
                                                reportSendEmails:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-md-4 padd-last-item report-right'>
                                    <p className='mb-0 text-end mt-10'>
                                      {!this.state.reportSendEmailsLoading ? (
                                        <button
                                          className='submit-btn btn btn-primary'
                                          onClick={this.handleSendEmail}
                                          disabled={
                                            reports && reports.length !== 0
                                              ? ''
                                              : 'disabled'
                                          }
                                        >
                                          Send Email
                                        </button>
                                      ) : (
                                        <BtnLoading
                                          name='Sending...'
                                          btnClass='primary submit-btn'
                                        />
                                      )}
                                    </p>
                                  </div>
                                  {order.orderemail &&
                                    order.orderemail.length !== 0 && (
                                      <>
                                        <div className='col-12 report-email-head'>
                                          <div>
                                            <h2>EMAIL Log</h2>
                                          </div>
                                        </div>
                                        <div className='col-md-12 padd-fir-item report-left mb-7'>
                                          <div className='row'>
                                            <div className='col-md-12 col-6in7-l'>
                                              <div className='email-log items'>
                                                {order.orderemail.map(
                                                  (item) => (
                                                    <SingleEmailLog
                                                      key={item.id}
                                                      item={item}
                                                    />
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </div>
                              <div className='col-md-1 section-name-right'>
                                <p className='name-vertical'>
                                  report management
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-12'>
                                {this.state.headUpdateLoading ? (
                                  <BtnLoading
                                    name='UPDATING...'
                                    btnClass='primary w-100 py-5 fs-2'
                                  />
                                ) : (
                                  <button
                                    className='btn btn-primary w-100 py-5 fs-2'
                                    onClick={this.handleHeadUpdateBtn}
                                  >
                                    UPDATE ORDER
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ==================End Report Management Section================== */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {order && (
          <OrderItemEditNew
            itemEditModal={this.state.orderItemAddModal}
            setItemEditModal={this.setOrderItemAddModal}
            userPatients={userPatientsShown}
            setUserPatients={this.setUserPatients}
            addressId={order.orderdetail.address.id}
            orderEditId={order.id}
            testItemModalClose={this.testItemModalClose}
            orderUserId={order.user.id}
            fees={this.state.fees}
            mainOrder={order}
            userPatientName={this.state.userPatientName}
            handlePatientSearch={this.handlePatientSearch}
            userDetails={this.state.order.user}
            userAllPatients={userPatients}
            labDiscountArray={this.state.labDiscountArray}
            updateDiscount={this.updateDiscount}
          />
        )}

        <MtAssignHistoryModal
          mt_assign_modal={this.state.mt_assign_modal}
          setMtAssignModal={this.setMtAssignModal}
          data={this.state.mtAssignHistory}
        />
      </>
    );
  }
}

export default OrderEdit;
OrderEdit.contextType = AuthContext;

const SingleOrderItem = ({ item, handleTestItemDelete }) => (
  <tr className='fs-6'>
    <td>
      {item.patient.full_name} (
      <span className='text-uppercase'>{item.patient.sex.substring(0, 1)}</span>
      ,{' '}
      {item.patient.dob === null
        ? ''
        : moment().diff(`${item.patient.dob}`, 'years') + 'y'}
      )
    </td>
    <td>
      {item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_oi &&
        item.purchasable_order_item.testitem_purchasable_oi &&
        item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
        item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.name
        ? item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_oi &&
        item.purchasable_order_item.testitem_purchasable_oi &&
        item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
        item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.name
        : ' '}{' '}


      {item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_order &&
        item.purchasable_order_item.testitem_purchasable_order[0] &&
        item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
        item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.name
        ? item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_order &&
        item.purchasable_order_item.testitem_purchasable_order[0] &&
        item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
        item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.name
        : ' '}{' '}


    </td>


    <td >

        <Tooltip
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          overlay={<div style={{width: 450 }}>

            <strong style={{fontSize:18, display:'block', marginBottom:2, paddingBottom:2}}>Test Preparation:</strong>

            {item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.test_preparation
              ? item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.test_preparation
              : ' '}{' '}


            {item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_order &&
              item.purchasable_order_item.testitem_purchasable_order[0] &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.test_preparation
              ? item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_order &&
              item.purchasable_order_item.testitem_purchasable_order[0] &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.test_preparation
              : 'Empty'}{' '}

          </div>}
        >
          <i class="n-a fa fa-list" aria-hidden="true" data-tip=""  data-for={`prep_${item.id}`}></i>
        </Tooltip>



    </td>
    <td >

        <Tooltip
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          overlay={<div style={{width: 450 }}>

            <strong style={{fontSize:18, display:'block', marginBottom:2, paddingBottom:2}}>Remarks:</strong>

            {item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.remarks
              ? item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.remarks
              : ' '}{' '}


            {item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_order &&
              item.purchasable_order_item.testitem_purchasable_order[0] &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.remarks
              ? item &&
              item.purchasable_order_item &&
              item.purchasable_order_item.testitem_purchasable_order &&
              item.purchasable_order_item.testitem_purchasable_order[0] &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test &&
              item.purchasable_order_item.testitem_purchasable_order[0].diagnostic_test.remarks
              : 'Empty'}{' '}

          </div>}
        >
          <i class="n-a fa fa-lightbulb" data-tip=""  data-for={`rem_${item.id}`}></i>
        </Tooltip>



    </td>



    <td>
      {' '}
      ৳
      {item &&
        item.purchasable_order_item &&
        item.purchasable_order_item &&
        parseInt(item.purchasable_order_item.sell_price)}
    </td>
    <td>
      {item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_oi &&
        item.purchasable_order_item.testitem_purchasable_oi.branch &&
        item.purchasable_order_item.testitem_purchasable_oi.branch.lab.name}


      {item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_order &&
        item.purchasable_order_item.testitem_purchasable_order[0] &&
        item.purchasable_order_item.testitem_purchasable_order[0].branch &&
        item.purchasable_order_item.testitem_purchasable_order[0].branch.lab &&
        item.purchasable_order_item.testitem_purchasable_order[0].branch.lab.name
        ? item &&
        item.purchasable_order_item &&
        item.purchasable_order_item.testitem_purchasable_order &&
        item.purchasable_order_item.testitem_purchasable_order[0] &&
        item.purchasable_order_item.testitem_purchasable_order[0].branch.lab &&
        item.purchasable_order_item.testitem_purchasable_order[0].branch.lab.name
        : ' '}{' '}


    </td>
    <td>
      <button
        className='btn btn-light-danger btn-sm'
        onClick={() => {
          if (window.confirm('Are you sure to delete this item?'))
            handleTestItemDelete(item);
        }}
      >
        Delete
      </button>
    </td>
  </tr>
);

const SingleEmailLog = ({ item }) => (
  <div className='single'>
    <button
      className={`btn btn-light-${item.mail_status === 'success' ? 'success' : 'danger'
        } log-btn`}
      disabled
    >
      {item.mail_status}
    </button>
    <span>{moment(item.created_at).format('DD MMM YYYY; hh:mm A')}</span>
  </div>
);

{
  /* <div className='pdf'>
  <div className='left'>
    <img src={PdfIcon} alt='' />
    <div className='name-date'>
      <div className='name'>CBC Report_Popular - Part 01</div>
      <div className='date'>Uploaded on: 01 Aug 2021; 12:25 PM</div>
    </div>
  </div>
  <div className='right'>
    <img src={CrossIcon} alt='' />
  </div>
</div>; */
}
