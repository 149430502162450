import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DateSelect from '../components/DateSelect';
import HeaderToolbar from '../components/HeaderToolbar';
import { AuthContext } from '../context/AuthContext';
import ScrollSpy from 'react-scrollspy-navigation';
import TimeSelect from '../components/TimeSelect';
import CrossIcon from '../assets/img/plus.svg';
import DownloadIcon from '../assets/img/download.svg';
import PdfIcon from '../assets/img/pdf.png';
import moment from 'moment';
import LoadingContent from '../components/LoadingContent';
import { dhakaArea, ctgArea, savarArea } from '../helpers/Areas';
import { toast } from 'react-toastify';
import PatientDob from '../components/PatientDob';
import { capitalize, parseInt, upperFirst } from 'lodash';
import BtnLoading from '../components/BtnLoading';
import OrderItemEditNew from '../components/OrderItemEditNew';
import SelectTests from '../components/TestCategory/SelectTests';
import SelectUser from '../components/SelectUser';
import OrderItemAddNew from '../components/OrderItemAddNew';
import { getUserToken } from '../firebase/Firebase';
import { sendMsgCustom } from '../firebase/SendMsgCustom';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

class OrderAddEdit extends Component {
  state = {
    order: null,
    loading: false,
    reports: null,
    orderAllPatients: [],
    AllPatientInfos: [],
    orderAllTests: [],
    fees: { collection_fee: 2500, meterial_fee: 50, delivery_fee: 100 },
    feesApi: { collection_fee: 200, meterial_fee: 50, delivery_fee: 100 },

    orderType: null,
    orderRef: null,
    headUpdateLoading: false,

    orderCro: null,
    orderMt: null,

    sampleDate: null,
    sampleTime: null,

    userPatients: null,
    userPatientsShown: null,
    userPatientName: '',

    locationLoopItem: dhakaArea,
    mobile: '',
    district: '',
    thana: '',
    address: `Flat no/Level: \nHouse: \nRoad: \nBlock: \nDetails: `,
    addEmail: '',
    addId: null,
    timeSelectPlaceholder: 'HH:MM',
    addressLoading: false,
    addressDeletingId: null,
    previousAddresses: [],
    selectedPreviousAddress: 0,
    new_mobile: '',
    new_district: '',
    new_thana: '',
    new_address: `Flat no/Level: \nHouse: \nRoad: \nBlock: \nDetails: `,
    new_addEmail: '',
    new_addId: null,
    new_addEditIdx: null,

    patientName: '',
    patientGender: '',
    patientDob: '',
    patientEmail: '',
    patientNumber: '',
    patientId: null,
    patientLoading: false,
    patientHeight: 0.0,
    patientWeight: 0.0,
    patientInfoId: null,

    orderManager: null,
    medicalTech: null,

    orderStatus: 'pending',
    serviceStatus: 'hard',
    paymentStatus: null,
    reportStatus: null,

    paymentEdit: false,
    paymentNote: null,
    paymentSource: null,
    paymentLoading: false,

    orderReportFile: null,
    orderReportName: '',
    orderReportTestName: [],
    orderReportPatientName: '',
    orderReportLoading: false,

    orderDiscountSource: '',
    orderDiscountPrice: null,
    orderDiscountPriceFromLab: null,
    orderDiscountCustom: null,
    orderDiscountColl: null,
    orderDiscountMate: null,
    orderDiscountNote: '',
    orderDiscountEdit: false,
    orderDiscountLoading: false,

    orderDiscountCollChangedOnce: false,

    reportOverviewEdit: false,
    reportStatusSoft: 'soft_copy_due',
    reportStatusHard: 'hard_copy_due',
    reportSoftNote: '',
    reportHardNote: '',

    orderNoteEdit: false,

    orderNoteId: null,
    orderNoteLoading: null,

    orderItemAddModal: false,

    reportSendEmails: '',
    reportSendEmailsLoading: false,

    orderDeliveryHead: '',
    orderDeliveryHeadNew: '',

    orderNote: '',
    orderNoteMarked: false,

    from_sneho: false,
    sneho_prescription_id: 0,

    labDiscountArray: [],

    isLabPartner: false,
    isqOrder: false,

    userNumber: null,
    userDetails: null,
    userPatients: null,
    haveOrder: false,
    testCarts: [],
    materialPerson: 0,

    username: '',
    fullname: '',
    sex: '',
    dob: '',
    email: '',
    password: 'amarlab12345',
    userCreateLoading: false,

    ref_cup_code: '',
    ref_channel: '',
    ref_reference: '',

    referenceGetedItem: [],
  };

  setTestCarts = (value) => {
    this.setState({ testCarts: [...value, ...this.state.testCarts] });

    setTimeout(() => {
      this.handleMeterialPerson(this.state.testCarts);
    }, 500);
  };

  setOrderReportTests = (value) => {
    this.setState({ orderReportTestName: value });
  };

  setPreviousAddresses = (addresses = []) => {
    this.setState({ previousAddresses: addresses })
  }

  setSelectedPreviousAddress = (index = 0, reEvaluateAddrs = true) => {
    this.setState({ selectedPreviousAddress: index });
    if (reEvaluateAddrs) this.setUserOldAddress(this.state.previousAddresses, index);
  }

  getFees = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/fees/`)
      .then((resp) => {
        this.setState({ feesApi: resp.data.results[0] });

          if (this.state.testCarts && this.state.testCarts.length && this.state.testCarts[0].test_item && this.state.testCarts[0].test_item.branch && this.state.testCarts[0].test_item.branch.lab && !this.state.testCarts[0].test_item.branch.lab.is_active ){
              this.setState({
                fees: {
                  collection_fee: 500,
                  meterial_fee: resp.data.results[0].meterial_fee,
                  delivery_fee: resp.data.results[0].delivery_fee,
                },
              });
          } else {
              if (this.state.serviceStatus === 'soft') {
                this.setState({ fees: resp.data.results[0] });
              } else {

                    console.log("ab aya maja ", this.state.testCarts);
                    this.setState({
                      fees: {
                        collection_fee:
                          resp.data.results[0].collection_fee +
                          resp.data.results[0].delivery_fee,
                        meterial_fee: resp.data.results[0].meterial_fee,
                        delivery_fee: resp.data.results[0].delivery_fee,


                        // collection_fee: 1000,
                        // meterial_fee: resp.data.results[0].meterial_fee,
                        // delivery_fee: resp.data.results[0].delivery_fee,


                      },
                    });
              }
          }

      });
  };


//collection_fee_extend


  filterOrderTests = (item) => {
    const l = [];
    const items = item.filter((i) => {
      if (
        !l.includes(
          i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.id
        )
      ) {
        l.push(
          i.purchasable_order_item.testitem_purchasable_oi.diagnostic_test.id
        );
        return true;
      }
      return false;
    });

    const tests = items
      .map(
        (item) =>
          item.purchasable_order_item.testitem_purchasable_oi.diagnostic_test
      )
      .map((item) => {
        return {
          ...item,
          value: item.name,
          label: item.name,
        };
      });
    // console.log(tests);
    this.setState({ orderAllTests: tests });
  };

  fetchRefData = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/reference-data-set/?limit=1000`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ referenceGetedItem: resp.data.results });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  fetchOrderPatients = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-patient/${id}`)
      .then((resp) => {
        const l = [];
        const ptns = resp.data.patient.filter((ptn) => {
          if (!l.includes(ptn.id)) {
            l.push(ptn.id);
            return true;
          }
          return false;
        });

        // setOrderAllPatients(ptns);
        this.setState({ orderAllPatients: ptns });
        // console.log(ptns);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  petientReports = (reports) => {
    const patientDetails = reports.map((item) => item.patient);
    const ids = patientDetails.map((o) => o.id);
    const filteredPatient = patientDetails.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    let patientOrders = [];
    filteredPatient.forEach((patient) => {
      const result = reports.filter((o1) => {
        return o1.patient.id === patient.id;
      });
      const newArray = {
        patient: patient,
        reports: result,
      };
      patientOrders.push(newArray);
    });
    // console.log(patientOrders);
    this.setState({ reports: patientOrders });
  };

  setPatientDob = (val) => {
    this.setState({ patientDob: val });
  };

  setUserDob = (val) => {
    this.setState({ dob: val });
  };

  setSampleDate = (value) => {
    this.setState({ sampleDate: value });
  };

  setSampleTime = (value) => {
    this.setState({ sampleTime: value });
  };

  setUserDetails = (value) => {
    this.setState({ userDetails: value });
  };

  setHaveOrder = (value) => {
    this.setState({ haveOrder: value });
  };

  setMobile = (v) => {
    this.setState({ mobile: v });
  };

  handlePrevAddrsClick = (idx = 0) => {
    // if (!!this.state.addId && this.state.selectedPreviousAddress === idx) {
    //   this.setState({ addId: null, })
    // }
    // else {
    //   this.setSelectedPreviousAddress(idx, true)
    // }
    this.setSelectedPreviousAddress(idx, true);
  }

  handlePrevAddrsEditClick = (index = 0) => {
    const data = [...this.state.previousAddresses];
    if (data.length !== 0) {
      this.setState({
        new_addId: data[index].id,
        new_mobile: data[index].mobile,
        new_district: data[index].district,
        new_thana: capitalize(data[index].thana),
        new_address: data[index].address,
        new_addEmail: data[index].email,
        new_addEditIdx: index,
      });

      if (data[index].district === 'Dhaka') {
        this.setState({ locationLoopItem: dhakaArea });
      } else if (data[index].district === 'Savar') {
        this.setState({ locationLoopItem: savarArea });
      } else if (data[index].district === 'Chattogram') {
        this.setState({ locationLoopItem: ctgArea });
      } else {
        this.setState({ locationLoopItem: dhakaArea });
      }
    }
  }


  setUserOldAddress = (data, index = 0) => {
    if (data.length !== 0) {
      // console.log(data);
      this.setState({
        addId: data[index].id,
        mobile: data[index].mobile,
        district: data[index].district,
        thana: capitalize(data[index].thana),
        address: data[index].address,
        addEmail: data[index].email,
      });

      if (data[index].district === 'Dhaka') {
        this.setState({ locationLoopItem: dhakaArea });
      } else if (data[index].district === 'Savar') {
        this.setState({ locationLoopItem: savarArea });
      } else if (data[index].district === 'Chattogram') {
        this.setState({ locationLoopItem: ctgArea });
      } else {
        this.setState({ locationLoopItem: dhakaArea });
      }
    }
  };

  fetchOrderManager = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name__in=Order%20Manager,Supervisor&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log('oms: ', resp.data.results);
        this.setState({ orderManager: resp.data.results });
      });
  };

  fetchMedicalTechnologist = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?groups__name=Medical%20Technologist&page=1&limit=1000000&ofset=0`
      )
      .then((resp) => {
        // console.log(resp.data.results);
        this.setState({ medicalTech: resp.data.results });
      });
  };

  fetchSingleOrder = (id, loading) => {
    if (loading) {
      this.setState({ loading: true });
    }

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/order-tree/${id}`)
      .then((resp) => {
        this.fetchUserPatients(resp.data.user.id);

        if (loading) {
          this.setState({ loading: false });
        }
        this.setState({ order: resp.data });
        console.log(resp.data);

        if (resp.data.orderdetail) {
          this.setState({
            orderCro: resp.data.orderdetail.cs_agent
              ? resp.data.orderdetail.cs_agent.id
              : '',
            orderMt: resp.data.orderdetail.mt
              ? resp.data.orderdetail.mt.id
              : '',
            orderType: resp.data.order_type,
            orderRef: resp.data.orderdetail.references,
          });
        }

        if (resp.data.orderdetail) {
          this.setState({
            orderStatus: resp.data.orderdetail.order_status,
            serviceStatus: resp.data.orderdetail.delivery_copy_status,
            paymentStatus: resp.data.orderdetail.payment_status,
            // reportStatus: null,
            // paymentNote: resp.data.orderdetail.payment_note,
            paymentSource: resp.data.orderdetail.payment_source,
          });
        }

        this.filterOrderTests(resp.data.orderitem);

        if (resp.data.orderdelivery && resp.data.orderdelivery.length !== 0) {
          const lastDelivery = resp.data.orderdelivery.at(-1).name;
          this.setState({
            orderDeliveryHead: lastDelivery,
            orderDeliveryHeadNew: lastDelivery,
          });
          // console.log(lastDelivery);
        }

        if (resp.data.orderdetail) {
          if (resp.data.orderdetail.address) {
            this.setState({
              reportSendEmails: resp.data.orderdetail.address.email,
            });
          }
        }

        if (resp.data.orderreport.length !== 0) {
          this.petientReports(resp.data.orderreport);
        }

        const getUrlId = window.location.hash;

        if (getUrlId) {
          if (getUrlId === '#order-note-nav') {
            // console.log(getUrlId);
            window.scrollTo(0, 360);
            this.props.history.push(`/order-edit/${resp.data.id}`);
          } else if (getUrlId === '#order-summery-nav') {
            window.scrollTo(0, 1600);
            this.props.history.push(`/order-edit/${resp.data.id}`);
          }
        }

        // const newFees = {
        //   collection_fee: resp.data.collection_fee,
        //   meterial_fee: resp.data.orderitem[0].meterial_fee,
        // };
        // this.setState({ fees: newFees });
        // this.setState({ editOrderItem: resp.data.orderitem });

        // this.setUserDetails(resp.data.user);
        // this.fetchusePat(resp.data.user.id);
        // this.setState({
        //   fullname: resp.data.user.first_name + resp.data.user.last_name,
        // });
        // // this.setState({ sex: resp.data.orderdetail.references });
        // // this.setState({ dob: resp.data.user.references });
        // this.setState({ email: resp.data.user.email });
        // this.setState({ username: resp.data.user.username });

        // this.setState({ references: resp.data.orderdetail.references });
        // this.setState({ orderType: resp.data.orderdetail.order_type });
        // this.setState({ paymentStatus: resp.data.orderdetail.payment_status });
        // this.setState({ paymentNote: resp.data.orderdetail.payment_note });
        // this.setState({ orderNote: resp.data.orderdetail.order_note });

        // this.setState({ mobile: resp.data.orderdetail.address.mobile });
        // this.setState({ district: resp.data.orderdetail.address.district });
        // this.setState({ thana: resp.data.orderdetail.address.thana });
        // this.setState({ address: resp.data.orderdetail.address.address });

        // if (resp.data.orderdetail.address.district === 'Chattogram') {
        //   this.setState({ locationLoopItem: ctgArea });
        // }

        // this.setState({ sampleDate: new Date(resp.data.date) });
        // this.setState({ sampleTime: new Date(resp.data.time) });
      })
      .catch((error) => {
        console.log(error.response);
        if (loading) {
          this.setState({ loading: false });
        }
      });
  };

  fetchUserPatients = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${id}&limit=300`
      )
      .then((resp) => {

        const results = resp.data.results
          .filter((patient) => patient.is_active)
          .map((patient) => {
            return {
              ...patient,
            };
          });
        // console.log(results);
        this.setUserPatients(results);
        // this.setState({ userPatients: results });
        // setUserPatients(results);

        // const userPatient = results.filter((patient) => patient.is_account);
        // console.log(userPatient);
        // if (userPatient.length !== 0) {
        //   axios
        //     .get(
        //       `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}`
        //     )
        //     .then((resp) => {
        //       // console.log(resp.data.results);
        //       setUserOldAddress(resp.data.results, 0);
        //     })
        //     .catch((error) => console.log(error.response));
        // }
      });
  };

  getLabDiscount = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/diagnostic/lab-discount-get/`)
      .then((resp) => {
        // this.calculateLabDiscount(resp.data.results);
        if (resp.data.results.length !== 0) {
          const data = resp.data.results
            .filter((coupon) => coupon.is_active)
            .filter((coupon) => {
              const expire_day = moment(coupon.expired_at)
                .add(1, 'days')
                .format('YYYY-MM-DD')
                .toString();
              const isSameOrAfter = moment(expire_day).isSameOrAfter(
                this.state.today
              );

              if (isSameOrAfter) {
                return coupon;
              }
            });

          if (data.length !== 0) {
            // console.log(data);
            this.setState({ labDiscountArray: data });
            this.calculateLabDiscount(data);
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  pageIniatialLoad = () => {
    this.getFees();
    this.context.setPageTitle('Userslllllll');
    this.context.setBreadcrum([
      { name: 'Home', link: '/' },
      { name: 'Orders', link: '/orders' },
      { name: 'Add Order', link: '/' },
    ]);
  };

  qOrderUserChange = () => {
    const qOrrderParams = this.props.location.params;

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/user/?search=${qOrrderParams.qOrderPhone}`
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.results.length !== 0) {
          const user = resp.data.results[0];
          this.setUserDetails(user);
          this.setMobile(qOrrderParams.qOrderPhone);

          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${user.id}&limit=300`
            )
            .then((resp) => {

              const results = resp.data.results
                .filter((patient) => patient.is_active)
                .map((patient) => {
                  return {
                    ...patient,
                    value: patient.full_name,
                    label: patient.full_name,
                  };
                });
              // console.log(results);
              this.setUserPatients(results);

              const userPatient = results.filter(
                (patient) => patient.is_account
              );
              // console.log(userPatient);
              if (userPatient.length !== 0) {
                axios
                  .get(
                    `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`
                  )
                  .then((resp) => {
                    // console.log(resp.data.results);
                    this.setUserOldAddress(resp.data.results, 0);
                  })
                  .catch((error) => console.log(error.response));
              }
            });
        } else {
          this.setState({ username: qOrrderParams.qOrderPhone });
        }
      });
  };

  fetchPartnerData = (data) => {
    // console.log(data);
    this.setUserDetails(data);
    this.setMobile(data.username);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${data.id}&limit=300`
      )
      .then((resp) => {

        const results = resp.data.results
          .filter((patient) => patient.is_active)
          .map((patient) => {
            return {
              ...patient,
              value: patient.full_name,
              label: patient.full_name,
            };
          });
        // console.log(results);
        this.setUserPatients(results);

        const userPatient = results.filter((patient) => patient.is_account);
        // console.log(userPatient);
        if (userPatient.length !== 0) {
          axios
            .get(
              `${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`
            )
            .then((resp) => {
              // console.log(resp.data.results);
              this.setUserOldAddress(resp.data.results, 0);
            })
            .catch((error) => console.log(error.response));
        }
      });
  };

  componentDidMount() {
    // console.log('abacd');
    // console.log(this.context.firebase);
    // console.log(this.getUserToken);

    const token = localStorage.getItem('token');
    const user_details = localStorage.getItem('user_details');
    const userPatients = localStorage.getItem('userPatients');
    const userRole = JSON.parse(user_details);

    this.fetchRefData();

    const qOrrderParams = this.props.location.params;
    if (qOrrderParams && qOrrderParams.isqOrder)
      this.setState({ isqOrder: true });

    if (!token) {
      this.props.history.push('/login');
      return;
    } else {
      this.context.interceptor(JSON.parse(token));
      this.getLabDiscount();
      this.fetchMedicalTechnologist();
    }

    if (userRole.is_superuser) {
      this.pageIniatialLoad();
      if (qOrrderParams && qOrrderParams.isqOrder) this.qOrderUserChange();
    } else {
      if (userRole.groups.length === 0) {
        this.props.history.push('/orders');
      } else if (
        userRole.groups[0].name === 'User' ||
        userRole.groups[0].name === 'Medical Technologist' ||
        userRole.groups[0].name === 'External'
      ) {
        this.props.history.push('/orders');
      } else if (userRole.groups[0].name === 'Lab Partner') {
        this.setState({ isLabPartner: true });
        this.pageIniatialLoad();
        this.fetchPartnerData(userRole);
        if (userPatients) {
          const uPatient = JSON.parse(userPatients);
          // console.log(uPatient);
          if (uPatient.lab_permit) {
            let arr = uPatient.lab_permit.split(', ');
            // console.log(arr);
            this.setState({ selectedLabName: arr });
          }
        }
      } else {
        if (qOrrderParams && qOrrderParams.isqOrder) this.qOrderUserChange();
        this.pageIniatialLoad();
      }
    }

    // const token = localStorage.getItem('token');
    // if (!token) {
    //   this.props.history.push('/login');
    // } else {
    //   axios.interceptors.request.use((config) => {
    //     const tokehjjhhn = 'Token ' + JSON.parse(token);
    //     config.headers.Authorization = tokehjjhhn;
    //     return config;
    //   });

    //   // console.log(this.props.match.params.id);
    //   if (this.props.match.params.id) {
    //     this.fetchSingleOrder(parseInt(this.props.match.params.id), true);
    //     this.fetchOrderPatients(parseInt(this.props.match.params.id));
    //   }

    //   this.fetchOrderManager();
    //   this.fetchMedicalTechnologist();
    //   this.getFees();
    // }
  }

  handleAddressI = () => {
    // console.log(this.state.order.orderdetail.address);
    this.setState({
      addId: this.state.order.orderdetail.address.id,
      mobile: this.state.order.orderdetail.address.mobile,
      district: this.state.order.orderdetail.address.district,
      thana: this.state.order.orderdetail.address.thana,
      address: this.state.order.orderdetail.address.address,
      addEmail: this.state.order.orderdetail.address.email,
      sampleDate: new Date(this.state.order.date),
    });

    if (this.state.order.orderdetail.address.district === 'Chattogram') {
      this.setState({ locationLoopItem: ctgArea });
    } else {
      this.setState({ locationLoopItem: dhakaArea });
    }

    setTimeout(() => {
      if (this.state.order.time) {
        if (this.state.addId) {
          this.setState({
            timeSelectPlaceholder: `${moment(this.state.order.time, [
              'HH:mm:ss',
            ]).format('hh:mm A')} - ${moment(this.state.order.time, 'HH:mm:ss')
              .add(120, 'minutes')
              .format('hh:mm A')}`,
          });
        } else {
          this.setState({ timeSelectPlaceholder: 'HH:MM' });
        }
      } else {
        this.setState({ timeSelectPlaceholder: 'HH:MM' });
      }
    }, 100);
  };

  clearAddressForm = () => {
    this.setState({
      locationLoopItem: dhakaArea,
      new_mobile: '',
      new_district: '',
      new_thana: '',
      new_address: 'Flat no/Level: \nHouse: \nRoad: \nBlock: \nDetails: ',
      new_addEmail: '',
      new_addId: null,
      new_addEditIdx: null,
    });

  };

  putOrderDetails = (data, id) => {
    this.setState({ headUpdateLoading: true, paymentLoading: true });

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        this.setState({ paymentEdit: false, paymentNote: null });
        toast.success(`Order updated successfully.`);

        this.setState({ reportOverviewEdit: false });
        this.setState({ headUpdateLoading: false, paymentLoading: false });

        // if (resp.data.payment_status === 'paid') {
        //   axios
        //     .put(
        //       `${process.env.REACT_APP_BASE_URL}/order/order-detail/${id}/`,
        //       { order_status: 'completed' }
        //     )
        //     .then((resp) => {
        //       // console.log(resp.data);
        //       this.fetchSingleOrder(parseInt(this.props.match.params.id));
        //     })
        //     .catch((error) => {
        //       // console.log(error.response);
        //     });
        // }
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ headUpdateLoading: false, paymentLoading: false });
      });
  };

  putOrder = (id, data) => {
    // this.setState({ headUpdateLoading: true });

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-only/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));

        this.setState({
          orderDiscountSource: '',
          orderDiscountPrice: null,
          orderDiscountColl: null,
          orderDiscountMate: null,
          orderDiscountNote: '',
          orderDiscountEdit: false,
          orderDiscountCollChangedOnce: false,
        });
        // this.setState({ headUpdateLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        // this.setState({ headUpdateLoading: false });
      });
  };


  getUserPreviousAddress = (selectedAddressIdx = 0, callback = null) => {
    const userPatient = this.state.userPatients.filter((patient) => patient.is_account);

    if (userPatient.length !== 0) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/?patient=${userPatient[0].id}&status=true&ordering=-id`)
        .then((resp) => {
          if (resp.data.results.length !== 0) {
            const tempAddrs = resp.data.results.filter(adrs => adrs.status)
            this.setUserOldAddress(tempAddrs, selectedAddressIdx);
            this.setPreviousAddresses(tempAddrs);
            this.setSelectedPreviousAddress(selectedAddressIdx, false);
            if (callback) callback();
          } else {
            axios
              .get(`${process.env.REACT_APP_BASE_URL}/user_management/address/?user=${this.state.userDetails.id}&status=true&ordering=-id`)
              .then((resp) => {
                if (callback) callback();
                if (resp.data.results.length !== 0) {
                  const tempAddrsByUser = resp.data.results.filter(adrs => adrs.status)
                  this.setUserOldAddress(tempAddrsByUser, selectedAddressIdx);
                  this.setPreviousAddresses(tempAddrsByUser);
                  this.setSelectedPreviousAddress(selectedAddressIdx, false);
                }
              })
              .catch((err) => { if (callback) callback(); console.log(err.response); });
          }
        })
        .catch((error) => { if (callback) callback(); console.log(error.response); });
    }
  }



  deleteAddress = (id) => {
    this.setState({ addressDeletingId: id });

    const putData = {
      status: false,
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/address/${id}/`, putData
      )
      .then((resp) => {
        this.getUserPreviousAddress(0, () => {
          this.setState({ addressDeletingId: null });
          this.clearAddressForm();
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addressDeletingId: null });
      });
  };


  putAddress = (data, id) => {
    this.setState({ addressLoading: true });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/address/${id}/`,
        data
      )
      .then((resp) => {
        this.getUserPreviousAddress(this.state.new_addEditIdx || this.state.selectedPreviousAddress, () => {
          this.setState({ addressLoading: false });
          this.clearAddressForm();
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ addressLoading: false });
      });
  };


  postAddress = (data) => {
    if (this.state.previousAddresses && this.state.previousAddresses.length < 4) {
      this.setState({ addressLoading: true });

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user_management/address/`, data
        )
        .then((resp) => {
          this.getUserPreviousAddress(0, () => {
            this.setState({ addressLoading: false });
            this.clearAddressForm();
          });
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({ addressLoading: false });
        });
    } else {
      toast.error('Sorry, user already have 4 address saved. Please, edit and select them to place an order.')
    }
  };


  handleAddressUpdate = (method = 'post') => {
    if (this.state.district === '') {
      toast.error(`Please select area.`);
    } else if (this.state.thana === '') {
      toast.error(`Please select thana.`);
    } else if (this.state.address === '') {
      toast.error(`Please enter location details.`);
    } else if (this.state.sampleDate === '' || this.state.sampleDate === null) {
      toast.error(`Please select sample collection date.`);
    } else {
      const putData = {
        district: this.state.district,
        thana: this.state.thana,
        address: this.state.address,
        mobile: this.state.mobile,
        email: this.state.addEmail,
      };

      if (this.state.addId && method === 'put') {
        this.putAddress(putData, this.state.addId);
      }
      else this.postAddress(putData)
    }
  };


  handleAddressUpdateNew = (method = 'post') => {
    if (!this.state.userDetails || !this.state.userDetails.id) {
      toast.error('Please select/create a user first.');
      window.scroll({ top: 700, left: 0, behavior: 'smooth' });
      return;
    }

    if (method === 'post' && (this.state.previousAddresses && this.state.previousAddresses.length > 3)) {
      toast.error('Sorry, user already have 4 address saved. Please, edit and select them to place an order.');
      return;
    }

    if (this.state.new_district.trim() === '') {
      toast.error(`Please select area.`);
    } else if (this.state.new_thana.trim() === '') {
      toast.error(`Please select thana.`);
    } else if (this.state.new_address === '') {
      toast.error(`Please enter location details.`);
    } else {

      const patientId = this.state.userPatients.filter(
        (patient) => patient.is_account
      );

      const putData = {
        district: this.state.new_district.trim(),
        thana: upperFirst(capitalize(this.state.new_thana)),
        address: this.state.new_address,
        mobile: this.state.new_mobile,
        email: this.state.new_addEmail.trim(),
        user: this.state.userDetails.id,
      };
      if (patientId.length !== 0 && patientId[0].id) {
        putData.patient = patientId[0].id;
      }

      const postAddress = {
        district: this.state.new_district.trim(),
        thana: upperFirst(capitalize(this.state.new_thana)),
        address: this.state.new_address,
        mobile: this.state.new_mobile,
        email: this.state.new_addEmail.trim(),
        patient: patientId.length !== 0 ? patientId[0].id : null,
        user: this.state.userDetails.id,
      };

      if (this.state.new_addId && method === 'put') {
        this.putAddress(putData, this.state.new_addId);
      } else this.postAddress(postAddress)
    }
  };


  handleDistrict = (e) => {
    // this.setState({ district: e.target.value });
    this.setState({ new_district: e.target.value });

    if (e.target.value === 'Dhaka') {
      this.setState({ locationLoopItem: dhakaArea });
    } else if (e.target.value === 'Savar') {
      this.setState({ locationLoopItem: savarArea });
    } else if (e.target.value === 'Chattogram') {
      this.setState({ locationLoopItem: ctgArea });
    } else {
      this.setState({ locationLoopItem: dhakaArea });
    }
  };


  putPatient = (id, data, data2) => {
    this.setState({ patientLoading: true });

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/user_management/patient/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        this.fetchUserPatients(this.state.userDetails.id);
        if (resp.data.is_active) {
          toast.success(`Patient updated successfully.`, {
            autoClose: 3000,
          });

          //patient info
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient-info/`,
              data2
            )
            .then((resp) => {
              // console.log(resp.data);
              this.fetchUserPatients(this.state.userDetails.id);
              //if (resp.data.is_active) {
                toast.success(`Patient weight added successfully.`, {
                  autoClose: 3000,
                });
              //}

              this.setState(prevState => ({
                AllPatientInfos: [...prevState.AllPatientInfos, resp.data.id]
              }));



              this.handlePatientFormClr();
              this.setState({ patientLoading: false });
              // setPtnLoading(false);
              // handlePatientEditClear();
            })
            .catch((error) => {
              // setPtnLoading(false);
              this.setState({ patientLoading: false });
              console.log(error.response);
            });
        //finish patient info


        } else {
          toast.success(`Patient deleted successfully.`, {
            autoClose: 3000,
          });
        }
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });
        // setPtnLoading(false);
        // handlePatientEditClear();
      })
      .catch((error) => {
        // setPtnLoading(false);
        this.setState({ patientLoading: false });
        console.log(error.response);
        this.handlePatientFormClr();
        // if (update) {
        //   toast.error(`Patient update failed.`, {
        //     autoClose: 3000,
        //   });
        // } else {
        //   toast.error(`Patient delete failed.`, {
        //     autoClose: 3000,
        //   });
        // }
      });
  };

  postPatient = (data, data2) => {
    this.setState({ patientLoading: true });

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user_management/patient/`, data)
      .then((resp) => {
        // console.log(resp.data);
        toast.success(`Patient added successfully.`, {
          autoClose: 3000,
        });
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });
        const newPat = [resp.data].map((p) => {
          return {
            ...p,
            value: p.full_name,
            label: p.full_name,
          };
        });
        this.setUserPatients([...newPat, ...this.state.userPatients]);
        // setPtnLoading(false);
        // handlePatientEditClear();



          data2.patient=resp.data.id;
          //patient info
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user_management/patient-info/`,
              data2
            )
            .then((resp) => {
              // console.log(resp.data);
              this.fetchUserPatients(this.state.userDetails.id);
              //if (resp.data.is_active) {
                toast.success(`Patient weight added successfully.`, {
                  autoClose: 3000,
                });
              //}

              this.setState(prevState => ({
                AllPatientInfos: [...prevState.AllPatientInfos, resp.data.id]
              }));



              this.handlePatientFormClr();
              this.setState({ patientLoading: false });
              // setPtnLoading(false);
              // handlePatientEditClear();
            })
            .catch((error) => {
              // setPtnLoading(false);
              this.setState({ patientLoading: false });
              console.log(error.response);
            });
        //finish patient info






      })
      .catch((error) => {
        // setPtnLoading(false);
        console.log(error.response);
        this.handlePatientFormClr();
        this.setState({ patientLoading: false });
        // if (update) {
        //   toast.error(`Patient update failed.`, {
        //     autoClose: 3000,
        //   });
        // } else {
        //   toast.error(`Patient delete failed.`, {
        //     autoClose: 3000,
        //   });
        // }
      });
  };

  handlePatientDelete = (patient) => {
    // console.log(patient);

    const putDate = {
      is_active: false,
    };

    this.putPatient(patient.id, putDate);
  };

  handlePatientEdit = (patient) => {
    // console.log(patient);
    this.setState({
      patientId: patient.id,
      patientName: patient.full_name,
      patientGender: patient.sex,
      patientDob: new Date(patient.dob),
      // patientEmail: patient.email,
      // patientNumber: patient.mobile,
      patientHeight: patient.patient_info.height,
      patientWeight: patient.patient_info.weight,
      patientInfoId: patient.patient_info.id,
    });
  };

  handlePatientUpdate = () => {
    if (this.state.patientName.trim() === '') {
      toast.error(`Please enter patient name.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientName.length < 4) {
      toast.error(`Patient name must be at least 4 character long.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientDob === '' || this.state.patientDob === null) {
      toast.error(`Please enter patient date of birth.`, {
        autoClose: 3000,
      });
    } else if (this.state.patientGender === '') {
      toast.error(`Please enter patient gender.`, {
        autoClose: 3000,
      });
    } else {
      const patientData = {
        full_name: this.state.patientName,
        dob: moment(this.state.patientDob).format('YYYY-MM-DD'),
        sex: this.state.patientGender,
        // mobile: this.state.patientNumber,
        // email: this.state.patientEmail,
        user: this.state.userDetails.id,
      };

      const patientInfoData = {
        height: this.state.patientHeight,
        weight: this.state.patientWeight,
        //patient: this.state.patientGender,
        //order: this.state.userDetails.id,
      };


      const check = this.state.userPatients.find(
        (item) =>
          item.full_name.toLowerCase() === this.state.patientName.toLowerCase()
      );

      if (this.state.patientId) {
        patientInfoData.patient = this.state.patientId;
        this.putPatient(this.state.patientId, patientData, patientInfoData);
        // console.log('put patient');
      } else {
        if (check) {
          toast.error(
            `A patient already exists with this name in your family, please try another name.`
          );
        } else this.postPatient(patientData, patientInfoData);
        // console.log('post patient');
      }

      // console.log(patientData, this.state.patientId);
    }
  };

  handlePatientFormClr = () => {
    this.setState({
      patientName: '',
      patientGender: '',
      patientDob: '',
      patientEmail: '',
      patientNumber: '',
      patientId: null,
      patientHeight: 0.0,
      patientWeight: 0.0,
    });
  };

  handlePaymentEdit = () => {
    // console.log(this.state.order);
    this.setState({
      paymentEdit: true,
      paymentNote: this.state.order.orderdetail.payment_note,
    });
  };

  handlePaymentInfo = () => {
    if (this.state.paymentStatus === '' || this.state.paymentStatus === null) {
      toast.error(`Please select payment status.`);
    } else if (
      this.state.paymentSource === '' ||
      this.state.paymentSource === null
    ) {
      toast.error(`Please select payment mode.`);
    } else {
      const putData = {
        payment_status: this.state.paymentStatus,
        payment_source: this.state.paymentSource,
        payment_note: this.state.paymentNote,
      };

      this.putOrderDetails(putData, this.state.order.orderdetail.id);
    }
  };

  handleFileDelete = (report) => {
    // console.log(report);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/order/order-report/${report.id}/`
      )
      .then((resp) => {
        // console.log('deleted', resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        toast.success(`Order report deleted sucessfully.`);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleOrderReportPatient = (e) => {
    this.setState({
      orderReportPatientName: e.target.value,
    });

    if (e.target.value !== '') {
      const patientName = this.state.orderAllPatients.filter(
        (patient) => patient.id === parseInt(e.target.value)
      );
      const firstName = patientName[0].full_name.split(' ').shift();
      const generateReportName = (order, patient) => {
        const orderId = order.order_id;
        const serial = order.orderemail
          ? order.orderemail.length + 1
          : Date.now();
        return orderId + patient.toUpperCase() + serial;
      };
      this.setState({
        orderReportName: generateReportName(this.state.order, firstName),
      });
    } else {
      this.setState({ orderReportName: '' });
    }
  };

  handleFileUpload = () => {
    if (
      this.state.orderReportPatientName === null ||
      this.state.orderReportPatientName === ''
    ) {
      toast.error(`Please select a patient.`);
    } else if (this.state.orderReportTestName.length === 0) {
      toast.error(`Please select a test.`);
    } else if (!this.state.orderReportFile) {
      toast.error(`Please select a file.`, {
        autoClose: 3000,
      });
    } else {
      this.setState({ orderReportLoading: true });

      const tagTests = this.state.orderReportTestName.map((test) => test.name);

      const postOrderReport = {
        order: this.state.order.id,
        upload_by: this.context.user_details.id,
        name: this.state.orderReportName,
        patient: this.state.orderReportPatientName,
        tests: tagTests.toString(),
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/order/order-report/`,
          postOrderReport
        )
        .then((resp) => {
          // console.log(resp.data);

          const data = new FormData();
          data.append('report', this.state.orderReportFile);

          axios
            .put(
              `${process.env.REACT_APP_BASE_URL}/order/order-report/${resp.data.id}/`,
              data
            )
            .then((resp) => {
              // console.log(resp.data);
              this.fetchSingleOrder(parseInt(this.props.match.params.id));
              this.setState({
                orderReportFile: null,
                orderReportName: '',
                orderReportTestName: [],
                orderReportPatientName: '',
                orderReportLoading: false,
              });
              toast.success(`Report uploaded successfully.`);
            })
            .catch((error) => {
              console.log(error.response);
              this.setState({ orderReportLoading: false });
            });

          // console.log(data);
        })
        .catch((err) => {
          console.log(err.response);
          this.setState({ orderReportLoading: false });
        });
    }
  };

  getTotalPriceWithFeesDiscount = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.test_item.test_item.purchasable_order_item.sell_price),
      0
    );

    const total =
      parseInt(diagprice) +
      parseInt(packprice) +
      parseInt(this.state.fees.collection_fee) +
      this.state.materialPerson * parseInt(this.state.fees.meterial_fee);

    if (this.state.orderDiscountPrice) {
      return parseInt(total) - parseInt(this.state.orderDiscountPrice);
    } else {
      return parseInt(total);
    }
  };

  handleOrderDiscountSource = (e) => {
    this.setState({
      orderDiscountSource: e.target.value,
      orderDiscountMate:
        this.state.fees.meterial_fee * this.state.materialPerson,
      orderDiscountColl: this.state.fees.collection_fee,
    });

    setTimeout(() => {
      if (e.target.value === 'admin') {
        this.setState({ orderDiscountPrice: this.state.orderDiscountCustom, orderDiscountCollChangedOnce: false, });
      } else if (e.target.value === 'collection_fee') {
        this.setState({
          orderDiscountPrice: this.state.orderDiscountColl,
          orderDiscountMate: 0,
        });
      } else if (e.target.value === 'material_fee') {
        this.setState({
          orderDiscountPrice:
            this.state.fees.meterial_fee * this.state.materialPerson,
          orderDiscountColl: 0,
          orderDiscountCollChangedOnce: false,
        });
      } else if (e.target.value === 'fee') {
        this.setState({
          orderDiscountPrice:
            this.state.fees.collection_fee +
            this.state.fees.meterial_fee * this.state.materialPerson,

          orderDiscountMate:
            this.state.fees.meterial_fee * this.state.materialPerson,
        });
      } else if (e.target.value === 'fee_discount') {
        this.setState({
          orderDiscountPrice: this.state.orderDiscountCustom
            ? this.state.fees.collection_fee +
            this.state.fees.meterial_fee * this.state.materialPerson +
            this.state.orderDiscountCustom
            : this.state.fees.collection_fee +
            this.state.fees.meterial_fee * this.state.materialPerson,

          orderDiscountMate:
            this.state.fees.meterial_fee * this.state.materialPerson,
        });
      } else {
        this.setState({ orderDiscountPrice: null, orderDiscountCollChangedOnce: false, });
        this.calculateCartAndLab();
      }
    }, 200);


    return;

    let meteFee = 0;
    let collFee =
      this.state.order && this.state.order.collection_fee
        ? this.state.order.collection_fee
        : '0';
    let total = 0;

    const patts = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    meteFee =
      this.state.order && this.state.order.orderitem.length !== 0
        ? this.state.order.orderitem[0].meterial_fee * patts.length
        : patts.length * 50;

    if (meteFee === '0' || collFee === '0') {
      total = '0';
    } else {
      total = parseInt(collFee) + parseInt(meteFee);
    }

    // console.log(meteFee, collFee);
    // console.log(total);

    if (e.target.value === 'admin') {
      this.setState({
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
        orderDiscountColl: 0,
        orderDiscountMate: 0,
      });
    } else if (e.target.value === 'collection_fee') {
      this.setState({
        orderDiscountColl: collFee,
        orderDiscountPrice: 0,
        orderDiscountMate: 0,
      });
    } else if (e.target.value === 'material_fee') {
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountPrice: 0,
        orderDiscountColl: 0,
      });
    } else if (e.target.value === 'fee') {
      // setOrderDiscountPrice(total);
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountColl: collFee,
        orderDiscountPrice: 0,
      });
    } else if (e.target.value === 'fee_discount') {
      this.setState({
        orderDiscountMate: meteFee <= 0 ? '0' : meteFee,
        orderDiscountColl: collFee,
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
      });
    } else {
      this.setState({
        orderDiscountPrice:
          this.state.order.orderdiscount &&
            this.state.order.orderdiscount.discount_price
            ? this.state.order.orderdiscount.discount_price
            : '',
      });
    }
  };

  setDiscountPriceCustom = () => {
    this.setState({
      orderDiscountPrice:
        parseInt(this.state.orderDiscountColl || 0) +
        parseInt(this.state.orderDiscountMate || 0) +
        parseInt(this.state.orderDiscountCustom || 0),
    });
  };

  setDiscountPrice = () => {
    let cusDis = 0;
    if (this.state.orderDiscountCustom) {
      cusDis = this.state.orderDiscountCustom || 0;
    }
    this.setState({
      orderDiscountPrice:
        parseInt(this.state.orderDiscountColl || 0) +
        parseInt(this.state.orderDiscountMate || 0) +
        parseInt(cusDis || 0),
    });
  };

  handleOrderDiscountEdit = () => {
    this.setState({
      orderDiscountEdit: true,
      orderDiscountSource:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_source,
      orderDiscountPrice:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_price,
      orderDiscountColl:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.collection_discount_price,
      orderDiscountMate:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.material_discount_price,
      orderDiscountNote:
        this.state.order.orderdiscount &&
        this.state.order.orderdiscount.discount_note,
    });
  };

  orderItemMaterial = (source) => {
    const patts = this.state.order.orderitem
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);

    if (
      source === 'fee' ||
      source === 'fee_discount' ||
      source === 'material_fee'
    ) {
      const main =
        this.state.fees.meterial_fee * patts.length -
        this.state.orderDiscountMate;
      return Math.round(main / patts.length);
    } else {
      return this.state.fees.meterial_fee;
    }
  };

  updateOrderItem = (id) => {
    // console.log(id);
    const putData = {
      meterial_fee: this.orderItemMaterial(this.state.orderDiscountSource),
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/${id}/`,
        putData
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  orderCollection = (source) => {
    if (source === 'collection_fee') {
      return 0;
    } else if (source === 'fee' || source === 'fee_discount') {
      // return Math.round(this.state.discountCollection);
      const mainColl =
        this.state.fees.collection_fee - parseInt(this.state.orderDiscountColl);
      return mainColl;
    } else {
      return this.state.fees.collection_fee;
    }
  };

  orderMaterial = (source) => {
    if (source === 'material_fee') {
      return 0;
    } else if (source === 'fee' || source === 'fee_discount') {
      // return Math.round(this.state.discountMaterial);
      return (
        this.state.fees.meterial_fee * this.state.materialPerson -
        this.state.orderDiscountMate
      );
    } else {
      return this.state.fees.meterial_fee * this.state.materialPerson;
    }
  };

  ajaxOrderDiscountPost = (data) => {
    // console.log(data);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-discount/`, data)
      .then((resp) => {
        const putData = {
          collection_fee: this.orderCollection(this.state.orderDiscountSource),
          material_fee: this.orderMaterial(this.state.orderDiscountSource),
        };

        const order_id = resp.data.order;

        this.putOrder(order_id, putData);

        this.setState({ orderDiscountLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  ajaxOrderDiscountPut = (data, id) => {
    // console.log(data);
    // console.log(id);

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/order-discount/${id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        const putData = {
          collection_fee: this.orderCollection(this.state.orderDiscountSource),
          material_fee: this.orderMaterial(this.state.orderDiscountSource),
        };

        const order_id = resp.data.order;
        this.putOrder(order_id, putData);
        this.setState({ orderDiscountLoading: false });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  handleOrderDiscount = () => {
    if (
      this.state.orderDiscountSource === '' ||
      this.state.orderDiscountSource === null
    ) {
      toast.error(`Please enter discount source.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.orderDiscountPrice === '' ||
      this.state.orderDiscountPrice === null
    ) {
      toast.error(`Please enter discount price.`, {
        autoClose: 3000,
      });
    } else {
      const postOrderDiscount = {
        order: this.state.order.id,
        discount_note: this.state.orderDiscountNote,
        discount_price: parseInt(this.state.orderDiscountPrice),
        discount_by: this.context.user_details.id,
        discount_source: this.state.orderDiscountSource,
        material_discount_price: this.state.orderDiscountMate,
        collection_discount_price: this.state.orderDiscountColl,
      };
      const putOrderDiscount = {
        discount_note: this.state.orderDiscountNote,
        discount_price: parseInt(this.state.orderDiscountPrice),
        discount_by: this.context.user_details.id,
        discount_source: this.state.orderDiscountSource,
        material_discount_price: this.state.orderDiscountMate,
        collection_discount_price: this.state.orderDiscountColl,
      };

      this.setState({ orderDiscountLoading: true });

      this.state.order.orderitem.forEach((item) => {
        this.updateOrderItem(item.id);
      });

      if (this.state.order.orderdiscount === null) {
        this.ajaxOrderDiscountPost(postOrderDiscount);
        // console.log(postOrderDiscount);
      } else {
        this.ajaxOrderDiscountPut(
          putOrderDiscount,
          this.state.order.orderdiscount.id
        );
        // console.log(putOrderDiscount);
      }
    }
  };

  postServiceStatus = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-delivery-status/`,
        data
      )
      .then((resp) => {
        console.log(resp.data);
        // mainOrder.orderdelivery.push(resp.data);
        // toast.success(`Service status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
        // setShowEditModal(false);
        // setReportDelivery('');
        // setShowPopover(false);
        // setShow(false);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  handleHeadUpdateBtn = () => {
    console.log(this.state.order);

    if (this.state.orderStatus === '' || this.state.orderStatus === null) {
      toast.error(`Please select order status.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.paymentStatus === '' ||
      this.state.paymentStatus === null
    ) {
      toast.error(`Please select payment status.`, {
        autoClose: 3000,
      });
    } else if (
      this.state.serviceStatus === '' ||
      this.state.serviceStatus === null
    ) {
      toast.error(`Please select service status.`, {
        autoClose: 3000,
      });
    } else if (this.state.orderType === '' || this.state.orderType === null) {
      toast.error(`Please select order type.`, {
        autoClose: 3000,
      });
    } else if (this.state.orderCro === '' || this.state.orderCro === null) {
      toast.error(`Please select a CRO.`, {
        autoClose: 3000,
      });
    } else if (this.state.orderMt === '' || this.state.orderMt === null) {
      toast.error(`Please select a MT.`, {
        autoClose: 3000,
      });
    } else {
      const putData = {
        order_status: this.state.orderStatus,
        payment_status: this.state.paymentStatus,
        delivery_copy_status: this.state.serviceStatus,
        cs_agent: this.state.orderCro,
        mt: this.state.orderMt,
        references: this.state.orderRef,
      };
      // console.log(putData);

      const putOrder = {
        order_type: this.state.orderType,
      };

      this.putOrder(parseInt(this.props.match.params.id), putOrder);
      this.putOrderDetails(putData, this.state.order.orderdetail.id);

      if (this.state.orderDeliveryHead !== this.state.orderDeliveryHeadNew) {
        const serviceData = {
          order: this.state.order.id,
          name: this.state.orderDeliveryHeadNew,
          created_by: this.context.user_details.id,
        };

        this.postServiceStatus(serviceData);
      }
    }
  };

  handleReportOverviewI = () => {
    this.setState({
      reportOverviewEdit: true,
    });
  };

  postSoftCopy = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-delivery-status/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        // toast.success(`Report delivery status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  postHardCopy = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-hardcopy-delivery-status/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        // toast.success(`Report delivery status saved sucessfully.`, {
        //   autoClose: 3000,
        // });
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  updateReportOverview = () => {
    const putData = {
      delivery_copy_status: this.state.serviceStatus,
    };

    this.putOrderDetails(putData, this.state.order.orderdetail.id);

    if (this.state.serviceStatus === 'hard') {
      if (this.state.reportStatusHard !== '') {
        const postHardCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusHard,
          created_by: this.context.user_details.id,
          note: this.state.reportHardNote,
        };

        // console.log(postHardCopy);
        this.postHardCopy(postHardCopy);
      }
    } else if (this.state.serviceStatus === 'soft') {
      if (this.state.reportStatusSoft !== '') {
        const postSoftCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusSoft,
          created_by: this.context.user_details.id,
          note: this.state.reportSoftNote,
        };

        // console.log(postSoftCopy);
        this.postSoftCopy(postSoftCopy);
      }
    } else if (this.state.serviceStatus === 'both') {
      if (
        this.state.reportStatusHard !== '' &&
        this.state.reportStatusSoft !== ''
      ) {
        const postHardCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusHard,
          created_by: this.context.user_details.id,
          note: this.state.reportHardNote,
        };

        const postSoftCopy = {
          order: this.state.order.id,
          name: this.state.reportStatusSoft,
          created_by: this.context.user_details.id,
          note: this.state.reportSoftNote,
        };

        // console.log(postSoftCopy);
        // console.log(postHardCopy);
        this.postHardCopy(postHardCopy);
        this.postSoftCopy(postSoftCopy);
      }
    }

    this.setState({
      reportStatusSoft: '',
      reportSoftNote: '',
      reportStatusHard: '',
      reportHardNote: '',
    });
  };

  postOrderNote = (data) => {
    this.setState({ orderNoteLoading: true });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-note/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.fetchSingleOrder(parseInt(this.props.match.params.id));
        // toast.success(`Order note added successfully.`);

        this.setState({
          orderNoteEdit: false,
          orderNote: '',
          orderNoteId: null,
          orderNoteLoading: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ orderNoteLoading: false });
      });
  };

  putOrderNote = (data, id) => {
    this.setState({ orderNoteLoading: true });
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/order/order-note/${id}/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
        toast.success(`Order note updated successfully.`);
        this.setState({
          orderNoteEdit: false,
          orderNote: '',
          orderNoteId: null,
          orderNoteLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ orderNoteLoading: false });
        console.log(error.response);
      });
  };

  handleNoteSubmit = (e) => {
    e.preventDefault();

    if (this.state.orderNote.trim() === '') {
      toast.error(`Please enter order note.`);
    } else {
      const postOrderNote = {
        note: this.state.orderNote,
        order: this.state.order.id,
        created_by: this.context.user_details.id,
      };

      const putOrderNote = {
        // order: this.state.order.id,
        note: this.state.orderNote,
        created_by: this.context.user_details.id,
        // assign_by:'',
      };

      if (this.state.orderNoteId) {
        // console.log(putOrderNote);
        this.putOrderNote(putOrderNote, this.state.orderNoteId);
      } else {
        // console.log(postOrderNote);
        this.postOrderNote(postOrderNote);
      }
    }
  };

  handleOrderNoteEdit = (note) => {
    // console.log(note);

    this.setState({
      orderNoteEdit: true,
      orderNote: note.note,
      orderNoteId: note.id,
    });
  };

  handleOrderNoteFormClr = () => {
    this.setState({
      orderNoteEdit: false,
      orderNote: '',
      orderNoteId: null,
      orderNoteLoading: null,
    });
  };

  orderItemGet = (orderEditId) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/order/order-item-get/?order=${orderEditId}&limit=100`
      )
      .then((resp) => {
        const items = resp.data.results;
        // console.log(items);
        const totalPrice = items.reduce((total, item) => {
          return (
            total + parseInt(item.purchasable_order_item.sell_price)
            // fees.meterial_fee
          );
        }, 0);
        // console.log(totalPrice);

        const patients = items
          .map((patient) => {
            return patient.patient.id;
          })
          .filter((x, i, a) => a.indexOf(x) === i);
        // console.log(patients.length);

        let subTotal = items[0].meterial_fee * patients.length;
        // subTotal = subTotal + fees.collection_fee;
        // console.log(subTotal);

        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/order/order-only/${orderEditId}/`,
            {
              total_price: totalPrice,
              material_fee: subTotal,
            }
          )
          .then((resp) => {
            // console.log(resp.data);
            this.fetchSingleOrder(parseInt(this.props.match.params.id));
            // setItemEditModal(false);
            this.setOrderItemAddModal(false);
          })
          .catch((error) => {
            console.log(error.response);
          });
      });
  };

  handleMeterialPerson = (array) => {
    const patients = array
      .map((patient) => {
        return patient.patient.id;
      })
      .filter((x, i, a) => a.indexOf(x) === i);
    // console.log(patients.length);
    this.setState({ materialPerson: patients.length });
  };

  handleTestItemDelete = (item) => {
    this.setState({
      testCarts: this.state.testCarts.filter((o) => o !== item),
    });
    toast.success('Test removed successfully.', {
      autoClose: 3000,
    });
    setTimeout(() => {
      this.handleMeterialPerson(this.state.testCarts);
      if (this.state.testCarts.length === 0) {
        this.setState({
          discountPrice: null,
          discountCustom: null,
          discountSource: '',
          discountNote: '',
        });
      }
    }, 1000);

    this.handleSomeColculation();
    return;
    // console.log(item);
    const orderId = item.order.id;
    // console.log(orderId);
    // orderItemGet(orderId);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/order/order-item/${item.id}/`)
      .then((resp) => {
        // console.log(resp.data);
        // getSingleOrderTree(orderId);
        this.orderItemGet(orderId);
        toast.success(`Test item removed successfully.`, {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleTestItemModal = () => {
    // setAddressId(order.orderdetail.address.id);
    // setOrderEditId(order.id);
    // setOrderUserId(order.user.id);
    this.setState({ orderItemAddModal: true });
    return;

    // axios
    //   .get(
    //     `${process.env.REACT_APP_BASE_URL}/user_management/patient/?user=${order.user.id}&limit=300`
    //   )
    //   .then((resp) => {
    //     const results = resp.data.results.filter(
    //       (patient) => patient.is_active
    //     );
    //     // console.log(results);
    //   });
  };

  testItemModalClose = () => {
    // getSingleOrderTree(orderEditId);
    // setItemEditModal(false);
    setTimeout(() => {
      this.orderItemGet(this.state.order.id);
      toast.success(`Test item added successfully.`, {
        autoClose: 3000,
      });
    }, 1000);
  };

  setOrderItemAddModal = (value) => {
    this.setState({ orderItemAddModal: value });
  };

  setUserPatients = (value) => {

    console.log("The value is = ", value)
    this.setState({ userPatients: value, userPatientsShown: value });
  };

  setUserPatientsShown = (value) => {
    this.setState({ userPatientsShown: value });
  };

  setIsLabPartner = (value) => {
    this.setState({ isLabPartner: value });
  };

  postOrderEmailStatus = (data) => {
    this.setState({ reportSendEmails: false });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-email/`, data)
      .then((resp) => {
        // console.log(resp.data);
        this.setState({ reportSendEmailsLoading: false });
        this.fetchSingleOrder(parseInt(this.props.match.params.id));
      })
      .catch((error) => {
        console.log(error.response);
        this.setState({ reportSendEmailsLoading: false });
      });
  };

  lastReportStatus = (array) => {
    const sortedArray = array.sort((a, b) => {
      return a.id - b.id;
    });

    return sortedArray.at(-1).note;
  };

  handleSendEmail = () => {
    const regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/;
    let emails = this.state.reportSendEmails.split(',');
    emails = emails.filter((i) => i.trim());

    let validationMessage = [];
    let emailArray = [];

    emails.forEach((email) => {
      const test = regex.test(email.trim());
      validationMessage.push(test);
      emailArray.push(email.trim());
    });

    if (validationMessage.includes(false)) {
      toast.error('Please enter valid email address.');
    } else {
      // console.log(
      //   `${process.env.REACT_APP_BASE_URL}/order/report-mail/${
      //     this.state.order.id
      //   }/${emailArray.toString()}`
      // );
      // return;

      this.setState({ reportSendEmailsLoading: true });

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/order/report-mail/${this.state.order.id
          }/${emailArray.toString()}`
        )
        .then((resp) => {
          // console.log(resp.data);

          const postData = {
            order: this.state.order.id,
            mail_status: resp.data.status === 1 ? 'success' : 'failure',
            sender_by: this.context.user_details.id,
          };
          this.postOrderEmailStatus(postData);

          if (resp.data.status === 1) {
            toast.success(`Email send successfully.`);
          } else {
            toast.success(`Sorry, something went wrong. Try again later.`);
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.setState({ reportSendEmailsLoading: false });
        });
    }
  };

  getTotalPrice = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.test_item.test_item.purchasable_order_item.sell_price),
      0
    );

    return parseInt(diagprice) + parseInt(packprice);
  };

  getTotalPriceWithFees = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.test_item.test_item.purchasable_order_item.sell_price),
      0
    );

    return (
      parseInt(diagprice) +
      parseInt(packprice) +
      parseInt(this.state.fees.collection_fee) +
      this.state.materialPerson * parseInt(this.state.fees.meterial_fee)
    );
  };

  checkHaveOrder = (id) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/user-order-exist/${id}`)
      .then((resp) => {
        // console.log(resp.data);
        // setHaveOrder(resp.data.exist);
        this.setState({ haveOrder: resp.data.exist });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  handleUserCreate = (e) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;

    if (this.state.fullname.trim().length < 4) {
      toast.error('User fullname must be at least 4 character.', {
        autoClose: 3000,
      });
    } else if (this.state.dob === '' || this.state.dob === null) {
      toast.error('Please enter user date of birth.', {
        autoClose: 3000,
      });
    } else if (!numberPattern.test(this.state.username)) {
      toast.error('Please enter a valid mobile number', {
        autoClose: 3000,
      });
    } else if (this.state.sex === '') {
      toast.error('Please select user gender.', {
        autoClose: 3000,
      });
    } else {
      this.setState({ userCreateLoading: true });

      const postUser = {
        username: this.state.username,
        password1: this.state.password,
        password2: this.state.password,
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/auth/registration`, {
        method: 'POST',
        body: JSON.stringify(postUser),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.username) {
            toast.error(`${data.username[0]}`, {
              autoClose: 3000,
            });
            return;
          } else if (data.password1) {
            if (data.password1[2]) {
              toast.error(`${data.password1[2]}`, {
                autoClose: 3000,
              });
            } else if (data.password1[1]) {
              toast.error(`${data.password1[1]}`, {
                autoClose: 3000,
              });
            } else if (data.password1[0]) {
              toast.error(`${data.password1[0]}`, {
                autoClose: 3000,
              });
            }
          } else if (data.key) {
            const myHeaders = new Headers({
              'Content-Type': 'application/json',
              Authorization: `Token ${data.key}`,
            });

            fetch(`${process.env.REACT_APP_BASE_URL}/auth/user/`, {
              method: 'GET',
              headers: myHeaders,
            })
              .then((resp) => resp.json())
              .then((data) => {
                this.checkHaveOrder(data.pk);

                axios
                  .get(
                    `${process.env.REACT_APP_BASE_URL}/user_management/user/${data.pk}`
                  )
                  .then((resp) => {
                    const postPatient = {
                      is_account: true,
                      user: resp.data.id,
                      full_name: this.state.fullname,
                      sex: this.state.sex,
                      dob: moment(this.state.dob).format('YYYY-MM-DD'),
                      mobile: this.state.username,
                      email: this.state.email,
                    };

                    const getLastName = (fullName) => {
                      let parts = fullName.split(' ');
                      // let firstName = parts.shift();
                      // let lastName = parts.join(' ');

                      // return lastName;
                      parts.shift();
                      return parts.join(' ');
                    };

                    const putUser = {
                      username: resp.data.username,
                      password: resp.data.password,
                      first_name: this.state.fullname.split(' ').shift(),
                      last_name: getLastName(this.state.fullname),
                      email: this.state.email,
                    };

                    axios
                      .put(
                        `${process.env.REACT_APP_BASE_URL}/user_management/user/${resp.data.id}/`,
                        putUser
                      )
                      .then((resp) => {
                        // console.log(resp.data);
                        // const userDetails = {
                        //   username: resp.data.username,
                        //   id: resp.data.id,
                        //   first_name: resp.data.first_name,
                        //   last_name: resp.data.last_name,
                        // };
                        // console.log(userDetails);
                        this.setUserDetails(resp.data);
                        this.setMobile(resp.data.username);

                        const user_id = resp.data.id;

                        axios
                          .post(
                            `${process.env.REACT_APP_BASE_URL}/user_management/patient/`,
                            postPatient
                          )
                          .then((resp) => {
                            toast.success(`User added successfully.`, {
                              autoClose: 3000,
                            });
                            this.setState({ username: '' });
                            this.setState({ fullname: '' });
                            this.setState({ sex: '' });
                            this.setState({ dob: '' });
                            this.setState({ userCreateLoading: false });

                            axios
                              .get(
                                `${process.env.REACT_APP_BASE_URL}/user_management/patient-with-info/?user=${user_id}&limit=300`
                              )
                              .then((resp) => {
                                const results = resp.data.results.map(
                                  (patient) => {
                                    return {
                                      ...patient,
                                      value: patient.full_name,
                                      label: patient.full_name,
                                    };
                                  }
                                );
                                // console.log(results);
                                this.setUserPatients(results);
                              });
                          });
                      })
                      .catch((err) => {
                        console.log(err.response);
                        this.setState({ userCreateLoading: false });
                      });
                  })
                  .catch((err) => {
                    console.log(err.response);
                    this.setState({ userCreateLoading: false });
                  });
              });
          }
        })
        .catch((error) => {
          console.log('I am in error ', error);
        });
    }
  };

  handleUserFormClr = () => {
    this.setState({
      username: '',
      fullname: '',
      sex: '',
      dob: '',
      email: '',
    });
  };

  orderTotalPrice = (array) => {
    const diagnosticrr = array.filter((diag) => {
      return diag.order_type === 'diagnostic';
    });

    const packagerr = array.filter((diag) => {
      return diag.order_type === 'package';
    });

    const diagprice = diagnosticrr.reduce(
      (total, item) =>
        total + parseInt(item.test_item.purchasable_order_item.sell_price),
      0
    );

    const packprice = packagerr.reduce(
      (total, item) =>
        total +
        parseInt(item.test_item.test_item.purchasable_order_item.sell_price),
      0
    );

    return parseInt(diagprice) + parseInt(packprice);
  };

  postOrderApproval = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-approval/`, data)
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  smsApiCall = (orderId, status) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/order/sms-order-status/${orderId}/${status}`)
      .then((resp) => {
        // console.log('come success', resp.data);
      })
      .catch((error)=> {
        // console.log('come err', error.response);
      })
  }

  orderItemMaterial = (source) => {
    if (source === 'material_fee') {
      return 0;
    } else if (source === 'fee' || source === 'fee_discount') {
      const main =
        this.state.fees.meterial_fee * this.state.materialPerson -
        this.state.orderDiscountMate;
      return Math.round(main / this.state.materialPerson);
    } else {
      return this.state.fees.meterial_fee;
    }
  };

  orderDiscountPrice = (source) => {
    if (
      source === 'collection_fee' ||
      source === 'material_fee' ||
      source === 'fee'
    ) {
      return 0;
    } else if (source === 'fee_discount') {
      return this.state.orderDiscountCustom
        ? this.state.orderDiscountCustom
        : 0;
    } else {
      return this.state.orderDiscountPrice;
    }
  };

  orderDiscountMaterial = (source) => {
    if (source === 'material_fee') {
      return this.state.fees.meterial_fee * this.state.materialPerson;
    } else if (source === 'fee' || source === 'fee_discount') {
      // return (
      //   this.state.fees.meterial_fee * this.state.materialPerson -
      //   this.state.discountMaterial
      // );
      return Math.round(this.state.orderDiscountMate);
    } else {
      return 0;
    }
  };

  orderDiscountCollection = (source) => {
    if (source === 'collection_fee') {
      return this.state.fees.collection_fee;
    } else if (source === 'fee' || source === 'fee_discount') {
      // const mainColl =
      //   this.state.fees.collection_fee -
      //   parseInt(this.state.discountCollection);
      // return mainColl;
      return Math.round(this.state.orderDiscountColl);
    } else {
      return 0;
    }
  };

  handleQuickOrder = (orderId) => {
    const qOrrderParams = this.props.location.params;
    const qOrderId = qOrrderParams.qOrderId;
    const currentUser = JSON.parse(localStorage.getItem('user_details'));

    const data = {
      order: orderId,
      is_responsed: true,
      response_user: currentUser.id,
    };

    this.setState({ responseLoading: true });
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/order/quick-order/${qOrderId}/`,
        data
      )
      .then((resp) => {
        this.props.history.push('/quick-orders');
        toast.success(`Order Places Successfully.`, {
          autoClose: 3000,
        });
        this.setState({ headUpdateLoading: false });
      })
      .catch((error) => {
        this.setState({ responseLoading: false });
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  orderDiscountPost = (postDate, orderId) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order-discount/`, postDate)
      .then((resp) => {
        if (this.state.isqOrder) {
          this.handleQuickOrder(orderId);
        } else {
          this.props.history.push('/orders');
          toast.success(`Order Places Successfully.`, {
            autoClose: 3000,
          });
          this.setState({ headUpdateLoading: false });
        }
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  postDeliverySoft = (id) => {
    const postDate = {
      order: id,
      name: this.state.reportStatusSoft,
      created_by: this.context.user_details.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-delivery-status/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  postDeliveryHard = (id) => {
    const postDate = {
      order: id,
      name: this.state.reportStatusHard,
      created_by: this.context.user_details.id,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/order/order-report-hardcopy-delivery-status/`,
        postDate
      )
      .then((resp) => {
        // console.log(resp.data);
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  postMtStatus = (data) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/mt/mt-status/`, data)
      .then((resp) => {
        // console.log(resp.data);
        // this.fetchSingleOrder(parseInt(this.props.match.params.id));
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(`Something went wrong, plase try again later.`, {
          autoClose: 3000,
        });
      });
  };

  postOrderAndDetails = (id) => {
    const order_items = this.state.testCarts.map((order) => {
      return {
        patient: order.patient.id,
        order_type: order.order_type,
        address: id,
        meterial_fee: this.orderItemMaterial(this.state.orderDiscountSource),
        purchasable_order_item:
          order.order_type === 'package'
            ? `${order.test_item.test_item.purchasable_order_item.id}`
            : `${order.test_item.purchasable_order_item.id}`,
      };
    });


    const postOrder = {
      user: this.state.userDetails.id,
      date: moment(this.state.sampleDate).format('YYYY-MM-DD'),
      time: moment(this.state.sampleTime).format('HH:mm:ss'),
      orderitem: order_items,
      total_price: this.orderTotalPrice(this.state.testCarts),
      collection_fee: this.orderCollection(this.state.orderDiscountSource),
      material_fee: this.orderMaterial(this.state.orderDiscountSource),
      order_type: this.state.orderType,
      from_sneho: this.state.from_sneho,
      sneho_prescription_id: this.state.sneho_prescription_id,
    };

    if (this.state.isLabPartner)
      postOrder.external_source_user = this.state.userDetails.id;

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/order/order/`, postOrder)
      .then((resp) => {
        this.referenceCheck(this.state.orderRef);

        const orderId = resp.data.orderitem[0].order;
        const postOrderDetails = {
          order: orderId,
          address: id,
          user_type: this.state.haveOrder ? 'old' : 'new',
          references: this.state.orderRef,
          payment_status: this.state.paymentStatus,
          payment_note: this.state.paymentNote,
          cs_agent: this.context.user_details.id,
          delivery_copy_status: this.state.serviceStatus,
          mt: this.state.orderMt,
        };

        // this.smsApiCall(orderId, 'order_placement');

        const postMtStatusData = {
          mt: this.state.orderMt,
          name: 'pending',
          order: orderId,
          created_by: this.context.user_details.id,
        };

        // this.postMtStatus(postMtStatusData);

        const approvalData = {
          order: orderId,
          is_approved: false,
        };

        const orderNoteData = {
          note: this.state.orderNote,
          order: orderId,
          created_by: this.context.user_details.id,
          marked: this.state.orderNoteMarked,
        };

        if (this.state.orderNote !== '') {
          this.postOrderNote(orderNoteData);
        }

        if (this.state.isLabPartner) {
          this.postOrderApproval(approvalData);
        }

        this.postDeliverySoft(orderId);
        this.postDeliveryHard(orderId);

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/order/order-detail/`,
            postOrderDetails
          )
          .then((resp) => {
            const postOrderDiscount = {
              discount_source: this.state.orderDiscountSource,
              discount_price: this.orderDiscountPrice(
                this.state.orderDiscountSource
              ),
              discount_note: this.state.orderDiscountNote || 'backend discount',
              discount_by: this.context.user_details.id,
              material_discount_price: this.orderDiscountMaterial(
                this.state.orderDiscountSource
              ),
              collection_discount_price: this.orderDiscountCollection(
                this.state.orderDiscountSource
              ),
              order: orderId,
            };

            this.context.fetchOrderStatus();

            if (this.state.orderDiscountSource) {
              this.orderDiscountPost(postOrderDiscount, orderId);
            } else {
              if (this.state.isqOrder) {
                this.handleQuickOrder(orderId);
              } else {
                this.props.history.push('/orders');
                toast.success(`Order Places Successfully.`, {
                  autoClose: 3000,
                });
                this.setState({ headUpdateLoading: false });
              }
            }
          })
          .catch((error) => {
            console.log(error.response);
          });



          //update patient info
          for(var x = 0; x < this.state.AllPatientInfos.length; x++){
            axios
              .put(
                `${process.env.REACT_APP_BASE_URL}/user_management/patient-info/${this.state.AllPatientInfos[x]}/`,
                {order: orderId}
              )
              .then((resp) => {
                toast.success(`Patient order updated successfully.`, {
                  autoClose: 3000,
                });
              })
              .catch((error) => {
              });
          }
          //finish update patient info



      });
  };

  referenceCheck = (ref) => {
    if (ref) {
      // console.log(ref);
      // console.log(this.state.referenceGetedItem);
      const check = this.state.referenceGetedItem.filter((item) => {
        return item.name.toLowerCase() === ref.toLowerCase();
      });
      if (check.length === 0) {
        // console.log('api request');
        const postData = {
          name: ref,
          created_by: this.context.user_details.id,
        };
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/order/reference-data-set/`,
            postData
          )
          .then((resp) => {
            // console.log(resp.data.results);
          })
          .catch((error) => {
            // console.log(error.response);
          });
      }
      // console.log(check);
    }
  };

  handlePlaceOrder = () => {
    const numberPattern = /^01\d{9}$/;

    // alert(this.state.from_sneho)
    // alert(this.state.sneho_prescription_id)

    if (this.state.orderStatus === '' || this.state.orderStatus === null) {
      toast.error('Please select order status.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.paymentStatus === '' || this.state.paymentStatus === null) {
      toast.error('Please select payment status.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.paymentStatus === 'paid' && (this.state.paymentNote === '' || this.state.paymentNote === null)) {
      toast.error('Please write payment Note.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.serviceStatus === '' || this.state.serviceStatus === null) {
      toast.error('Please select report status.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.orderType === '' || this.state.orderType === null) {
      toast.error('Please select order type.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (this.state.orderRef === '' || this.state.orderRef === null) {
      toast.error('Please enter reference.');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }

    if (!this.state.orderMt) {
      toast.error('Please select a mt.');
      return;
    }


    if (!this.state.userDetails) {
      toast.error('Please select/create a user.');
      window.scroll({ top: 700, left: 0, behavior: 'smooth' });
      return;
    }



    if (this.state.orderDiscountSource !== '') {
      if (this.state.orderDiscountSource === 'admin') {
        if (
          this.state.orderDiscountPrice === '' ||
          this.state.orderDiscountPrice === null
        ) {
          toast.error('Please enter discount rate.');
          return;
        }
      }

      if (this.state.orderDiscountSource === 'collection_fee') {
        if (this.state.orderDiscountColl === '') {
          toast.error('Please enter discount collection fee.');
          return;
        }
      }

      if (this.state.orderDiscountSource === 'material_fee') {
        if (this.state.orderDiscountMate === '') {
          toast.error('Please enter discount material fee.');
          return;
        }
      }

      if (this.state.orderDiscountSource === 'fee') {
        if (this.state.orderDiscountColl === '') {
          toast.error('Please enter discount collection fee.');
          return;
        }

        if (this.state.orderDiscountMate === '') {
          toast.error('Please enter discount material fee.');
          return;
        }
      }

      if (this.state.orderDiscountSource === 'fee_discount') {
        if (this.state.orderDiscountCustom === '') {
          toast.error('Please enter discount rate.');
          return;
        }
        if (this.state.orderDiscountColl === '') {
          toast.error('Please enter discount collection fee.');
          return;
        }

        if (this.state.orderDiscountMate === '') {
          toast.error('Please enter discount material fee.');
          return;
        }
      }
    }


    // alert(this.state.from_sneho)
    // alert(this.state.sneho_prescription_id)

    const snehoPrescriptionId = this.state.sneho_prescription_id || '';
    if (this.state.from_sneho && !snehoPrescriptionId.trim()) {
      toast.error('Please enter Sneho Prescription ID, or uncheck "From Sneho".');
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      return;
    }


    if (this.state.testCarts.length === 0) {
      toast.error('Please add a test/package.');
      window.scroll({ top: 1650, left: 0, behavior: 'smooth' });
      return;
    }


    if (this.state.district === '') {
      window.scroll({ top: 1300, left: 0, behavior: 'smooth' });
      toast.error('Please select location area.');
    } else if (this.state.thana === '') {
      window.scroll({ top: 1300, left: 0, behavior: 'smooth' });
      toast.error('Please select location thana.');
    } else if (this.state.address.trim() === '') {
      window.scroll({ top: 1300, left: 0, behavior: 'smooth' });
      toast.error('Please enter location details.');
    } else if (!numberPattern.test(this.state.mobile)) {
      window.scroll({ top: 1300, left: 0, behavior: 'smooth' });
      toast.error('Please enter a valid mobile number.');
    } else if (this.state.sampleDate === '' || this.state.sampleDate === null) {
      toast.error('Please select sample collection date.');
      window.scroll({ top: 1100, left: 0, behavior: 'smooth' });
    } else if (this.state.sampleTime === '' || this.state.sampleTime === null) {
      toast.error('Please select sample collection time.');
      window.scroll({ top: 1100, left: 0, behavior: 'smooth' });
    } else if (this.state.paymentStatus === '') {
      toast.error('Please select payment status.');
      // this.refPaymentStatus.current.focus();
      // window.scroll(0, 1600);
    } else {
      this.setState({ headUpdateLoading: true });

      const patientId = this.state.userPatients.filter(
        (patient) => patient.is_account
      );

      const postAddress = {
        district: this.state.district,
        thana: this.state.thana,
        address: this.state.address,
        mobile: this.state.mobile,
        email: this.state.addEmail,
        patient: patientId.length !== 0 ? patientId[0].id : null,
      };

      let firstTimeRequested = true;

      if (this.state.addId) {
        // axios
        //   .put(
        //     `${process.env.REACT_APP_BASE_URL}/user_management/address/${this.state.addId}/`,
        //     postAddress
        //   )
        //   .then((resp) => {
        //     // console.log(resp.data);
        //     if (firstTimeRequested) {
        //       this.postOrderAndDetails(resp.data.id);
        //     }
        //     firstTimeRequested = false;
        //   })
        //   .catch((error) => {
        //     console.log(error.response);
        //   });
        this.postOrderAndDetails(this.state.addId);
      } else {
        // axios
        //   .post(
        //     `${process.env.REACT_APP_BASE_URL}/user_management/address/`,
        //     postAddress
        //   )
        //   .then((resp) => {
        //     // console.log(resp.data);
        //     if (firstTimeRequested) {
        //       this.postOrderAndDetails(resp.data.id);
        //     }
        //     firstTimeRequested = false;
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        window.scroll({ top: 1300, left: 0, behavior: 'smooth' });
        toast.error('Please select a location for collection.');
      }
    }
  };

  calculateLabDiscount = (array) => {
    // console.log(array);
    // console.log(this.state.orders);

    let labMatched = false;

    this.state.testCarts.forEach((test) => {
      let matchedLabs = [];
      if (test.order_type === 'diagnostic') {
        const matched = array.find((coupon) => {
          return (
            test.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      } else if (test.order_type === 'package') {
        const matched = array.find((coupon) => {
          return (
            test.test_item.test_item.branch.lab.name.toLowerCase() ===
            coupon.lab.name.toLowerCase()
          );
        });
        if (matched) {
          matchedLabs.push(matched);
        }
      }

      if (matchedLabs.length !== 0) {
        labMatched = true;
      } else {
        labMatched = false;
      }
    });

    if (labMatched) {
      let matchedTests = [];
      let matchedPackages = [];

      array.forEach((labTest) => {
        let single = [];
        labTest.cct_lab.forEach((test) => {
          let existing = this.state.testCarts
            .filter((cart) => cart.order_type === 'diagnostic')
            .filter(
              (cart) =>
                cart.test_item.diagnostic_test.id === test.test.id &&
                cart.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedTests.push(...single);
      });
      array.forEach((labTest) => {
        let single = [];
        labTest.ccp_lab.forEach((test) => {
          let existing = this.state.testCarts
            .filter((cart) => cart.order_type === 'package')
            .filter(
              (cart) =>
                cart.package.package.id === test.test_item.id &&
                cart.package.test_item.branch.lab.id === labTest.lab.id
            )
            .map((cart) => {
              return {
                ...cart,
                criteria: labTest.criteria,
                rate: labTest.rate,
                is_collection_fee: labTest.is_collection_fee,
                is_material_fee: labTest.is_material_fee,
                material_rate_percentage: labTest.material_rate_percentage,
              };
            });

          if (existing) {
            single.push(...existing);
          }
        });
        matchedPackages.push(...single);
      });

      matchedTests = matchedTests.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.test_item.id === tag.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      matchedPackages = matchedPackages.filter(
        (tag, index, array) =>
          array.findIndex(
            (t) =>
              t.package.test_item.id === tag.package.test_item.id &&
              t.patient.id === tag.patient.id
          ) === index
      );

      const allItems = [...matchedTests, ...matchedPackages];
      // console.log(allItems);

      let itemMaterial = 0,
        itemColletion = 0;

      const checkCollection = allItems.map((item) => item.is_collection_fee);
      checkCollection.includes(true)
        ? (itemColletion = 0)
        : (itemColletion = this.state.fees.collection_fee);

      const checkMaterial = allItems
        .filter((item) => {
          return item.is_material_fee;
        })
        .sort(
          (a, b) =>
            parseFloat(b.material_rate_percentage) -
            parseFloat(a.material_rate_percentage)
        );
      if (checkMaterial.length !== 0) {
        // console.log(checkMaterial[0]);
        itemMaterial =
          this.state.fees.meterial_fee -
          (this.state.fees.meterial_fee *
            checkMaterial[0].material_rate_percentage) /
          100;
      } else {
        itemMaterial = this.state.fees.meterial_fee;
      }

      // console.log(itemColletion, itemMaterial);

      const newCart = this.state.testCarts.map(
        (cart) =>
          matchedTests.find(
            (test) =>
              test.patient === cart.patient && test.test_item === cart.test_item
          ) || cart
      );
      // console.log(newCart);
      this.setState({ testCarts: newCart });

      const newCart1 = this.state.testCarts.map(
        (cart) =>
          matchedPackages.find(
            (test) =>
              test.patient === cart.patient && test.package === cart.package
          ) || cart
      );
      // console.log(newCart1);
      this.setState({ testCarts: newCart1 });

      const discountTestPrice = matchedTests.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.test_item.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
            100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPackagePrice = matchedPackages.reduce((total, item) => {
        if (item.criteria === 'perchantange') {
          return (
            total +
            (parseInt(item.package.package.purchasable_order_item.sell_price) *
              parseInt(item.rate)) /
            100
          );
        } else {
          return total + parseInt(item.rate);
        }
      }, 0);

      const discountPrice = discountTestPrice + discountPackagePrice;

      if (discountPrice && 0 < discountPrice) {
        this.setState({
          orderDiscountPrice: discountPrice,
          orderDiscountCustom: discountPrice,
          orderDiscountPriceFromLab: discountPrice,
          orderDiscountSource: 'cupon',
          orderDiscountNote: 'lab discount',
        });
        // console.log(discountTestPrice);
      }
    }
  };

  calculateCartAndLab = () => {
    // console.log(this.state.labDiscountArray);
    this.calculateLabDiscount(this.state.labDiscountArray);
  };

  handleSomeColculation = () => {
    setTimeout(() => {
      if (this.state.testCarts.length !== 0) {
        this.calculateCartAndLab();

        this.getFees()
                 // if (this.state.testCarts && this.state.testCarts.length && this.state.testCarts[0].test_item && this.state.testCarts[0].test_item.branch && this.state.testCarts[0].test_item.branch.lab && !this.state.testCarts[0].test_item.branch.lab.is_active ){
                 //    this.setState({
                 //      fees: {
                 //        collection_fee: 500,
                 //        meterial_fee: resp.data.results[0].meterial_fee,
                 //        delivery_fee: resp.data.results[0].delivery_fee,
                 //      },
                 //    });
                 //  }


        if (
          this.state.testCarts[0].order_type === 'package' ||
          this.state.orderType === 'covid'
        ) {
          this.setState({
            fees: { collection_fee: 0, meterial_fee: 0, delivery_fee: 0 },
            orderDiscountCollection: 0,
            orderDiscountMate: 0,
          });
        }
      }

      if (this.state.orderType === 'covid') {
        this.setState({
          fees: { collection_fee: 0, meterial_fee: 0, delivery_fee: 0 },
        });
      }
    }, 500);
  };

  handlePatientSearch = (e) => {
    this.setState({ userPatientName: e.target.value });
    setTimeout(() => {
      const newPatient = this.state.userPatients.filter((p) =>
        p.full_name
          .toLowerCase()
          .includes(this.state.userPatientName.trim().toLocaleLowerCase())
      );
      this.setState({ userPatientsShown: newPatient });
    }, 200);
  };

  render() {
    const { order, testCarts, medicalTech, reports } = this.state;
    const { userPatients, orderDiscountSource, userPatientsShown, addId, selectedPreviousAddress, previousAddresses } = this.state;

    return (
      <>
        <HeaderToolbar title='Create New Order' />

        <div className='content d-flex flex-column flex-column-fluid order-edit'>
          <div id='kt_content_container' className='container-xxl'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card card-custom card-stretch gutter-b head-card shadow'>
                  <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-center flex-colum'>
                      <Link
                        to='/orders'
                        className='btn btn-light-primary py-5 px-8 me-7'
                      >
                        <i className='fas fa-chevron-left me-2'></i>
                        Back
                      </Link>
                      <div className='d-flex align-items-start'>
                        <div className='me-6'>
                          <h2 className='fs-1 mb-2'>Order Details</h2>
                        </div>
                        <span className='text-primary fs-7 mt-1 fw-normal'>
                          {this.state.userDetails &&
                            (this.state.haveOrder ? 'OLD USER' : 'NEW USER')}
                        </span>
                      </div>
                    </h3>
                    <div className='card-toolbar'>
                      {this.state.headUpdateLoading ? (
                        <BtnLoading
                          name='Placing...'
                          btnClass='primary submit-btn'
                        />
                      ) : (
                        <button
                          onClick={this.handlePlaceOrder}
                          className='submit-btn btn btn-primary py-5 px-8'
                        >
                          Place order
                        </button>
                      )}
                    </div>
                  </div>
                  <div className='card-header border-0 pt-0'>
                    <h3 className='card-title align-items-center flex-colum'>
                      <p className='bg-light-primary px-16 me-7'></p>
                      <div className='d-flex'>
                        <div className='head-select'>
                          <span className='text-primary'>Order: </span>
                          <select
                            className='form-select text-primary'
                            value={this.state.orderStatus}
                            onChange={(e) =>
                              this.setState({
                                orderStatus: e.target.value,
                              })
                            }
                          >
                            <option value=''>Select order status</option>
                            <option value='pending'>Pending</option>
                            <option value='waiting_for_confirmation'>
                              Waiting for Confirmation
                            </option>
                            <option value='service_on_hold'>
                              Service on Hold
                            </option>
                            <option value='confirmed'>Confirmed</option>
                            <option value='cancel'>Cancel</option>
                            <option value='completed'>Completed</option>
                          </select>
                        </div>
                        <div className='head-select'>
                          <span className='text-primary'>Service: </span>

                          <select
                            className='form-select text-primary'
                            value={this.state.orderDeliveryHeadNew}
                            onChange={(e) =>
                              this.setState({
                                orderDeliveryHeadNew: e.target.value,
                              })
                            }
                          >
                            <option value=''>Select service status</option>
                            <option value='pending'>Pending</option>
                            <option value='order_received'>
                              Order Received
                            </option>
                            <option value='sample_collected'>
                              Sample Collected
                            </option>
                            <option value='sample_delivered_to_lab'>
                              Sample Delivered to Lab
                            </option>
                          </select>
                        </div>
                        <div className='head-select'>
                          <span className='text-danger'>Payment: </span>
                          <select
                            className='form-select text-danger'
                            value={this.state.paymentStatus}
                            onChange={(e) =>
                              this.setState({
                                paymentStatus: e.target.value,
                              })
                            }
                          >
                            <option value=''>Set Payment Status</option>
                            <option value='paid'>Paid</option>
                            <option value='unpaid'>Unpaid</option>
                          </select>
                        </div>
                        <div className='head-select'>
                          <span className='text-danger'>Report: </span>
                          <select
                            className='form-select text-danger'
                            value={this.state.serviceStatus}
                            onChange={(e) => {
                              this.setState({
                                serviceStatus: e.target.value,
                              });
                              setTimeout(() => {
                                this.getFees();
                              }, 200);
                            }}
                          >
                            <option value='both'>Both Copy</option>
                            <option value='hard'>Hard Copy</option>
                            <option value='soft'>Soft Copy</option>
                          </select>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div className='card-header border-0 pt-0 align-items-end'>
                    <h3 className='card-title align-items-center flex-colum'>
                      <p className='bg-light-primary px-16 me-7'></p>
                      <div className='d-flex'>
                        <div className='cro-mt ms-0'>
                          <select
                            className='form-select'
                            value={this.state.orderType}
                            onChange={(e) => {
                              this.setState({
                                orderType: e.target.value,
                              });
                              if (e.target.value === 'covid') {
                                this.setState({
                                  fees: {
                                    collection_fee: 0,
                                    meterial_fee: 0,
                                    delivery_fee: 0,
                                  },
                                });
                              } else {
                                this.getFees();
                              }
                            }}
                          >
                            <option value=''>Order Type</option>
                            <option value='covid'>Covid</option>
                            <option value='non_covid'>Non Covid</option>
                          </select>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='ref-h'>
                            <span className='fw-bold'>Ref: </span>
                            <input
                              list='refLists'
                              value={this.state.orderRef}
                              onChange={(e) =>
                                this.setState({
                                  orderRef: e.target.value,
                                })
                              }
                            />
                            <datalist id='refLists'>
                              {this.state.referenceGetedItem.map((i, a) => (
                                <option value={i.name} key={a}>
                                  {i.name}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        </div>





                        {this.state.sampleDate && (
                          <div className='dashed'>
                            <p className='m-0'>
                              {moment(this.state.sampleDate).format(
                                'DD MMM YYYY'
                              )}
                            </p>
                            <span className='text-gray-500'>Due Date</span>
                          </div>
                        )}
                        {this.state.sampleTime && (
                          <div className='dashed'>
                            <p className='m-0'>
                              {moment(this.state.sampleTime, [
                                'HH:mm:ss',
                              ]).format('hh:mm')}{' '}
                              -{' '}
                              {moment(this.state.sampleTime, 'HH:mm:ss')
                                .add(120, 'minutes')
                                .format('hh:mm A')}
                            </p>
                            <span className='text-gray-500'>
                              Collection Time
                            </span>
                          </div>
                        )}
                        {this.state.testCarts.length !== 0 && (
                          <div className='dashed'>
                            <p className='m-0'>
                              ৳{' '}
                              {this.getTotalPriceWithFees(this.state.testCarts)}
                            </p>
                            <span className='text-gray-500'>Order Amount</span>
                          </div>
                        )}
                      </div>
                    </h3>
                    <div className='card-toolbar'>







                      <div className='cro-mt'>
                        <span className='fw-bold'>MT</span>
                        <select
                          className='form-select'
                          value={this.state.orderMt}
                          onChange={(e) =>
                            this.setState({
                              orderMt: e.target.value,
                            })
                          }
                        >
                          <option value=''>MT</option>
                          {medicalTech &&
                            medicalTech.map((user, i) => (
                              <option value={user.id} key={i}>
                                {user.first_name
                                  ? `${user.first_name} ${user.last_name}`
                                  : user.username}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='card-body pt-2 pb-0 mt-6'>
                    <nav className='menu-lists'>
                      <ScrollSpy duration={1500} offsetTop={200}>
                        {/* <a href='#order-refe-nav' ref={React.createRef()}>
                          Reference
                        </a> */}
                        <a href='#order-note-nav' ref={React.createRef()}>
                          Order Notes
                        </a>
                        <a href='#patient-info-nav' ref={React.createRef()}>
                          Patient Info.
                        </a>
                        <a href='#schedule-nav' ref={React.createRef()}>
                          Schedule
                        </a>
                        <a href='#collection-info-nav' ref={React.createRef()}>
                          Collection Info.
                        </a>
                        <a href='#order-summery-nav' ref={React.createRef()}>
                          Order Summary
                        </a>
                        <a href='#payment-info-nav' ref={React.createRef()}>
                          Payment Info.
                        </a>
                        <a href='#discount-info-nav' ref={React.createRef()}>
                          Discount
                        </a>
                        <a href='#report-info-nav' ref={React.createRef()}>
                          Report
                        </a>
                      </ScrollSpy>
                    </nav>
                  </div>
                </div>

                <div className='all-sections'>
                  {/* ==================Reference Section================== */}
                  {/* <div
                    id='order-refe-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-md-6 col-6in7-l'>
                              <div className='mb-7'>
                                <p className='item-title'>Cupon Code *</p>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder='Enter coupon'
                                  value={this.state.ref_cup_code}
                                  onChange={(e) =>
                                    this.setState({
                                      ref_cup_code: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className=''>
                                <p className='item-title'>
                                  Reference Channel *
                                </p>
                                <select
                                  className='form-select form-select-sloid'
                                  value={this.state.ref_channel}
                                  onChange={(e) =>
                                    this.setState({
                                      ref_channel: e.target.value,
                                    })
                                  }
                                >
                                  <option value=''>Select Channel</option>
                                  <option value='male'>Male</option>
                                  <option value='female'>Female</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-md-6 col-6in7-r'>
                              <div className='mb-7'>
                                <p className='item-title'>Select Reference *</p>
                                <select
                                  className='form-select form-select-sloid'
                                  value={this.state.ref_reference}
                                  onChange={(e) =>
                                    this.setState({
                                      ref_reference: e.target.value,
                                    })
                                  }
                                >
                                  <option value=''>Select Reference</option>
                                  <option value='male'>Male</option>
                                  <option value='female'>Female</option>
                                </select>
                              </div>

                              <div className='mb-7'>
                                <p className='item-title text-white'>-</p>
                                <p className='mb-0 text-end'>
                                  <div className='d-flex justify-content-between'>
                                    <button
                                      className='submit-btn btn btn-light'
                                      onClick={this.handleUserFormClr}
                                    >
                                      View Details
                                    </button>
                                    {this.state.userCreateLoading ? (
                                      <BtnLoading
                                        name='Adding'
                                        btnClass='primary submit-btn'
                                      />
                                    ) : (
                                      <button
                                        className='submit-btn btn btn-primary'
                                        onClick={this.handleUserCreate}
                                      >
                                        Save
                                      </button>
                                    )}
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>Reference: </p>
                          <p className='m-0 edit-option'>
                            <p className='mb-2'>#778652</p>
                            <p className='text-primary mb-2'>
                              <span className='fw-bold'>Reference: </span>
                              Dr. Ahmed
                            </p>
                            <p className='mb-2'>Channel: DMF Doctor</p>
                            <p className='mb-2'>Category: Direct</p>
                            <i
                              className='far fa-edit'
                              onClick={this.handleAddressI}
                            ></i>
                          </p>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>ORDER reference</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* ==================End Reference Section================== */}

                  {/* ==================Notes Section================== */}
                  <div
                    id='order-note-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <p className='item-title'>Note</p>
                          <form onSubmit={this.handleNoteSubmit}>
                            <textarea
                              className='form-control mb-7'
                              rows='6'
                              placeholder='Add Note'
                              value={this.state.orderNote}
                              onChange={(e) =>
                                this.setState({
                                  orderNote: e.target.value,
                                })
                              }
                              maxLength={990}
                            ></textarea>

                            <p className='item-title'>Marked as Colored</p>
                            <div className='form-check form-switch form-check-custom form-check-solid'>
                              <input
                                type='checkbox'
                                name='new_user_only'
                                onChange={() =>
                                  this.setState({
                                    orderNoteMarked:
                                      !this.state.orderNoteMarked,
                                  })
                                }
                                checked={this.state.orderNoteMarked}
                                className='form-check-input w-50px'
                              />
                            </div>

                            {/* <p className='mb-0 text-end mt-8'>
                              {this.state.orderNote !== '' && (
                                <button
                                  className='submit-btn btn btn-danger me-4'
                                  onClick={this.handleOrderNoteFormClr}
                                  type='button'
                                >
                                  Clear
                                </button>
                              )}
                              {this.state.orderNoteLoading ? (
                                <BtnLoading
                                  name='Loading...'
                                  btnClass='primary submit-btn'
                                />
                              ) : (
                                <button className='submit-btn btn btn-primary'>
                                  {this.state.orderNoteId
                                    ? 'Update Note'
                                    : 'Add Note'}
                                </button>
                              )}
                            </p> */}
                          </form>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>Order Notes: </p>
                          <div className='notes-all'>
                            <p className='m-0 fs-6'>{this.state.orderNote}</p>
                          </div>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>ORDER NoteS</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Notes Section================== */}

                  {/* ==================Patients Section================== */}
                  <div
                    id='patient-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='mb-7'>
                                {!this.state.isLabPartner &&
                                  !this.state.isqOrder && (
                                    <>
                                      <p className='item-title'>
                                        Select User *
                                      </p>
                                      <SelectUser
                                        setUserDetails={this.setUserDetails}
                                        setUserPatients={this.setUserPatients}
                                        setHaveOrder={this.setHaveOrder}
                                        setMobile={this.setMobile}
                                        editId={null}
                                        setUserOldAddress={
                                          this.setUserOldAddress
                                        }
                                        setPreviousAddresses={this.setPreviousAddresses}
                                        setSelectedPreviousAddress={this.setSelectedPreviousAddress}
                                        setIsLabPartner={this.setIsLabPartner}
                                        qOrderUser={userPatients}
                                      />
                                    </>
                                  )}
                                {!this.state.isLabPartner && (
                                  <p className='mb-4 pl-2 mt-10 fw-bold'>Or</p>
                                )}
                              </div>
                            </div>
                            {!this.state.userDetails ? (
                              <>
                                <div className='col-md-6 col-6in7-l'>
                                  <div className='mb-7'>
                                    <p className='item-title'>User Fullame *</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='Enter user fullname'
                                      value={this.state.fullname}
                                      onChange={(e) =>
                                        this.setState({
                                          fullname: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className='mb-7'>
                                    <p className='item-title'>User Number *</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='Enter user phone'
                                      value={this.state.username}
                                      onChange={(e) =>
                                        this.setState({
                                          username: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className=''>
                                    <p className='item-title'>User Gender *</p>
                                    <select
                                      className='form-select form-select-sloid'
                                      value={this.state.sex}
                                      onChange={(e) =>
                                        this.setState({
                                          sex: e.target.value,
                                        })
                                      }
                                    >
                                      <option value=''>Select Gender</option>
                                      <option value='male'>Male</option>
                                      <option value='female'>Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-md-6 col-6in7-r'>
                                  <div className='mb-3'>
                                    <p className='item-title'>
                                      Date Of Birth *
                                    </p>
                                    <br />
                                    <PatientDob
                                      placeholder='DD/MM/YY'
                                      patientDob={this.state.dob}
                                      setPatientDob={this.setUserDob}
                                    />
                                  </div>

                                  <div className='mb-17'>
                                    <p className='item-title'>User Email</p>
                                    <input
                                      type='email'
                                      className='form-control form-control-solid'
                                      placeholder='Enter user email'
                                      value={this.state.email}
                                      onChange={(e) =>
                                        this.setState({
                                          email: e.target.value,
                                        })
                                      }
                                    />
                                  </div>

                                  <p className='mb-0 text-end mt-9'>
                                    <div className='d-flex justify-content-end'>
                                      <button
                                        className='me-3 pe-5 btn btn-danger'
                                        onClick={this.handleUserFormClr}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                      {this.state.userCreateLoading ? (
                                        <BtnLoading
                                          name='Adding'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <button
                                          className='submit-btn btn btn-primary'
                                          onClick={this.handleUserCreate}
                                        >
                                          Add User
                                        </button>
                                      )}
                                    </div>
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='col-md-6 col-6in7-l'>
                                  <div className='mb-7'>
                                    <p className='item-title'>Patient Name *</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='Patient Name'
                                      value={this.state.patientName}
                                      onChange={(e) =>
                                        this.setState({
                                          patientName: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div className=''>
                                    <p className='item-title'>Gender *</p>
                                    <select
                                      className='form-select form-select-sloid'
                                      value={this.state.patientGender}
                                      onChange={(e) =>
                                        this.setState({
                                          patientGender: e.target.value,
                                        })
                                      }
                                    >
                                      <option value=''>Select Gender</option>
                                      <option value='male'>Male</option>
                                      <option value='female'>Female</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-md-6 col-6in7-r row'>
                                  <div className='mb-13'>
                                    <p className='item-title'>
                                      Date Of Birth *
                                    </p>
                                    <br />
                                    <PatientDob
                                      placeholder='DD/MM/YY'
                                      patientDob={this.state.patientDob}
                                      setPatientDob={this.setPatientDob}
                                    />
                                  </div>

                                  <div className='mb-3 col-md-6'>
                                    <p className='item-title'>Weight (KG.)</p>
                                    <input
                                      type='number'
                                      step='0.1'
                                      className='form-control form-control-solid'
                                      placeholder='Patient Height'
                                      value={this.state.patientWeight}
                                      onChange={(e) =>
                                        this.setState({
                                          patientWeight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>


                                  <div className='mb-3 col-md-6'>
                                    <p className='item-title'>Height (Ft.)</p>
                                    <input
                                      type='number'
                                      step='0.1'
                                      className='form-control form-control-solid'
                                      placeholder='Patient Height'
                                      value={this.state.patientHeight}
                                      onChange={(e) =>
                                        this.setState({
                                          patientHeight: e.target.value,
                                        })
                                      }
                                    />
                                  </div>



                                  <p className='mb-0 text-end mt-9'>
                                    {this.state.patientId ? (
                                      <div className='d-flex justify-content-end'>
                                        <button
                                          className='me-3 pe-5 btn btn-danger'
                                          onClick={this.handlePatientFormClr}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </button>
                                        {this.state.patientLoading ? (
                                          <BtnLoading
                                            name='Updating'
                                            btnClass='success submit-btn'
                                          />
                                        ) : (
                                          <button
                                            className='submit-btn btn btn-success'
                                            onClick={this.handlePatientUpdate}
                                          >
                                            Update
                                          </button>
                                        )}
                                      </div>
                                    ) : (
                                      <div className='d-flex justify-content-end'>
                                        <button
                                          className='me-3 pe-5 btn btn-danger'
                                          onClick={this.handlePatientFormClr}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </button>
                                        {this.state.patientLoading ? (
                                          <BtnLoading
                                            name='Adding'
                                            btnClass='primary submit-btn'
                                          />
                                        ) : (
                                          <button
                                            className='me-3 submit-btn pe-5 btn btn-primary'
                                            onClick={this.handlePatientUpdate}
                                          >
                                            Add Patient
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </p>



                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>All Patients: </p>
                          <div className='items'>
                            {userPatients &&
                              userPatients.reverse().map((patient, i) => (
                                <p
                                  key={i}
                                  className={`edit-option loop ${patient.is_account ? 'user' : ''
                                    }`}
                                >
                                  <div>
                                    <p className='name mb-2 text-primary fw-bold'>
                                      {patient.full_name}
                                    </p>
                                    <p className='m-0'>
                                      Age:{' '}
                                      {moment().diff(
                                        `${patient.dob}`,
                                        'years'
                                      ) + ' Years'}
                                    </p>
                                    <p className='m-0 text-capitalize'>
                                      Sex: {patient.sex}
                                    </p>

                                    {patient.patient_info && patient.patient_info.weight ? (
                                      <p className='m-0 text-capitalize'>Weight: {patient.patient_info.weight} KG.</p>
                                    ) : (
                                      <>&nbsp;</>
                                    )}

                                    {patient.patient_info && patient.patient_info.height ? (
                                      <p className='m-0 text-capitalize'>Height: {patient.patient_info.height} Ft.</p>
                                    ) : (
                                      <>&nbsp;</>
                                    )}
                                  </div>
                                  <i
                                    className='far fa-edit edit'
                                    onClick={() =>
                                      this.handlePatientEdit(patient)
                                    }
                                  ></i>
                                  {!patient.is_account && (
                                    <i
                                      className='far fa-trash-alt'
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            'Are you sure to delete this patient?'
                                          )
                                        )
                                          this.handlePatientDelete(patient);
                                      }}
                                    ></i>
                                  )}
                                </p>
                              ))}
                          </div>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>patient info</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Patients Section================== */}

                  {/* ==================Schedule Section================== */}
                  <div
                    id='schedule-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-md-6 col-6in7-l'>
                              <div className='mb-2'>
                                <p className='item-title'>
                                  Sample Collection Date *
                                </p>
                                <DateSelect
                                  placeholderText='DD/MM/YY'
                                  sampleDate={this.state.sampleDate}
                                  setSampleDate={this.setSampleDate}
                                />
                              </div>
                            </div>
                            <div className='col-md-6 col-6in7-r'>
                              <div className=''>
                                <p className='item-title'>
                                  Sample Collection Slot *
                                </p>
                                <TimeSelect
                                  placeholderText={
                                    this.state.timeSelectPlaceholder
                                  }
                                  sampleTime={this.state.sampleTime}
                                  setSampleTime={this.setSampleTime}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>Overview: </p>
                          <p className='m-0 edit-option'>
                            <p className='mb-2'>
                              <span className='fw-bold'>Date: </span>
                              {this.state.sampleDate &&
                                moment(this.state.sampleDate).format(
                                  'DD MMM YYYY'
                                )}
                              <br />
                              <span className='fw-bold'>Time: </span>
                              {this.state.sampleTime && (
                                <>
                                  {moment(this.state.sampleTime, [
                                    'HH:mm:ss',
                                  ]).format('hh:mm A')}{' '}
                                  -{' '}
                                  {moment(this.state.sampleTime, 'HH:mm:ss')
                                    .add(120, 'minutes')
                                    .format('hh:mm A')}
                                </>
                              )}
                            </p>
                            {/* <i
                              className='far fa-edit'
                              onClick={this.handleAddressI}
                            ></i> */}
                          </p>

                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>Schedule</p>
                        </div>
                      </div>
                    </div>
                  </div>


                  {/* ==================Collection Section================== */}
                  <div
                    id='collection-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-md-6 col-6in7-l'>
                              <div className='mb-7'>
                                <p className='item-title'>Area *</p>
                                <select
                                  className='form-select form-select-sloid'
                                  onChange={this.handleDistrict}
                                  value={this.state.new_district}
                                >
                                  <option value=''>Select Area</option>
                                  <option value='Dhaka'>Dhaka</option>
                                  {/* <option value='Savar'>Savar</option>
                                  <option value='Chattogram'>Chattogram</option> */}
                                </select>
                              </div>
                              <div className='mb-7'>
                                <p className='item-title'>Location details *</p>
                                <textarea
                                  className='form-control'
                                  rows='6'
                                  placeholder='Enter details'
                                  value={this.state.new_address}
                                  onChange={(e) =>
                                    this.setState({
                                      // address: e.target.value,
                                      new_address: e.target.value,
                                    })
                                  }
                                  maxLength={990}
                                ></textarea>
                              </div>
                            </div>
                            <div className='col-md-6 col-6in7-r'>
                              <div className='mb-7'>
                                <p className='item-title'>Thana *</p>
                                <select
                                  onChange={(e) =>
                                    this.setState({
                                      // thana: e.target.value,
                                      new_thana: e.target.value,
                                    })
                                  }
                                  value={this.state.new_thana}
                                  className='form-select form-select-sloid'
                                >
                                  <option value=''>Select Thana</option>
                                  {this.state.locationLoopItem.map(
                                    (area, i) => (
                                      <option value={area} key={i}>
                                        {area}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className='mb-7 mt-2'>
                                <p className='item-title'>Collection Phone *</p>
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder='sample collection number'
                                  value={this.state.new_mobile}
                                  onChange={(e) =>
                                    this.setState({
                                      // mobile: e.target.value,
                                      new_mobile: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className='mb-7 mt-2'>
                                <p className='item-title'>Collection Email</p>
                                <input
                                  type='email'
                                  className='form-control form-control-solid'
                                  placeholder='sample collection email'
                                  value={this.state.new_addEmail}
                                  onChange={(e) =>
                                    this.setState({
                                      // addEmail: e.target.value,
                                      new_addEmail: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className='mb-7'>
                                <p className='mb-0 text-end mt-7'>
                                  {this.state.new_addId ? (
                                    <div className='d-flex justify-content-end'>
                                      <button
                                        className='me-3 pe-5 btn btn-danger'
                                        onClick={this.clearAddressForm}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                      {this.state.addressLoading ? (
                                        <BtnLoading
                                          name='Updating'
                                          btnClass='success submit-btn'
                                        />
                                      ) : (
                                        <button
                                          className='submit-btn btn btn-success'
                                          onClick={() => this.handleAddressUpdateNew('put')}
                                        >
                                          Update
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-end'>
                                      <button
                                        className='me-3 pe-5 btn btn-danger'
                                        onClick={this.clearAddressForm}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                      {this.state.addressLoading ? (
                                        <BtnLoading
                                          name='Adding'
                                          btnClass='primary submit-btn'
                                        />
                                      ) : (
                                        <button
                                          className='me-3 submit-btn pe-5 btn btn-primary'
                                          onClick={() => this.handleAddressUpdateNew('post')}
                                        >
                                          Add Address
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item add-item'>
                          <p className='item-title'>Overview: </p>
                          {previousAddresses && previousAddresses.length > 0 ?
                            previousAddresses.slice(0, 4).map((prevAddrs, idx) => (
                              <p
                                onClick={() => this.handlePrevAddrsClick(idx)}
                                className={`m-0 mb-4 edit-option prev-addrs${(addId && selectedPreviousAddress === idx) ? ` selectedAddrs` : ''}`}>
                                <div className={`d-flex flex-row${selectedPreviousAddress === idx ? ` me-12` : ``}`}>
                                  <p className='fw-bold text-primary mb-2'>
                                    {/* {this.state.district}, {this.state.thana} */}
                                    {prevAddrs.district ? prevAddrs.district : ''}, {prevAddrs.thana ? prevAddrs.thana : ''}
                                  </p>
                                  {(selectedPreviousAddress === idx) && <i class="far fa-check-square selected"></i>}
                                  <i
                                    className='far fa-edit edit'
                                    onClick={(e) => { e.stopPropagation(); this.handlePrevAddrsEditClick(idx); }}
                                  ></i>
                                  <i
                                    className='far fa-trash-alt'
                                    disabled={prevAddrs.id === this.state.addressDeletingId}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Are you sure to delete this address?'
                                        )
                                      ) { this.deleteAddress(prevAddrs.id) }
                                    }}
                                  ></i>
                                </div>
                                <p className='mb-2'>
                                  <span className='fw-bold'>Details: </span>
                                  {/* {this.state.address} */}
                                  {prevAddrs.address
                                    ? prevAddrs.address
                                    : ''}
                                </p>
                                {/* <p className='mb-2'>
                                  <span className='fw-bold'>Date: </span>
                                  {this.state.sampleDate &&
                                    moment(this.state.sampleDate).format(
                                      'DD MMM YYYY'
                                    )}
                                  <br />
                                  <span className='fw-bold'>Time: </span>
                                  {this.state.sampleTime && (
                                    <>
                                      {moment(this.state.sampleTime, [
                                        'HH:mm:ss',
                                      ]).format('hh:mm A')}{' '}
                                      -{' '}
                                      {moment(this.state.sampleTime, 'HH:mm:ss')
                                        .add(120, 'minutes')
                                        .format('hh:mm A')}
                                    </>
                                  )}
                                </p> */}
                                <p className='m-0'>
                                  <span className='fw-bold'>Phone: </span>
                                  {/* {this.state.mobile} */}
                                  {prevAddrs.mobile
                                    ? prevAddrs.mobile
                                    : ''}
                                  <br />
                                  <span className='fw-bold'>Email: </span>
                                  {/* {this.state.addEmail} */}
                                  {prevAddrs.email
                                    ? prevAddrs.email
                                    : ''}
                                </p>


                              </p>
                            ))
                            :
                            <p className='m-0 edit-option'>
                              <p className='fw-bold text-primary mb-2'>
                                {this.state.district}, {this.state.thana}
                              </p>
                              <p className='mb-2'>
                                <span className='fw-bold'>Details: </span>
                                {this.state.address}
                              </p>
                              <p className='m-0'>
                                <span className='fw-bold'>Phone: </span>
                                {this.state.mobile}
                                <br />
                                <span className='fw-bold'>Email: </span>
                                {this.state.addEmail}
                              </p>
                              {/* <i
                              className='far fa-edit'
                              onClick={this.handleAddressI}
                            ></i> */}
                            </p>

                          }
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>collection info</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Collection Section================== */}

                  {/* ==================Order Summery Section================== */}
                  <div
                    id='order-summery-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-11'>
                          <table className='mb-0 table table-row-dashed table-row-gray-300 gy-7'>
                            <thead>
                              <tr className='fw-bolder fs-6 text-gray-600'>
                                <th className='min-w-200px'>PATIENT INFO.</th>
                                <th className='min-w-200px'>
                                  DIAGNOSTIC INFO.
                                </th>
                                <th className='min-w-20px'>
                                  &nbsp;
                                </th>
                                <th className='min-w-150px'>
                                  &nbsp;
                                </th>
                                <th className='min-w-75px'>PRICE</th>
                                <th className='min-w-150px'>ORDERED FROM </th>
                                <th className='min-w-50px'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {testCarts.length !== 0 &&
                                testCarts.map((item, i) => (
                                  <SingleOrderItem
                                    key={i}
                                    item={item}
                                    handleTestItemDelete={
                                      this.handleTestItemDelete
                                    }
                                  />
                                ))}
                            </tbody>
                          </table>
                          <div
                            className='row border-bottom-dashed border-top-dashed border-gray-300 gy-1 py-4'
                            style={{ borderWidth: '1px' }}
                          >
                            <div className='col-md-8'>
                              <p className='mb-2 text-end fs-6'>
                                <span className='fw-bold'>Total Tests: </span>
                                {testCarts.length}
                              </p>
                              <p className='mb-2 text-end fs-6'>
                                <span className='fw-bold'>
                                  Diagnostic Price:{' '}
                                </span>
                                {this.getTotalPrice(testCarts)}
                              </p>
                              <p className='mb-2 text-end fs-6'>
                                <span className='fw-bold'>
                                  Collection Fee:{' '}
                                </span>
                                {/* ৳{parseInt(order.collection_fee) || '00'} */}{' '}
                                {testCarts.length === 0 ? (
                                  '0'
                                ) : (
                                  <span>
                                    ৳{' '}
                                    {this.state.fees &&
                                      this.state.fees.collection_fee}
                                  </span>
                                )}
                              </p>
                              <p className='m-0 text-end fs-6'>
                                <span className='fw-bold'>Material Fee: </span>
                                {/* {parseInt(order.material_fee) || '00'} */}{' '}
                                {this.state.fees &&
                                  this.state.fees.meterial_fee *
                                  this.state.materialPerson}
                              </p>
                            </div>
                            <div className='col-md-5'></div>
                          </div>
                          <div className='py-4 row'>
                            <div className='col-md-8'>
                              <p className='mb-2 text-end fs-6'>
                                <span className='fw-bold'>Total: </span>৳
                                {testCarts.length === 0 ? (
                                  '0'
                                ) : (
                                  <span>
                                    {this.getTotalPriceWithFees(testCarts)}
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className='col-md-4 align-self-end'>
                              <p className='mb-2 text-end'>
                                <button
                                  className='submit-btn btn btn-primary'
                                  // disabled={
                                  //   this.state.userDetails === null
                                  //     ? 'disabled'
                                  //     : ''
                                  // }
                                  onClick={this.handleTestItemModal}
                                >
                                  Add New Test
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>order summery</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Order Summery Section================== */}

                  {/* ==================Payment Section================== */}
                  <div
                    id='payment-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-md-6 col-6in7-l'>
                              <div className='mb-7'>
                                <p className='item-title'>Payment Status *</p>
                                <select
                                  className='form-select form-select-sloid'
                                  value={this.state.paymentStatus}
                                  onChange={(e) =>
                                    this.setState({
                                      paymentStatus: e.target.value,
                                    })
                                  }
                                >
                                  <option value=''>Payment Status</option>
                                  <option value='paid'>Paid</option>
                                  <option value='unpaid'>Unpaid</option>
                                </select>
                              </div>
                              <div className='mb-7'>
                                <p className='item-title'>Payment Mode</p>
                                <select
                                  className='form-select form-select-sloid'
                                  value={this.state.paymentSource}
                                  onChange={(e) =>
                                    this.setState({
                                      paymentSource: e.target.value,
                                    })
                                  }
                                >
                                  <option value='cod'>Cash On Delivery</option>
                                  <option value='sslc'>SSL Commerze</option>
                                  <option value='bkash'>Bkash</option>
                                </select>
                              </div>
                            </div>
                            <div className='col-md-6 col-6in7-r'>
                              <div className=''>
                                <p className='item-title'>Payment Note</p>
                                <textarea
                                  className='form-control'
                                  rows='6'
                                  placeholder='Payment Note'
                                  value={this.state.paymentNote}
                                  onChange={(e) =>
                                    this.setState({
                                      paymentNote: e.target.value,
                                    })
                                  }
                                  maxLength={990}
                                ></textarea>
                              </div>
                              {/* <p className='mb-0 text-end mt-9'>
                                {this.state.paymentLoading ? (
                                  <BtnLoading
                                    name='updating'
                                    btnClass='primary submit-btn'
                                  />
                                ) : (
                                  <button
                                    className='submit-btn btn btn-primary'
                                    disabled={
                                      this.state.paymentEdit ? '' : 'disabled'
                                    }
                                    onClick={this.handlePaymentInfo}
                                  >
                                    Update
                                  </button>
                                )}
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>Payment Overview: </p>
                          <p className='m-0 edit-option'>
                            <p className='fw-bold text-danger mb-2 text-uppercase'>
                              {this.state.paymentStatus}
                            </p>
                            <p className='mb-2'>
                              <span className='fw-bold'>Amount: </span>৳
                              {testCarts.length === 0 ? (
                                '0'
                              ) : (
                                <span>
                                  {this.getTotalPriceWithFees(testCarts)}
                                </span>
                              )}
                            </p>
                            <p className='m-0'>
                              <span className='fw-bold'>Note: </span>
                              {this.state.paymentNote}
                            </p>
                            {/* <i
                              className='far fa-edit'
                              onClick={this.handlePaymentEdit}
                            ></i> */}
                          </p>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>payment info</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Payment Section================== */}

                  {/* ==================Discount Section================== */}
                  <div
                    id='discount-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        {!this.state.isLabPartner && (
                          <div className='col-md-7 padd-fir-item'>
                            <div className='row'>
                              <div className='col-md-6 col-6in7-l'>
                                <div className='mb-7'>
                                  <p className='item-title'>Discount Type</p>
                                  <select
                                    className='form-select form-select-sloid'
                                    value={this.state.orderDiscountSource}
                                    onChange={this.handleOrderDiscountSource}
                                    disabled={
                                      this.state.testCarts.length !== 0
                                        ? ''
                                        : 'disabled'
                                    }
                                  >
                                    <option value=''>Select Type</option>
                                    <option value='admin'>Custom</option>
                                    <option value='collection_fee'>
                                      Collection Fee
                                    </option>
                                    <option value='material_fee'>
                                      Material Fee
                                    </option>
                                    <option value='fee'>
                                      Fee (Collection + Material)
                                    </option>
                                    <option value='fee_discount'>
                                      Fee (Collection + Material + Custom)
                                    </option>
                                  </select>
                                </div>
                                {orderDiscountSource === '' ||
                                  orderDiscountSource === null ||
                                  orderDiscountSource === 'admin' ||
                                  orderDiscountSource === 'cupon' ? (
                                  <div className='mb-7'>
                                    <p className='item-title'>Rate</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='discount rate'
                                      value={this.state.orderDiscountPrice}
                                      onChange={(e) =>
                                        this.setState({
                                          orderDiscountPrice: e.target.value,
                                        })
                                      }
                                      disabled={
                                        orderDiscountSource === ''
                                          ? 'disabled'
                                          : ''
                                      }
                                      min={
                                        orderDiscountSource === 'cupon'
                                          ? this.state.orderDiscountPrice
                                          : 0
                                      }
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {orderDiscountSource === 'fee_discount' ? (
                                  <input
                                    type='number'
                                    className='form-control mb-5'
                                    placeholder='Discount Rate'
                                    value={this.state.orderDiscountCustom || ''}
                                    onChange={(e) => {
                                      setTimeout(() => {
                                        this.setDiscountPriceCustom();
                                      }, 500);
                                      this.setState({
                                        orderDiscountCustom: e.target.value,
                                      });
                                    }}
                                    min={
                                      orderDiscountSource === 'cupon'
                                        ? this.state.orderDiscountCustom
                                        : 0
                                    }
                                  />
                                ) : (
                                  ''
                                )}

                                {orderDiscountSource === 'collection_fee' ||
                                  orderDiscountSource === 'fee' ||
                                  orderDiscountSource === 'fee_discount' ? (
                                  <input
                                    type='number'
                                    className='form-control mb-5'
                                    placeholder='Collection Fee'
                                    value={this.state.orderDiscountColl || ''}
                                    onChange={(e) => {
                                      setTimeout(() => {
                                        this.setDiscountPrice();
                                      }, 500);
                                      this.setState({
                                        orderDiscountColl: e.target.value,
                                        orderDiscountCollChangedOnce: true,
                                      });
                                    }}
                                  // disabled={this.state.discountSource === 'collection_fee'}
                                  />
                                ) : (
                                  ''
                                )}

                                {orderDiscountSource === 'material_fee' ||
                                  orderDiscountSource === 'fee' ||
                                  orderDiscountSource === 'fee_discount' ? (
                                  <input
                                    type='number'
                                    className='form-control mb-5'
                                    placeholder='Material Fee'
                                    value={this.state.orderDiscountMate || ''}
                                    onChange={(e) => {
                                      setTimeout(() => {
                                        this.setDiscountPrice();
                                      }, 500);
                                      this.setState({
                                        orderDiscountMate: e.target.value,
                                      });
                                    }}
                                  // disabled={this.state.discountSource === 'material_fee'}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className='col-md-6 col-6in7-r'>
                                {orderDiscountSource === 'fee_discount' ? (
                                  <div className='mb-7'>
                                    <p className='item-title'>
                                      Discount Material
                                    </p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='discount material'
                                      value={this.state.orderDiscountMate}
                                      onChange={(e) =>
                                        this.setState({
                                          orderDiscountMate: e.target.value,
                                        })
                                      }
                                      disabled={
                                        this.state.orderDiscountEdit
                                          ? ''
                                          : 'disabled'
                                      }
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div className=''>
                                  <p className='item-title'> Note</p>
                                  <textarea
                                    className='form-control'
                                    rows='6'
                                    placeholder='discount note'
                                    value={this.state.orderDiscountNote}
                                    onChange={(e) =>
                                      this.setState({
                                        orderDiscountNote: e.target.value,
                                      })
                                    }
                                    maxLength={990}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div
                          className={`${!this.state.isLabPartner
                            ? 'col-md-4 padd-last-item'
                            : 'col-11'
                            }`}
                        >
                          <p className='item-title'>Discount Overview: </p>
                          <p className='m-0 edit-option'>
                            <p className='fw-bold text-danger mb-2 text-uppercase'>
                              {/* {order.orderdiscount &&
                                order.orderdiscount.discount_source.replace(
                                  /_/g,
                                  ' '
                                )} */}
                            </p>
                            <p className='mb-2'>
                              <span className='fw-bold'>Total Price: </span>৳
                              {this.getTotalPrice(testCarts)}
                            </p>
                            <p className='mb-2'>
                              <span className='fw-bold'>Collection Fee: </span>৳
                              {testCarts.length === 0 ? (
                                '0'
                              ) : (
                                <>
                                  ৳{' '}
                                  {this.state.fees &&
                                    this.state.fees.collection_fee}
                                </>
                              )}
                            </p>
                            <p className='mb-2'>
                              <span className='fw-bold'>Material Fee: </span>৳
                              {this.state.fees &&
                                this.state.fees.meterial_fee *
                                this.state.materialPerson}
                            </p>

                            <p
                              className='m-0 pt-2 border-top-dashed border-gray-300'
                              style={{ borderWidth: '1px' }}
                            ></p>

                            {orderDiscountSource === '' ||
                              orderDiscountSource === 'cupon' ||
                              orderDiscountSource === 'admin' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>
                                  {this.state.isLabPartner
                                    ? 'Lab Discount'
                                    : 'Discount'}
                                  :
                                </span>{' '}
                                ৳ - {this.state.orderDiscountPrice || '0'}
                              </p>
                            ) : (
                              ''
                            )}
                            {orderDiscountSource === 'fee_discount' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>Discount</span>- ৳{' '}
                                {this.state.orderDiscountCustom || '0'}
                              </p>
                            ) : (
                              ''
                            )}
                            {orderDiscountSource === 'collection_fee' ||
                              orderDiscountSource === 'fee_discount' ||
                              orderDiscountSource === 'fee' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>
                                  Collection Discount:{' '}
                                </span>
                                ৳ -{this.state.orderDiscountColl || '0'}
                              </p>
                            ) : (
                              ''
                            )}
                            {orderDiscountSource === 'material_fee' ||
                              orderDiscountSource === 'fee_discount' ||
                              orderDiscountSource === 'fee' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>
                                  Material Discount:{' '}
                                </span>
                                ৳ - {this.state.orderDiscountMate || '0'}
                              </p>
                            ) : (
                              ''
                            )}
                            <p
                              className='m-0 pt-2 border-top-dashed border-gray-300'
                              style={{ borderWidth: '1px' }}
                            >
                              <span className='fw-bold'>Total Payable: </span>৳
                              {this.state.testCarts.length === 0 ? (
                                '0'
                              ) : (
                                <span>
                                  {this.getTotalPriceWithFeesDiscount(
                                    this.state.testCarts
                                  )}
                                </span>
                              )}
                            </p>
                            {/* <i
                              className='far fa-edit'
                              onClick={this.handleOrderDiscountEdit}
                            ></i> */}
                          </p>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>discount info</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Discount Section================== */}
                  {/* ==================Sneho Section================== */}

                  <div
                    id='report-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                      <div className='col-md-7 padd-fir-item'>
                        <div className="row">
                          <div className="col-md-6 col-6in7-l">
                            <div className='cro-mt'>
                               <p className='fw-bold'>From Sneho</p>
                               <div style={{height: '52px'}} className='form-check form-switch form-check-custom form-check-solid'>
                                 <input
                                   type='checkbox'
                                   name='from_sneho'
                                   onChange={() =>
                                     this.setState((prevState) => ({
                                       from_sneho: !prevState.from_sneho,
                                     }))
                                   }
                                   checked={this.state.from_sneho}
                                   className='form-check-input w-50px'
                                 />
                               </div>
                           </div>
                           {this.state.from_sneho && (
                           <div className='cro-mt'>
                               <p className='fw-bold'>Sneho Prescription ID *</p>
                               <input
                               style={{
                                 height: '52px',
                                 top: '-2px',
                                 position: 'relative',
                               }}
                                 type='number'
                                 className='form-control form-control-solid'
                                 placeholder='Sneho Prescription ID *'
                                 value={this.state.sneho_prescription_id}
                                 onChange={(e) =>
                                   this.setState({
                                     sneho_prescription_id: e.target.value,
                                   })
                                 }
                               />
                           </div>
                           )}
                         </div>
                        </div>
                       </div>
                       <div className="col-md-4 padd-last-item">
                        &nbsp;
                       </div>
                       <div class="col-md-1 section-name-right"><p class="name-vertical">Sneho</p></div>
                     </div>
                    </div>
                  </div>
                  {/* ==================End Sneho Section================== */}
                  {/* ==================Report Overview Section================== */}
                  <div
                    id='report-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-7 padd-fir-item'>
                          <div className='row'>
                            <div className='col-md-6 col-6in7-l'>
                              <div className='mb-7'>
                                <p className='item-title'>Report Type</p>
                                <select
                                  className='form-select form-select-sloid'
                                  value={this.state.serviceStatus}
                                  onChange={(e) => {
                                    this.setState({
                                      serviceStatus: e.target.value,
                                    });
                                    setTimeout(() => {
                                      this.getFees();
                                    }, 200);

                                    if (
                                      this.state.orderDiscountSource === 'collection_fee' ||
                                      this.state.orderDiscountSource === 'fee' ||
                                      this.state.orderDiscountSource === 'fee_discount'
                                    ) {
                                      if (this.state.orderDiscountCollChangedOnce) {
                                        this.setState({
                                          orderDiscountColl: Math.min(
                                            this.state.orderDiscountColl,
                                            e.target.value === 'soft'
                                              ? this.state.feesApi.collection_fee
                                              : (this.state.feesApi.collection_fee + this.state.feesApi.delivery_fee)
                                          ),
                                        })
                                      } else {
                                        this.setState({
                                          orderDiscountColl: e.target.value === 'soft'
                                            ? this.state.feesApi.collection_fee
                                            : (this.state.feesApi.collection_fee + this.state.feesApi.delivery_fee)
                                        })
                                      }

                                    } else {
                                      this.setState({
                                        orderDiscountColl: 0
                                      })
                                    }

                                  }}
                                >
                                  <option value='both'>Both Copy</option>
                                  <option value='hard'>Hard Copy</option>
                                  <option value='soft'>Soft Copy</option>
                                </select>
                              </div>

                              {this.state.serviceStatus === 'soft' ||
                                this.state.serviceStatus === 'both' ? (
                                <div className='mb-7'>
                                  <p className='item-title'>
                                    Report Status (Soft)
                                  </p>
                                  <select
                                    className='form-select form-select-sloid'
                                    value={this.state.reportStatusSoft}
                                    onChange={(e) =>
                                      this.setState({
                                        reportStatusSoft: e.target.value,
                                      })
                                    }
                                  >
                                    <option value=''>Select Copy</option>
                                    <option value='no_soft_copy'>
                                      No Soft Copy
                                    </option>
                                    <option value='soft_copy_sent'>
                                      Soft Copy Sent
                                    </option>
                                    <option value='soft_copy_due'>
                                      Soft Copy Due
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                ''
                              )}

                              {this.state.serviceStatus === 'hard' ||
                                this.state.serviceStatus === 'both' ? (
                                <div className=''>
                                  <p className='item-title'>
                                    Report Status (Hard)
                                  </p>
                                  <select
                                    className='form-select form-select-sloid'
                                    value={this.state.reportStatusHard}
                                    onChange={(e) =>
                                      this.setState({
                                        reportStatusHard: e.target.value,
                                      })
                                    }
                                  >
                                    <option value=''>Select Copy</option>
                                    <option value='no_hard_copy'>
                                      No Hard Copy
                                    </option>
                                    <option value='hard_copy_sent'>
                                      Hard Copy Sent
                                    </option>
                                    <option value='hard_copy_due'>
                                      Hard Copy Due
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                ''
                              )}

                              {/* <div className='mb-7'>
                                      <p className='item-title'>
                                        Set Notification Schedule
                                      </p>
                                      <select
                                        disabled={
                                          this.state.reportOverviewEdit
                                            ? ''
                                            : 'disabled'
                                        }
                                        className='form-select form-select-sloid'
                                      >
                                        <option value=''>
                                          Select Date & time
                                        </option>
                                      </select>
                                    </div> */}
                            </div>
                            <div className='col-md-6 col-6in7-r'>
                              {this.state.serviceStatus === 'soft' ||
                                this.state.serviceStatus === 'both' ? (
                                <div className='mb-7'>
                                  <p className='item-title'>Note (Soft)</p>
                                  <textarea
                                    className='form-control'
                                    rows='3'
                                    placeholder=''
                                    value={this.state.reportSoftNote}
                                    onChange={(e) =>
                                      this.setState({
                                        reportSoftNote: e.target.value,
                                      })
                                    }
                                    maxLength={990}
                                  ></textarea>
                                </div>
                              ) : (
                                ''
                              )}
                              {this.state.serviceStatus === 'hard' ||
                                this.state.serviceStatus === 'both' ? (
                                <div className=''>
                                  <p className='item-title'>Note (Hard)</p>
                                  <textarea
                                    className='form-control'
                                    rows='3'
                                    placeholder=''
                                    maxLength={990}
                                    value={this.state.reportHardNote}
                                    onChange={(e) =>
                                      this.setState({
                                        reportHardNote: e.target.value,
                                      })
                                    }
                                  ></textarea>
                                </div>
                              ) : (
                                ''
                              )}
                              {/* <p className='mb-0 text-end mt-9'>
                                <button
                                  onClick={this.updateReportOverview}
                                  className='submit-btn btn btn-primary'
                                >
                                  Update
                                </button>
                              </p> */}
                            </div>
                          </div>
                        </div>
                        <div className='col-md-4 padd-last-item'>
                          <p className='item-title'>Report Overview: </p>
                          <p className='m-0 edit-option'>
                            <p className='fw-bold text-danger mb-2 text-uppercase'>
                              {this.state.serviceStatus} Copy
                            </p>
                            {this.state.serviceStatus === 'soft' ||
                              this.state.serviceStatus === 'both' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>Note (Soft): </span>
                                {this.state.reportSoftNote}
                              </p>
                            ) : (
                              ''
                            )}
                            {this.state.serviceStatus === 'hard' ||
                              this.state.serviceStatus === 'both' ? (
                              <p className='mb-2'>
                                <span className='fw-bold'>Note (Hard): </span>
                                {this.state.reportHardNote}
                              </p>
                            ) : (
                              ''
                            )}
                            {/* <i
                              className='far fa-edit'
                              onClick={this.handleReportOverviewI}
                            ></i> */}
                          </p>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>report overview</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Report Overview Section================== */}

                  {/* ==================Report Management Section================== */}
                  <div
                    id='report-info-nav'
                    className='card card-custom card-stretch gutter-b mt-7'
                  >
                    <div className='card-body py-2 my-6'>
                      <div className='row'>
                        <div className='col-md-11'>
                          <div className='row'>
                            <div className='col-md-8 padd-fir-item report-left'>
                              <div className='row'>
                                <div className='col-md-6 col-6in7-l'>
                                  <div className='mb-7'>
                                    <p className='item-title'>Select Patient</p>
                                    <select
                                      className='form-select form-select-sloid'
                                      value={this.state.orderReportPatientName}
                                      onChange={this.handleOrderReportPatient}
                                      disabled
                                    >
                                      <option value=''>Select Patient</option>
                                      {this.state.orderAllPatients.map(
                                        (patient, i) => (
                                          <option
                                            value={patient.id}
                                            key={patient.id + i}
                                          >
                                            {patient.full_name}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                  <div className='mb-7  file-patient'>
                                    <p className='item-title'>
                                      Select Diagnostic
                                    </p>
                                    {/* <SelectTests
                                      allTests={this.state.orderAllTests}
                                      setOrderReportTests={
                                        this.setOrderReportTests
                                      }
                                      orderTests={
                                        this.state.orderReportTestName
                                      }
                                    /> */}
                                    <select
                                      className='form-select form-select-sloid'
                                      value={this.state.orderReportPatientName}
                                      onChange={this.handleOrderReportPatient}
                                      disabled
                                    >
                                      <option value=''>Select Tests</option>
                                    </select>
                                  </div>
                                </div>
                                <div className='col-md-6 col-6in7-r'>
                                  <div className='mb-7'>
                                    <p className='item-title'>Report Name</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid mb-5'
                                      placeholder='Report name'
                                      value={this.state.orderReportName}
                                      onChange={(e) =>
                                        this.setState({
                                          orderReportName: e.target.value,
                                        })
                                      }
                                      disabled
                                    />
                                  </div>
                                  <div className='mb-9'>
                                    <p className='item-title'>Upload File</p>
                                    <input
                                      type='file'
                                      className='form-control form-control-solid mb-5'
                                      placeholder='Upload File'
                                      onChange={(e) => {
                                        this.setState({
                                          orderReportFile: e.target.files[0],
                                        });
                                      }}
                                      disabled
                                      accept='application/pdf'
                                    />
                                  </div>
                                  <p className='mb-0 text-end mt-9'>
                                    {!this.state.orderReportLoading ? (
                                      <button
                                        className='submit-btn btn btn-primary'
                                        onClick={this.handleFileUpload}
                                        disabled
                                      >
                                        Upload
                                      </button>
                                    ) : (
                                      <BtnLoading
                                        name='Uploading'
                                        btnClass='primary submit-btn'
                                      />
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-4 padd-last-item report-right'>
                              <p className='item-title'>Report Overview: </p>
                              <div className='reports'>
                                {reports && reports.length !== 0 ? (
                                  reports.map((report, i) => (
                                    <div className='single' key={i}>
                                      <div className='head'>
                                        <h5 className='fs-5 m-0'>
                                          {report.patient.full_name}
                                        </h5>
                                        <button className='btn' disabled>
                                          Download All
                                        </button>
                                      </div>
                                      <div className='body'>
                                        {report.reports.map((file, i) => (
                                          <div className='pdf' key={i}>
                                            <div className='left'>
                                              <img src={PdfIcon} alt='' />
                                              <div className='name-date'>
                                                <div>
                                                  {file.name
                                                    ? file.name
                                                    : 'Report ' + (i + 1)}
                                                </div>
                                                <div className='date'>
                                                  Uploaded on:{' '}
                                                  {moment(
                                                    file.created_at
                                                  ).format(
                                                    'DD MMM YYYY; hh:mm A'
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className='right'>
                                              <a
                                                href={file.report}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                              >
                                                <img
                                                  src={DownloadIcon}
                                                  alt=''
                                                  className='download'
                                                />
                                              </a>
                                              <img
                                                src={CrossIcon}
                                                alt=''
                                                onClick={() => {
                                                  if (
                                                    window.confirm(
                                                      'Are you sure to delete this file?'
                                                    )
                                                  )
                                                    this.handleFileDelete(file);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className='fs-6'>
                                    Sorry, No Report Uploaded Yet.
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className='col-12 report-email-head'>
                              <div>
                                <h2>SEND REPORTS VIA EMAIL</h2>
                              </div>
                            </div>
                            <div className='col-md-8 padd-fir-item report-left'>
                              <div className='row'>
                                <div className='col-md-12 col-6in7-l'>
                                  <div className='mb-7'>
                                    <p className='item-title'>Email</p>
                                    <input
                                      type='text'
                                      className='form-control form-control-solid'
                                      placeholder='comma seprated mulitple email address'
                                      value={this.state.reportSendEmails}
                                      onChange={(e) =>
                                        this.setState({
                                          reportSendEmails: e.target.value,
                                        })
                                      }
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-4 padd-last-item report-right'>
                              <p className='mb-0 text-end mt-10'>
                                {!this.state.reportSendEmailsLoading ? (
                                  <button
                                    className='submit-btn btn btn-primary'
                                    onClick={this.handleSendEmail}
                                    disabled={
                                      reports && reports.length !== 0
                                        ? ''
                                        : 'disabled'
                                    }
                                  >
                                    Send Email
                                  </button>
                                ) : (
                                  <BtnLoading
                                    name='Sending...'
                                    btnClass='primary submit-btn'
                                  />
                                )}
                              </p>
                            </div>
                            {order &&
                              order.orderemail &&
                              order.orderemail.length !== 0 && (
                                <>
                                  <div className='col-12 report-email-head'>
                                    <div>
                                      <h2>EMAIL Log</h2>
                                    </div>
                                  </div>
                                  <div className='col-md-12 padd-fir-item report-left'>
                                    <div className='row'>
                                      <div className='col-md-12 col-6in7-l'>
                                        <div className='email-log items'>
                                          {order.orderemail.map((item) => (
                                            <SingleEmailLog
                                              key={item.id}
                                              item={item}
                                            />
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                        <div className='col-md-1 section-name-right'>
                          <p className='name-vertical'>report management</p>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          {this.state.headUpdateLoading ? (
                            <BtnLoading
                              name='Placing...'
                              btnClass='primary w-100 py-5 fs-2'
                            />
                          ) : (
                            <button
                              className='btn btn-primary w-100 py-5 fs-2'
                              onClick={this.handlePlaceOrder}
                            >
                              Place Order
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ==================End Report Management Section================== */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <OrderItemAddNew
          itemEditModal={this.state.orderItemAddModal}
          setItemEditModal={this.setOrderItemAddModal}
          userPatients={userPatientsShown}
          setUserPatients={this.setUserPatients}
          testItemModalClose={this.testItemModalClose}
          testCarts={this.state.testCarts}
          setTestCarts={this.setTestCarts}
          handleSomeColculation={this.handleSomeColculation}
          userPatientName={this.state.userPatientName}
          handlePatientSearch={this.handlePatientSearch}
          userDetails={this.state.userDetails}
          userAllPatients={userPatients}
        />
      </>
    );
  }
}

export default OrderAddEdit;
OrderAddEdit.contextType = AuthContext;

const SingleOrderItem = ({ item, handleTestItemDelete }) => (
  <tr className='fs-6'>
    <td>
      {item.patient.full_name} (
      <span className='text-uppercase'>{item.patient.sex.substring(0, 1)}</span>
      ,{' '}
      {item.patient.dob === null
        ? ''
        : moment().diff(`${item.patient.dob}`, 'years') + 'y'}
      )
    </td>
    <td>
      {/* {item && item.test_item && item.test_item.diagnostic_test
        ? item &&
          item.test_item &&
          item.test_item.diagnostic_test &&
          item.test_item.diagnostic_test.name
        : 'no name'}{' '} */}
      {item.order_type === 'package'
        ? `${item.test_item.package.name}`
        : `${item.test_item.diagnostic_test.name}`}
    </td>


    <td >

        <Tooltip
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          overlay={<div style={{width: 450 }}>

            <strong style={{fontSize:18, display:'block', marginBottom:2, paddingBottom:2}}>Test Preparation:</strong>

            {item &&
              item.test_item &&
              item.test_item.diagnostic_test &&
              item.test_item.diagnostic_test.test_preparation
              ? item &&
              item.test_item &&
              item.test_item.diagnostic_test &&
              item.test_item.diagnostic_test.test_preparation
              : 'Empty'}{' '}



          </div>}
        >
          <i class="n-a fa fa-list" aria-hidden="true" data-tip=""  data-for={`prep_${item.id}`}></i>
        </Tooltip>



    </td>
    <td >

        <Tooltip
          mouseEnterDelay={0}
          mouseLeaveDelay={0.1}
          overlay={<div style={{width: 450 }}>

            <strong style={{fontSize:18, display:'block', marginBottom:2, paddingBottom:2}}>Remarks:</strong>
            {item &&
              item.test_item &&
              item.test_item.diagnostic_test &&
              item.test_item.diagnostic_test.remarks
              ? item &&
              item.test_item &&
              item.test_item.diagnostic_test &&
              item.test_item.diagnostic_test.remarks
              : 'Empty'}{' '}

          </div>}
        >
          <i class="n-a fa fa-lightbulb" data-tip=""  data-for={`rem_${item.id}`}></i>
        </Tooltip>


     </td>

    <td>
      {' '}
      ৳
      {/* {item &&
        item.test_item &&
        item.test_item.purchasable_order_item &&
        parseInt(item.test_item.purchasable_order_item.sell_price)} */}
      {item.order_type === 'package'
        ? `${item.test_item.test_item.purchasable_order_item.sell_price}`
        : `${item.test_item.purchasable_order_item.sell_price}`}
    </td>
    <td>
      {/* {item &&
        item.test_item &&
        item.test_item.branch &&
        item.test_item.branch.lab &&
        item.test_item.branch.lab.name} */}
      {item.order_type === 'diagnostic'
        ? item.test_item.branch.lab.name
        : item.test_item.test_item.branch.lab.name}
    </td>
    <td>
      <button
        className='btn btn-light-danger btn-sm'
        onClick={() => {
          if (window.confirm('Are you sure to delete this item?'))
            handleTestItemDelete(item);
        }}
      >
        Delete
      </button>
    </td>
  </tr>
);

const SingleEmailLog = ({ item }) => (
  <div className='single'>
    <button
      className={`btn btn-light-${item.mail_status === 'success' ? 'success' : 'danger'
        } log-btn`}
      disabled
    >
      {item.mail_status}
    </button>
    <span>{moment(item.created_at).format('DD MMM YYYY; hh:mm A')}</span>
  </div>
);

{
  /* <div className='pdf'>
  <div className='left'>
    <img src={PdfIcon} alt='' />
    <div className='name-date'>
      <div className='name'>CBC Report_Popular - Part 01</div>
      <div className='date'>Uploaded on: 01 Aug 2021; 12:25 PM</div>
    </div>
  </div>
  <div className='right'>
    <img src={CrossIcon} alt='' />
  </div>
</div>; */
}
